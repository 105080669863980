import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from 'tabler-react';
import TimeAgo from 'react-timeago';
import AudienceGenders from '../AudienceGenders';
import AudienceAges from '../AudienceAges';
import AudienceCities from '../AudienceCities';
import AudienceCountries from '../AudienceCountries';
import styles from './SocialProfileDemographics.module.css';

const SocialProfileDemographics = ({
  profile: {
    audience: { updatedAt, genders, ages, cities, countries },
    __typename,
  },
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid.Row>
        <Grid.Col lg="6">
          <h3 className={styles.DemographicsTitle}>{t('Audience Gender')}</h3>
          <AudienceGenders genders={genders} />
        </Grid.Col>
        <Grid.Col lg="6">
          <h3 className={styles.DemographicsTitle}>{t('Audience Age')}</h3>
          <AudienceAges ages={ages} />
        </Grid.Col>
      </Grid.Row>
      <hr />
      <Grid.Row>
        <Grid.Col>
          <h3 className={styles.DemographicsTitle}>{t('Audience Location')}</h3>
          <AudienceCountries countries={countries} />
        </Grid.Col>
        {__typename === 'InstagramProfile' && (
          <Grid.Col>
            <h3 className={styles.DemographicsTitle}>{t('Audience City')}</h3>
            <AudienceCities cities={cities} />
          </Grid.Col>
        )}
      </Grid.Row>
      {updatedAt !== null && (
        <Grid.Row>
          <Grid.Col>
            <p className={`${styles.DemographicsUpdatedAt} w-auto float-right pt-2`}>
              {t('Updated ')} <TimeAgo date={updatedAt} />
            </p>
          </Grid.Col>
        </Grid.Row>
      )}
    </>
  );
};

SocialProfileDemographics.propTypes = {
  profile: PropTypes.shape({
    __typename: PropTypes.oneOf(['InstagramProfile', 'YouTubeChannel']),
    audience: PropTypes.shape({
      ages: PropTypes.array,
      cities: PropTypes.array,
      countries: PropTypes.array,
      genders: PropTypes.array,
      updatedAt: PropTypes.string,
    }),
  }).isRequired,
};

export default SocialProfileDemographics;
