import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'tabler-react';
import Drawer from '../../../layouts/components/Drawer';
import Form from './Form';
import styles from './CreateCampaign.module.css';

const CreateCampaign = () => {
  const { t } = useTranslation();
  const [createCampaignFormIsOpen, setCreateCampaignFormIsOpen] = useState(false);

  return (
    <div className="float-right">
      <Drawer
        handleClose={() => {
          setCreateCampaignFormIsOpen(false);
        }}
        isOpen={createCampaignFormIsOpen}
      >
        {createCampaignFormIsOpen && (
          <Form
            className={styles.Form}
            onSubmitSuccess={() => {
              setCreateCampaignFormIsOpen(false);
            }}
          />
        )}
      </Drawer>
      <div data-testid="CreateCampaignButton">
        <Button
          block
          className="w-auto py-2"
          color="success"
          onClick={() => {
            setCreateCampaignFormIsOpen(true);
          }}
        >
          {t('Add Campaign')}
        </Button>
      </div>
    </div>
  );
};

export default CreateCampaign;
