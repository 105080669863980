import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CREATIVE_ITERATION_STATES } from '../../../../constants';
import styles from './StateLabel.module.css';

const StateLabel = ({ creativeIteration }) => {
  const { t } = useTranslation();
  const { state, comment, reviewer } = creativeIteration;

  switch (state) {
    case CREATIVE_ITERATION_STATES.POSTED:
      return <div className={`${styles.StateLabel} ${styles.StatePosted}`}>{t('Posted')}</div>;
    case CREATIVE_ITERATION_STATES.APPROVED:
      return <div className={`${styles.StateLabel} ${styles.StateApproved}`}>{t('Approved')}</div>;
    case CREATIVE_ITERATION_STATES.PENDING:
      return <div className={`${styles.StateLabel} ${styles.StatePending}`}>{t('Pending')}</div>;
    case CREATIVE_ITERATION_STATES.CHANGES_REQUESTED:
      return (
        <div className={styles.StateChangesRequested}>
          <div className="d-flex justify-content-start">
            <p className={styles.BlockQuoteLabel}>
              <span className={styles.BlockQuoteAuthor}>{reviewer?.name} </span>
              {t('requested changes')}:
            </p>
          </div>

          <blockquote className={styles.BlockQuote}>
            <p className={`mb-0 ${styles.BlockQuoteContent}`}>{comment}</p>
          </blockquote>
        </div>
      );
    default:
      return <div>{state}</div>;
  }
};

StateLabel.propTypes = {
  creativeIteration: PropTypes.shape({
    comment: PropTypes.string,
    reviewer: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    state: PropTypes.oneOf(Object.values(CREATIVE_ITERATION_STATES)).isRequired,
  }).isRequired,
};

export default StateLabel;
