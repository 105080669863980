import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { Form as TablerForm, Button } from 'tabler-react';
import Loader from '../../../../layouts/components/Loader';
import HandleError from '../../../../layouts/components/ErrorMessage/HandleError';
import ADD_STATS_MUTATION from './addStats.mutation';
import styles from './Form.module.css';

const Form = ({ advert, onSubmitSuccess }) => {
  const { t } = useTranslation();
  const [error, setError] = useState();
  const [numberOfLikes, setNumberOfLikes] = useState('');
  const [numberOfComments, setNumberOfComments] = useState('');

  const [addStats, { loading }] = useMutation(ADD_STATS_MUTATION, {
    update: onSubmitSuccess,
  });

  const handleSubmit = async event => {
    event.preventDefault();
    if (!numberOfLikes || !numberOfComments) {
      setError({
        message: t('Please enter numbers'),
      });
      return;
    }
    try {
      await addStats({
        variables: {
          advertId: advert.id,
          stats: {
            numberOfComments,
            numberOfLikes,
          },
        },
      });
    } catch (err) {
      setError(err);
    }
  };

  return (
    <div className={styles.Form} data-testid="AddStatsForm">
      {/* have this here so we don't reset the form after submit */}
      {loading && <Loader />}
      <TablerForm onSubmit={handleSubmit}>
        <h2 className="text-center w-100 pb-3">{t('Add Advert Stats')}</h2>
        <div className={styles.SelectAreaContainer}>
          <div className={styles.SelectArea}>
            <TablerForm.Label>
              {t('Number of likes')}
              <TablerForm.Input
                min="0"
                onChange={event => setNumberOfLikes(parseInt(event.target.value, 10))}
                type="number"
                value={numberOfLikes}
              />
            </TablerForm.Label>
          </div>
          <div className={styles.SelectArea}>
            <TablerForm.Label>
              {t('Number of comments')}
              <TablerForm.Input
                min="0"
                onChange={event => setNumberOfComments(parseInt(event.target.value, 10))}
                type="number"
                value={numberOfComments}
              />
            </TablerForm.Label>
          </div>
        </div>
        <TablerForm.Footer className="mt-3 text-center">
          {error && (
            <p className="text-danger">
              <HandleError error={error} />
            </p>
          )}
          <Button
            block
            className={`w-auto mx-auto px-4 py-2 ${loading && 'disabled'}`}
            color="success"
            type="submit"
          >
            {t('Add')}
          </Button>
        </TablerForm.Footer>
      </TablerForm>
    </div>
  );
};

Form.propTypes = {
  advert: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
};

export default Form;
