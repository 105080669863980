import React from 'react';
import { Grid } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import styles from './NoContentPreview.module.css';

const NoContentPreview = () => {
  const { t } = useTranslation();
  return (
    <Grid.Row>
      <Grid.Col>
        <div className={`d-flex align-items-center justify-content-center ${styles.NotUploaded}`}>
          <p>{t('No media uploaded yet')}</p>
        </div>
      </Grid.Col>
    </Grid.Row>
  );
};

export default NoContentPreview;
