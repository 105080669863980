import gql from 'graphql-tag';
import InfluencerInfo from '../InfluencerInfo';
import CampaignCardFragment from '../../../campaigns/components/CampaignCard/campaignCard.fragment';

export default gql`
  query influencer($id: ID!, $firstCampaigns: Int, $query: String, $filterBy: CampaignFilters) {
    influencer(id: $id) {
      id
      ...InfluencerInfo
      campaigns(first: $firstCampaigns, query: $query, filterBy: $filterBy) {
        edges {
          node {
            ...CampaignCard
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${InfluencerInfo.fragments.influencerInfo}
  ${CampaignCardFragment}
`;
