import gql from 'graphql-tag';

export default gql`
  mutation($creativeIterationId: ID!, $url: URL!) {
    postAdvert(input: { creativeIterationId: $creativeIterationId, url: $url }) {
      advert {
        id
        url
        postedAt
        # this is here so the cache is updated properly
        # the issue we found it with was when we mark a deliverable as posted
        # and try to add stats for it straight away
        deliverable {
          id
          advert {
            id
          }
        }
        creativeIteration {
          id
          state
        }
      }
    }
  }
`;
