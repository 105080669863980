import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import Typeahead from '../Typeahead';
import BRANDS_DATA from './brandsData.query';

const Brands = ({ onChange, value, autoFocus, isMulti, placeholder, formatCreateLabel, type }) => {
  let brands;
  const { data, loading, error } = useQuery(BRANDS_DATA);

  if (loading) {
    return null;
  }

  if (error) {
    brands = [];
  }

  if (data) {
    brands = [...new Set(data.brands.map(brand => brand.name))].map(brand => ({
      label: brand,
      value: brand,
    }));
  }

  const defaultValue = value.map(brand => ({
    value: brand,
    label: brand,
  }));

  return (
    <div className="mb-3">
      {brands && (
        <Typeahead
          autoFocus={autoFocus}
          formatCreateLabel={formatCreateLabel}
          isMulti={isMulti}
          onChange={onChange}
          options={brands}
          placeholder={placeholder}
          type={type}
          value={defaultValue}
        />
      )}
    </div>
  );
};

Brands.defaultProps = {
  autoFocus: false,
  formatCreateLabel: null,
  isMulti: false,
  placeholder: '',
  value: null,
};

Brands.propTypes = {
  autoFocus: PropTypes.bool,
  formatCreateLabel: PropTypes.func,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default Brands;
