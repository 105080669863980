import gql from 'graphql-tag';

export default gql`
  query($shortcode: String!, $influencerId: ID!) {
    campaign(shortcode: $shortcode) {
      content(influencerId: $influencerId) {
        deliverables {
          id
          type
          order
          advert {
            id
          }
          socialProfile {
            ... on InstagramProfile {
              id
              username
            }
            ... on YouTubeChannel {
              id
              title
            }
            __typename
          }
          creativeIterations {
            id
            title
            state
            caption
            comment
            price
            media {
              id
              path
              type
              order
            }
            reviewer {
              id
              name
            }
            type
            reviewerHasAccess
          }
        }
        influencer {
          id
          name
          pictureUrl
          profiles {
            ... on InstagramProfile {
              id
              pictureUrl
              username
              url
            }
            ... on YouTubeChannel {
              id
              pictureUrl
              title
              url
            }

            __typename
          }
        }
      }
      id
      shortcode
      brand {
        name
      }
      name
      permissions {
        viewerCanAddContent
        viewerCanAddStats
        viewerCanMarkAsPosted
        viewerCanShowToReviewer
        viewerCanUploadContent
        viewerCanApprove
      }
    }
  }
`;
