import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Card, Button } from 'tabler-react';
import { Link } from 'react-router-dom';
import CreateShortlist from '../CreateShortlist';
import UploadReport from '../UploadReport';
import EditCampaign from '../EditCampaign';
import { generateShortlistUrl, generateContentUrl } from '../../../utils/helpers';
import styles from './CampaignCard.module.css';

const CampaignCard = ({ campaign }) => {
  const { t } = useTranslation();
  const {
    agency,
    brand,
    name,
    reportUrl,
    hasContent,
    hasReport,
    hasShortlist,
    permissions: {
      viewerCanUploadReport,
      viewerCanAddContent,
      viewerCanCreateShortlist,
      viewerCanEditCampaign,
    },
  } = campaign;

  return (
    <div data-testid="CampaignCard">
      <Card className={styles.Card}>
        <p className={`${styles.AgencyName} mb-0`}>{agency && agency.name}</p>
        <Grid.Row>
          <Grid.Col width="10">
            <p className={`${styles.Title} mb-0`}>
              {brand && `${brand.name}: `}
              {name}
            </p>
          </Grid.Col>
          <Grid.Col className="d-flex align-items-center justify-content-end" width="2">
            {viewerCanEditCampaign && <EditCampaign campaign={campaign} />}
          </Grid.Col>
        </Grid.Row>
        <Grid.Row className="d-flex justify-content-between">
          <Grid.Col className="mt-3 col-4">
            {hasShortlist && (
              <Link to={generateShortlistUrl(campaign)}>
                <Button className={`${styles.Button} w-100`} color="primary">
                  {t('View Shortlist')}
                </Button>
              </Link>
            )}
            {viewerCanCreateShortlist && !hasShortlist && (
              <CreateShortlist campaign={campaign} className={styles.Button} />
            )}
            {!viewerCanCreateShortlist && !hasShortlist && (
              <Button className={`${styles.Button} w-100`} color="primary" disabled>
                {t('No Shortlist Yet')}
              </Button>
            )}
          </Grid.Col>
          <Grid.Col className="mt-3 col-4">
            {(hasContent || viewerCanAddContent) && (
              <Link to={generateContentUrl(campaign)}>
                <Button
                  className={`${styles.Button} w-100`}
                  color={hasContent ? 'primary' : 'primary-light'}
                >
                  {hasContent ? t('View Content') : t('Add Content')}
                </Button>
              </Link>
            )}
            {!hasContent && !viewerCanAddContent && (
              <Button className={`${styles.Button} w-100`} color="primary" disabled>
                {t('No Content Yet')}
              </Button>
            )}
          </Grid.Col>
          <Grid.Col className="mt-3 col-4">
            {hasReport && (
              <a href={reportUrl} rel="noopener noreferrer" target="_blank">
                <Button className={`${styles.Button} w-100`} color="primary">
                  {t('View Report')}
                </Button>
              </a>
            )}
            {viewerCanUploadReport && !hasReport && (
              <UploadReport campaign={campaign} className={styles.Button} />
            )}
            {!viewerCanUploadReport && !hasReport && (
              <Button className={`${styles.Button} w-100`} color="primary" disabled>
                {t('No Report Yet')}
              </Button>
            )}
          </Grid.Col>
        </Grid.Row>
      </Card>
    </div>
  );
};

CampaignCard.propTypes = {
  campaign: PropTypes.shape({
    agency: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    brand: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    hasContent: PropTypes.bool.isRequired,
    hasReport: PropTypes.bool.isRequired,
    hasShortlist: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    permissions: PropTypes.shape({
      viewerCanAddContent: PropTypes.bool.isRequired,
      viewerCanCreateShortlist: PropTypes.bool.isRequired,
      viewerCanEditCampaign: PropTypes.bool.isRequired,
      viewerCanUploadReport: PropTypes.bool.isRequired,
    }),
    reportUrl: PropTypes.string,
  }).isRequired,
};

export default CampaignCard;
