import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'tabler-react';
import Drawer from '../../../layouts/components/Drawer';
import Form from './Form';

const CreateClient = () => {
  const { t } = useTranslation();

  const [createClientFormIsOpen, setClientFormIsOpen] = useState(false);

  return (
    <div className="float-right">
      <Drawer
        handleClose={() => {
          setClientFormIsOpen(false);
        }}
        isOpen={createClientFormIsOpen}
      >
        <Form
          onSubmitSuccess={() => {
            setClientFormIsOpen(false);
            // trigger refetch of the shortlist after submit success
            // refetch();
          }}
        />
      </Drawer>
      <div data-testid="CreateClientButton">
        <Button
          block
          className="w-auto py-2"
          color="success"
          onClick={() => {
            setClientFormIsOpen(true);
          }}
        >
          {t('Add Client')}
        </Button>
      </div>
    </div>
  );
};

export default CreateClient;
