import React from 'react';
import PropTypes from 'prop-types';
import Masonry from 'react-masonry-css';
import styles from './MasonryGallery.module.css';

const MasonryGallery = ({ breakpointCols, children }) => {
  return (
    <Masonry
      breakpointCols={breakpointCols}
      className={styles.Masonry}
      columnClassName={styles.MasonryColumn}
    >
      {children}
    </Masonry>
  );
};

MasonryGallery.defaultProps = {
  breakpointCols: {
    default: 5,
    1200: 4,
    992: 3,
    768: 2,
    557: 1,
  },
};

MasonryGallery.propTypes = {
  breakpointCols: PropTypes.shape(),
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default MasonryGallery;
