import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form as TablerForm, Button } from 'tabler-react';
import { useParams, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import Loader from '../../../../../../layouts/components/Loader';
import GET_CAMPAIGN_SHORTLIST from '../../../../CampaignShortlistQueryContainer/campaignShortlist.query';
import HandleError from '../../../../../../layouts/components/ErrorMessage/HandleError';
import styles from './Form.module.css';
import UPDATE_SHORTLISTED_PROFILE from './updateShortlistedProfile.mutation';
import REMOVE_SHORTLISTED_PROFILE from './removeShortlistedProfile.mutation';

const Form = ({ shortlistedProfile: profile, onSubmitSuccess, viewerCanRecommend }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [removeError, setRemoveError] = useState('');
  const [editError, setEditError] = useState('');
  const [rationale, setRationale] = useState(profile.rationale || '');
  const [selectedFileName, setSelectedFileName] = useState();
  const [isRecommended, setIsRecommended] = useState(profile.isRecommended);
  const [isBooked, setIsBooked] = useState(profile.isBooked);
  const audienceDataRef = useRef(null);
  const { slug } = useParams();
  const shortcode = slug.split('-').pop();
  const {
    id: profileId,
    node: { username, title },
  } = profile;

  const [updateShortlistedProfile, { loading: loadingEdit }] = useMutation(
    UPDATE_SHORTLISTED_PROFILE,
    {
      update: (_, { data }) => {
        const {
          updateShortlistedProfile: {
            shortlistedProfile: { id },
          },
        } = data;

        onSubmitSuccess(id);

        history.push(`?details=${id}`);
      },
    }
  );

  const [removeShortlistedProfile, { loading: loadingRemove }] = useMutation(
    REMOVE_SHORTLISTED_PROFILE,
    {
      refetchQueries: [{ query: GET_CAMPAIGN_SHORTLIST, variables: { shortcode } }],
    }
  );

  const loading = loadingEdit || loadingRemove;

  const handleRemove = async event => {
    event.preventDefault();
    try {
      await removeShortlistedProfile({
        variables: { shortlistedProfileId: profileId },
      });
      onSubmitSuccess();
    } catch (err) {
      setRemoveError(err);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      await updateShortlistedProfile({
        variables: {
          id: profileId,
          rationale,
          isRecommended,
          isBooked,
          audienceData: audienceDataRef.current ? audienceDataRef.current.files[0] : null,
        },
      });
    } catch (err) {
      setEditError(err);
    }
  };

  return (
    <div className={styles.Form} data-testid="EditShortlistedSocialProfileForm">
      {/* have this here so we don't reset the form after submit */}
      {loading && <Loader />}
      <TablerForm className={styles.EditShortlistedProfileForm} onSubmit={handleSubmit}>
        <div className="row pb-3 no-gutters">
          <div className="col-9 pr-2">
            <h2 className={`${styles.Heading} mb-0 mt-1 float-left w-100`}>
              {t('Edit ')}
              {username && <span className={styles.ProfileUsername}>{username}</span>}
              {title && title}
            </h2>
          </div>
          <div className="col-3">
            <Button
              block
              className="w-auto float-right px-4 py-2"
              color="danger"
              disabled={loading}
              onClick={handleRemove}
              type="button"
            >
              {t('Remove')}
            </Button>
          </div>
        </div>
        <TablerForm.Label className="my-3">
          {t('Info')}
          <TablerForm.Textarea
            className="pt-3"
            name="rationale"
            onChange={event => setRationale(event.target.value)}
            rows={4}
            value={rationale}
          />
        </TablerForm.Label>
        {viewerCanRecommend && (
          <>
            <TablerForm.Checkbox
              checked={isRecommended}
              label={t('Recommend influencer')}
              name="isRecommended"
              onChange={event => setIsRecommended(event.target.checked)}
            />
            <TablerForm.Checkbox
              checked={isBooked}
              label={t('Mark as booked')}
              name="isBooked"
              onChange={event => setIsBooked(event.target.checked)}
            />
          </>
        )}
        <div className="form-group mt-3">
          <TablerForm.Label>{t('Upload audience data')}</TablerForm.Label>
          <div className="input-group">
            <div className="custom-file">
              <label
                className="c-pointer custom-file-label"
                data-testid="AudienceDataLabel"
                htmlFor="audienceData"
              >
                {selectedFileName || t('Choose file')}
                <input
                  ref={audienceDataRef}
                  accept="application/json"
                  aria-describedby="audienceData"
                  className={`${styles.AudienceDataInput} custom-file-input`}
                  id="audienceData"
                  onChange={() => {
                    setSelectedFileName(audienceDataRef.current.files[0].name);
                  }}
                  type="file"
                />
              </label>
            </div>
          </div>
        </div>
        <TablerForm.Footer className="mt-3 text-center">
          {(editError || removeError) && (
            <p className="text-danger" data-testid="Error">
              <HandleError error={editError || removeError} />
            </p>
          )}
          <Button
            block
            className="w-auto mx-auto px-4 py-2"
            color="success"
            disabled={loading}
            type="submit"
          >
            {t('Save')}
          </Button>
        </TablerForm.Footer>
      </TablerForm>
    </div>
  );
};

Form.propTypes = {
  onSubmitSuccess: PropTypes.func.isRequired,
  shortlistedProfile: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isBooked: PropTypes.bool.isRequired,
    isRecommended: PropTypes.bool.isRequired,
    node: PropTypes.shape({
      title: PropTypes.string,
      username: PropTypes.string,
    }),
    rationale: PropTypes.string,
  }).isRequired,
  viewerCanRecommend: PropTypes.bool.isRequired, // eslint-disable-line react/boolean-prop-naming
};

export default Form;
