import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import RangeSlider from '../../RangeSlider';
import styles from './EngagementRate.module.css';

const MIN = '0';
const MAX = '30';
const STEP = 0.25;

const castValues = (engagementRate, onApplyFilters) => {
  return engagementRate.map(rate => {
    if (isNaN(Number(rate))) {
      onApplyFilters({ engagementRate: [] });
      return null;
    }

    return Number(rate);
  });
};

const EngagementRateFilter = ({ engagementRate, onApplyFilters }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState(castValues(engagementRate, onApplyFilters));

  useEffect(() => {
    setValues(castValues(engagementRate, onApplyFilters));
  }, [engagementRate, onApplyFilters]);

  return (
    <Form.Group>
      <Form.Label>{t('Engagement Rate')}</Form.Label>
      <div data-testid="EngagementRateFilter">
        <RangeSlider
          className={styles.ThumbTooltip}
          max={Number(MAX)}
          min={Number(MIN)}
          onApplyFilters={finalValues => onApplyFilters({ engagementRate: finalValues })}
          setValues={setValues}
          step={STEP}
          values={values}
        >
          {arg => arg.toFixed(2)}
        </RangeSlider>
      </div>
    </Form.Group>
  );
};

EngagementRateFilter.defaultProps = {
  engagementRate: [MIN, MAX],
};

EngagementRateFilter.propTypes = {
  engagementRate: PropTypes.arrayOf(PropTypes.string),
  onApplyFilters: PropTypes.func.isRequired,
};

export default EngagementRateFilter;
