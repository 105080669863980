import {
  getDetailsDrawerFormatter,
  ShortlistDrawerFormatter,
  ChannelsFormatter,
  AvatarFormatter,
  HumanizeNumbersFormatter,
  PercentageFormatter,
} from './Formatters';
// import { Editors } from 'react-data-grid-addons';

const getColumnConfig = ({ onClick, t, platform }) => {
  // Temporary hidden
  // const genderOptions = [
  //   { id: 'male', value: t('Male') },
  //   { id: 'female', value: t('Female') },
  //   { id: 'other', value: t('Other') },
  // ];

  const platformConfig = {
    INSTAGRAM: [
      { key: 'shortlist', width: 40, formatter: ShortlistDrawerFormatter },
      { key: 'avatar', width: 50, formatter: AvatarFormatter },
      { key: 'profileName', name: t('Name'), width: 220 },
      { key: 'handle', width: 160 },
      {
        key: 'gender',
        width: 80,
        name: t('Gender'),
        // Temporary hidden
        // editor: <Editors.DropDownEditor options={genderOptions} />,
      },
      { key: 'country', name: t('Country') },
      { key: 'channels', formatter: ChannelsFormatter },
      {
        key: 'followerCount',
        name: t('Followers'),
        editable: true,
        formatter: HumanizeNumbersFormatter,
      },
      {
        key: 'engagementRate',
        name: t('Engagement'),
        editable: true,
        formatter: PercentageFormatter,
      },
      { key: 'details', width: 40, formatter: getDetailsDrawerFormatter(onClick, t) },
    ],

    YOUTUBE: [
      { key: 'shortlist', width: 40, formatter: ShortlistDrawerFormatter },
      { key: 'avatar', width: 50, formatter: AvatarFormatter },
      { key: 'profileName', width: 220, name: t('Name') },
      {
        key: 'gender',
        width: 80,
        name: t('Gender'),
        // Temporary hidden
        // editor: <Editors.DropDownEditor options={genderOptions} />,
      },
      { key: 'country', name: t('Country') },
      { key: 'channels', formatter: ChannelsFormatter },
      {
        key: 'subscriberCount',
        name: t('Subscribers'),
        editable: true,
        formatter: HumanizeNumbersFormatter,
      },
      { key: 'viewRate', name: t('View Rate') },
      { key: 'details', width: 40, formatter: getDetailsDrawerFormatter(onClick, t) },
    ],
  };

  return platformConfig[platform];
};

export default getColumnConfig;
