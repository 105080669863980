import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import ClientCard from '../ClientCard/ClientCard';
import styles from './ClientsList.module.css';

const ClientsList = ({ clients }) => {
  const { t } = useTranslation();

  if (clients.length === 0) {
    return (
      <Grid.Row>
        <Grid.Col>
          <div className={`text-center text-muted ${styles.NoContentLabel}`}>
            {t('No clients found')}
          </div>
        </Grid.Col>
      </Grid.Row>
    );
  }

  return (
    <Grid.Row>
      {clients.map((client, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid.Col key={`${client.name}${index}`} className="cardItem col-12" lg={6}>
          <ClientCard client={client} />
        </Grid.Col>
      ))}
    </Grid.Row>
  );
};

ClientsList.propTypes = {
  clients: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ClientsList;
