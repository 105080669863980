import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'tabler-react';
import styles from './InfluencerViewButtons.module.css';

const InfluencerViewButtons = ({ view, setView }) => {
  return (
    <Form.SelectGroup className={`${styles.SelectGroup} w-auto`}>
      <Form.SelectGroupItem
        checked={view === 'table'}
        className={styles.SelectGroupItem}
        icon="list"
        name="view"
        onChange={() => setView('table')}
      />
      <Form.SelectGroupItem
        checked={view === 'grid'}
        className={styles.SelectGroupItem}
        icon="grid"
        name="view"
        onChange={() => setView('grid')}
      />
    </Form.SelectGroup>
  );
};

InfluencerViewButtons.propTypes = {
  setView: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
};

export default InfluencerViewButtons;
