import React from 'react';
import PropTypes from 'prop-types';
import AddToShortlist from '../Actions/AddToShortlist';

const ShortlistDrawerFormatter = ({ value: { profile } }) => {
  return <AddToShortlist profile={profile} />;
};

ShortlistDrawerFormatter.defaultProps = {
  value: {},
};

ShortlistDrawerFormatter.propTypes = {
  value: PropTypes.shape({
    profile: PropTypes.shape(),
  }),
};

export default ShortlistDrawerFormatter;
