import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import viewerUtils from '../../../utils/viewer';

const PrivateRoute = ({ children, ...rest }) => {
  return (
    <Route
      // ESLint is disabled here because we need to wrap the Route component and otherwise
      // we will have very big list of props
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={({ location }) =>
        viewerUtils.isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
