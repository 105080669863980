import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import Loader from '../../../layouts/components/Loader';
import ErrorMessage from '../../../layouts/components/ErrorMessage';
import GET_INFLUENCER_CAMPAIGNS_LIST from './influencerCampaigns.query';

const handleScroll = ({ currentTarget }) => {
  if (currentTarget.scrollTop + currentTarget.clientHeight >= currentTarget.scrollHeight) {
    return true;
  }

  return false;
};

const updateQuery = (previousResult, { fetchMoreResult }) => {
  if (!fetchMoreResult) {
    return previousResult;
  }

  // Merging the previous campaigns object with the one returned from fetchMore
  return {
    ...previousResult,
    campaigns: {
      ...previousResult.campaigns,
      ...fetchMoreResult.campaigns,
      edges: [...previousResult.campaigns.edges, ...fetchMoreResult.campaigns.edges],
    },
  };
};

const firstCampaigns = 30;

const InfluencerCampaignsQueryContainer = ({ children, id, query, states }) => {
  const { t } = useTranslation();

  const filterBy = states.length === 0 ? {} : { states: states.map(state => state.toUpperCase()) };

  const { loading, error, data, fetchMore } = useQuery(GET_INFLUENCER_CAMPAIGNS_LIST, {
    variables: {
      id,
      firstCampaigns,
      query,
      filterBy,
    },
  });

  if (error) return <ErrorMessage error={error} />;

  if (loading) return <Loader />;

  const { influencer } = data;

  if (influencer === null) {
    return (
      <div className="d-flex h-100 justify-content-center align-items-center">
        <p className="text-muted">{t('Influencer not found')}</p>
      </div>
    );
  }

  const {
    campaigns: {
      edges: campaignNodes,
      pageInfo: { hasNextPage, endCursor: afterCampaign },
    },
  } = influencer;

  return (
    <div
      className="scrollableContainer h-100"
      data-testid="ScrollableContainer"
      onScroll={event => {
        if (handleScroll(event) && hasNextPage) {
          fetchMore({
            variables: {
              firstCampaigns,
              afterCampaign,
              query,
              filterBy,
            },
            updateQuery,
          });
        }
      }}
    >
      {children(
        influencer,
        campaignNodes.map(campaingNode => campaingNode.node)
      )}
    </div>
  );
};

InfluencerCampaignsQueryContainer.defaultProps = {
  query: '',
  states: [],
};

InfluencerCampaignsQueryContainer.propTypes = {
  children: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  query: PropTypes.string,
  states: PropTypes.arrayOf(PropTypes.string),
};

export default InfluencerCampaignsQueryContainer;
