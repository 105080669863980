import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Loader from '../../../layouts/components/Loader';
import ErrorMessage from '../../../layouts/components/ErrorMessage';
import GET_INFLUENCER_NOTES from './influencerNotes.query';
import ADD_NOTE from './addNote.mutation';

const firstNotes = 30;

const updateCache = (cache, { data }, subjectId) => {
  const {
    addComment: { comment },
  } = data;

  const cachedData = cache.readQuery({
    query: GET_INFLUENCER_NOTES,
    variables: { id: subjectId, firstNotes },
  });

  cachedData.influencer.comments.edges.unshift({ node: comment, __typename: 'CommentEdge' });

  cache.writeQuery({
    query: GET_INFLUENCER_NOTES,
    data: cachedData,
    variables: { id: subjectId, firstNotes },
  });
};

const updateQuery = (previousResult, { fetchMoreResult }) => {
  if (!fetchMoreResult) {
    return previousResult;
  }

  // Merging the previous influencer object with the one returned from fetchMore
  return {
    ...previousResult,
    influencer: {
      ...previousResult.influencer,
      comments: {
        ...previousResult.influencer.comments,
        ...fetchMoreResult.influencer.comments,
        edges: [
          ...previousResult.influencer.comments.edges,
          ...fetchMoreResult.influencer.comments.edges,
        ],
      },
    },
  };
};

const InfluencerNotesQueryContainer = ({ children, id }) => {
  const { t } = useTranslation();
  const [addNoteError, setAddNoteError] = useState();

  const [addNote] = useMutation(ADD_NOTE, {
    update: (cache, data) => updateCache(cache, data, id),
  });

  const { loading, error, data, fetchMore } = useQuery(GET_INFLUENCER_NOTES, {
    variables: {
      firstNotes,
      id,
    },
  });

  if (error) return <ErrorMessage error={error} />;

  if (loading) return <Loader />;

  const { influencer } = data;

  if (influencer === null) {
    return (
      <div className="d-flex h-100 justify-content-center align-items-center">
        <p className="text-muted">{t('Influencer not found')}</p>
      </div>
    );
  }

  const {
    comments: {
      edges: commentNodes,
      pageInfo: { hasNextPage, endCursor: afterNote },
    },
  } = influencer;

  const handleAddNewEntry = body => {
    try {
      addNote({
        variables: {
          comment: {
            subjectId: id,
            body,
          },
        },
      });
    } catch (err) {
      setAddNoteError(err);
    }
  };

  const handleFetchMore = () => {
    if (hasNextPage) {
      fetchMore({
        variables: {
          id,
          firstNotes,
          afterNote,
        },
        updateQuery,
      });
    }
  };

  return (
    <div className="h-100 scrollableContainer">
      {children(
        influencer,
        commentNodes.map(commentNode => commentNode.node),
        handleAddNewEntry,
        handleFetchMore,
        addNoteError
      )}
    </div>
  );
};

InfluencerNotesQueryContainer.propTypes = {
  children: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default InfluencerNotesQueryContainer;
