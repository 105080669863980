import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Card } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import InfluencerWidget from '../../../influencers/components/InfluencerWidget';
import styles from './InfluencerContentCard.module.css';

const InfluencerContentCard = ({ content }) => {
  const { t } = useTranslation();
  const { approved, toApprove, changesRequested, posted, influencer } = content;

  return (
    <div data-testid="InfluencerContentCard">
      <Card className={styles.InfluencerContentCard}>
        <Grid.Row>
          <Grid.Col width="9">
            <InfluencerWidget imageSize={100} influencer={influencer} />
          </Grid.Col>
          <Grid.Col className="d-flex align-items-center justify-content-end" width="3">
            {/* Using an svg icon here because there isn't any similar in fontawesome */}
            {toApprove > 0 && (
              <i data-testid="ToApproveFlag">
                <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7 12h-2v-2h2v2zm0-4h-2V6h2v4z"
                    fill="#fdc421"
                  />
                </svg>
              </i>
            )}
          </Grid.Col>
        </Grid.Row>
        <Grid.Row className={styles.InfluencerContentStats}>
          <Grid.Col className={`${styles.InfluencerContentStat} TestToApproveStat`} width="3">
            <p className={styles.StatValue}>{toApprove}</p>
            <p className={styles.StatLabel}>{t('to review')}</p>
          </Grid.Col>
          <Grid.Col className={`${styles.InfluencerContentStat} TestNeedsUpdateStat`} width="3">
            <p className={styles.StatValue}>{changesRequested}</p>
            <p className={styles.StatLabel}>{t('needs update')}</p>
          </Grid.Col>{' '}
          <Grid.Col className={`${styles.InfluencerContentStat} TestApprovedStat`} width="3">
            <p className={styles.StatValue}>{approved}</p>
            <p className={styles.StatLabel}>{t('approved')}</p>
          </Grid.Col>{' '}
          <Grid.Col className={`${styles.InfluencerContentStat} TestPostedStat`} width="3">
            <p className={styles.StatValue}>{posted}</p>
            <p className={styles.StatLabel}>{t('posted')}</p>
          </Grid.Col>
        </Grid.Row>
      </Card>
    </div>
  );
};

InfluencerContentCard.propTypes = {
  content: PropTypes.shape({
    approved: PropTypes.number.isRequired,
    changesRequested: PropTypes.number.isRequired,
    influencer: PropTypes.shape().isRequired,
    posted: PropTypes.number.isRequired,
    toApprove: PropTypes.number.isRequired,
  }).isRequired,
};

export default InfluencerContentCard;
