import React from 'react';
import { Grid } from 'tabler-react';
import PropTypes from 'prop-types';
import SocialProfileCard from './SocialProfileCard';

const GridView = ({ profiles, onClick }) => {
  return profiles.map(profile => (
    <Grid.Col key={profile.id} className="col-12 col-md-12 col-lg-6 col-xl-4 cardItem">
      <SocialProfileCard onClick={onClick} profile={profile} />
    </Grid.Col>
  ));
};

GridView.propTypes = {
  onClick: PropTypes.func.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })).isRequired,
};
export default GridView;
