import React from 'react';

const TenantLogo = () => {
  const host = window.location.hostname.split('.').find(val => {
    if (val === 'app' || val === 'www') return false;
    return true;
  });

  let logo;

  switch (host.toLowerCase()) {
    // Teams
    case 'justeat':
      logo = <img alt="Logo" height="35" src="/logos/justeat_logo.svg" />;
      break;

    case 'naturalcycles':
      logo = <img alt="Logo" height="40" src="/logos/naturalcycles_logo.png" />;
      break;

    // Tenants
    case 'gmincatech':
      logo = <img alt="Logo" height="35" src="/logos/inca_logo.png" />;
      break;

    case 'greenmangaminginfluencers':
      logo = <img alt="Logo" height="35" src="/logos/greenmangaming_logo.svg" />;
      break;

    case 'phdmediatech':
      logo = <img alt="Logo" height="40" src="/logos/phdmedia_logo.png" />;
      break;

    case 'localhost':
    case 'tailify':
      logo = <img alt="Logo" height="35" src="/logos/tailify_logo.png" />;
      break;

    default:
      break;
  }

  return <div>{logo}</div>;
};

export default TenantLogo;
