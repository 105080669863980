import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import Drawer from '../../../../../layouts/components/Drawer';
import Form from './Form';
import styles from './RequestChanges.module.css';

const RequestChanges = ({ creativeIteration }) => {
  const { t } = useTranslation();

  const [addRequestChangesIsOpen, setRequestChangesIsOpen] = useState(false);

  return (
    <>
      <Drawer
        handleClose={() => {
          setRequestChangesIsOpen(false);
        }}
        isOpen={addRequestChangesIsOpen}
      >
        {addRequestChangesIsOpen && <Form creativeIteration={creativeIteration} />}
      </Drawer>
      <Button
        className={`w-50 text-center ${styles.ActionChange}`}
        color="primary-light"
        onClick={() => {
          setRequestChangesIsOpen(true);
        }}
      >
        {t('Request Changes')}
      </Button>
    </>
  );
};

RequestChanges.propTypes = {
  creativeIteration: PropTypes.shape({}).isRequired,
};

export default RequestChanges;
