const generateCreativeIterationTitle = (type, order) => {
  const socialMediasLabels = {
    InstagramFeedImage: `Instagram Post ${order}`,
    InstagramFeedVideo: `Instagram Post ${order}`,
    InstagramStoryImage: `Instagram Story ${order}`,
    InstagramStoryVideo: `Instagram Story ${order}`,
    InstagramFeedCarousel: `Instagram Post ${order}`,
    YouTubeVideo: `YouTube Video ${order}`,
  };

  return `${socialMediasLabels[type]}`;
};

export default generateCreativeIterationTitle;
