import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { Button } from 'tabler-react';
import StateLabel from '../StateLabel';
import { CREATIVE_ITERATION_STATES } from '../../../../constants';
import Loader from '../../../../layouts/components/Loader';
import HandleError from '../../../../layouts/components/ErrorMessage/HandleError';
import RequestChanges from './RequestChanges';
import styles from './ReviewerActions.module.css';
import UPDATE_CREATIVE_ITERATION_STATE from './updateCreativeIterationState.mutation';

const ReviewerActions = ({ creativeIteration }) => {
  const { t } = useTranslation();
  const [updateCreativeIterationState, { loading }] = useMutation(UPDATE_CREATIVE_ITERATION_STATE);
  const [error, setError] = useState('');

  const handleApprove = async event => {
    event.preventDefault();
    try {
      await updateCreativeIterationState({
        variables: {
          creativeIterationId: creativeIteration.id,
          state: CREATIVE_ITERATION_STATES.APPROVED,
        },
      });
    } catch (err) {
      setError(err);
    }
  };

  if (creativeIteration.state === CREATIVE_ITERATION_STATES.PENDING) {
    return (
      <div data-testid="ReviewerActions">
        {loading && <Loader />}
        {error && (
          <p className="text-center text-danger">
            <HandleError error={error} />
          </p>
        )}
        <div className={styles.ActionContainer}>
          <Button
            className={`w-50 text-center ${styles.ActionApprove}`}
            color="success"
            onClick={handleApprove}
          >
            {t('Approve')}
          </Button>
          <RequestChanges creativeIteration={creativeIteration} />
        </div>
      </div>
    );
  }

  return <StateLabel className="mx-0" creativeIteration={creativeIteration} />;
};

ReviewerActions.propTypes = {
  creativeIteration: PropTypes.shape({
    id: PropTypes.string.isRequired,
    state: PropTypes.oneOf(Object.values(CREATIVE_ITERATION_STATES)).isRequired,
  }).isRequired,
};

export default ReviewerActions;
