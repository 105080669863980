import axios from 'axios';
import viewerUtils from './utils/viewer';

axios.defaults.baseURL = process.env.REACT_APP_REST_API_URL;
axios.interceptors.request.use(config => {
  const updatedConfig = config;
  if (viewerUtils.isAuthenticated()) {
    updatedConfig.headers.Authorization = `Bearer ${viewerUtils.readToken()}`;
  }
  return updatedConfig;
});
