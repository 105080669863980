import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'tabler-react';
import PropTypes from 'prop-types';
import Drawer from '../../../../../layouts/components/Drawer';
import Form from './Form';
import styles from './MarkAsPosted.module.css';

const MarkAsPosted = ({ creativeIterationId }) => {
  const { t } = useTranslation();
  const [postedFormIsOpen, setPostedFormIsOpen] = useState(false);

  return (
    <>
      <Drawer
        handleClose={() => {
          setPostedFormIsOpen(false);
        }}
        isOpen={postedFormIsOpen}
      >
        {postedFormIsOpen && (
          <Form
            creativeIterationId={creativeIterationId}
            onSubmitSuccess={() => {
              setPostedFormIsOpen(false);
            }}
          />
        )}
      </Drawer>
      <Button
        className={`text-center w-100 ${styles.Posted}`}
        onClick={() => setPostedFormIsOpen(true)}
      >
        {t('Mark as Posted')}
      </Button>
    </>
  );
};

MarkAsPosted.propTypes = {
  creativeIterationId: PropTypes.string.isRequired,
};

export default MarkAsPosted;
