import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'tabler-react';
import Drawer from '../../../layouts/components/Drawer';
import Form from './Form';
import styles from './EditCampaign.module.css';

const EditCampaign = ({ campaign }) => {
  const [editCampaignFormIsOpen, setEditCampaignFormIsOpen] = useState(false);

  return (
    <div className="float-right">
      <Drawer
        handleClose={() => {
          setEditCampaignFormIsOpen(false);
        }}
        isOpen={editCampaignFormIsOpen}
      >
        <Form
          campaign={campaign}
          className={styles.Form}
          onSubmitSuccess={() => {
            setEditCampaignFormIsOpen(false);
          }}
        />
      </Drawer>
      <span data-testid="EditCampaignButton">
        <Icon
          className={styles.PencilIcon}
          name="pencil"
          onClick={() => {
            setEditCampaignFormIsOpen(true);
          }}
          prefix="fa"
        />
      </span>
    </div>
  );
};

EditCampaign.propTypes = {
  campaign: PropTypes.shape({}).isRequired,
};

export default EditCampaign;
