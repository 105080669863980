import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Grid } from 'tabler-react';
import Authorized from '../../../layouts/Authorized';
import PageHeader from '../../../layouts/components/PageHeader';
import PageTitle from '../../../layouts/components/PageTitle';
import PageActions from '../../../layouts/components/PageActions';
import ContentSummaryListQueryContainer from '../../components/ContentSummaryListQueryContainer';
import ContentSummaryList from '../../components/ContentSummaryList/ContentSummaryList';
import AddInfluencerContent from '../../components/AddInfluencerContent';

const CampaignContentSummaryPage = () => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const campaignShortcode = slug.replace(/.*-(.+)$/, '$1');

  return (
    <Authorized>
      <ContentSummaryListQueryContainer shortcode={campaignShortcode}>
        {campaign => {
          const {
            brand: { name: brandName },
            name,
            contentSummary,
            permissions: { viewerCanAddContent },
          } = campaign;

          return (
            <Container className="h-100 pt-4">
              <Grid.Row>
                <Grid.Col>
                  <PageHeader className="w-100">
                    <PageTitle subtitle={brandName} title={`${name} / ${t('Content')}`} />
                    <PageActions>
                      {viewerCanAddContent && <AddInfluencerContent campaign={campaign} />}
                    </PageActions>
                  </PageHeader>
                </Grid.Col>
              </Grid.Row>
              <ContentSummaryList contentSummary={contentSummary} />
            </Container>
          );
        }}
      </ContentSummaryListQueryContainer>
    </Authorized>
  );
};

export default CampaignContentSummaryPage;
