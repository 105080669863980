import slugify from 'slugify';

export const campaignSlug = campaign => {
  const {
    brand: { name: brandName },
    name,
    shortcode,
  } = campaign;

  return slugify(`${brandName} ${name} ${shortcode}`, {
    lower: true,
  });
};

export const generateShortlistUrl = campaign => {
  return `/campaigns/${campaignSlug(campaign)}/shortlist`;
};

export const generateContentUrl = campaign => {
  return `/campaigns/${campaignSlug(campaign)}/content`;
};

export const generateInfluencerContentUrl = campaign => {
  const {
    content: {
      influencer: { id: influencerId },
    },
  } = campaign;

  return `/campaigns/${campaignSlug(campaign)}/content/${influencerId}`;
};

export const generateContentUrlFromDeliverable = deliverable => {
  const {
    id: deliverableId,
    campaign,
    socialProfile: {
      influencer: { id: influencerId },
    },
  } = deliverable;

  return `/campaigns/${campaignSlug(
    campaign
  )}/content/${influencerId}?deliverable=${deliverableId}`;
};
