import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { Grid, Form as TablerForm, Button } from 'tabler-react';
import Loader from '../../../../layouts/components/Loader';
import HandleError from '../../../../layouts/components/ErrorMessage/HandleError';
import CREATE_SHORTLIST from './createShortlist.mutation';

const Form = ({ campaign, onSubmitSuccess, onCloseForm }) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');

  const [createShortlist, { loading }] = useMutation(CREATE_SHORTLIST, {
    update: () => {
      onSubmitSuccess();
    },
  });

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      await createShortlist({
        variables: {
          campaignId: campaign.id,
        },
      });
    } catch (err) {
      setError(error);
    }
  };

  return (
    <div
      className="h-100 justify-content-center align-items-center d-flex"
      data-testid="CreateShortlistForm"
    >
      <Grid.Row>
        {loading && <Loader />}
        <Grid.Col>
          <TablerForm onSubmit={handleSubmit}>
            <h3 className="text-center">
              {t('Are you sure you want to create a shortlist for {{campaignName}}?', {
                campaignName: `${campaign.brand.name}: ${campaign.name}`,
              })}
            </h3>
            {error && (
              <h6 className="text-danger text-center">
                <HandleError error={error} />
              </h6>
            )}
            <Grid.Row className="justify-content-center">
              <Button className="mr-4 px-4 py-2" color="success" type="submit">
                {t('Yes')}
              </Button>
              <Button
                className="px-4 py-2"
                color="danger"
                onClick={() => {
                  onCloseForm();
                }}
                type="button"
              >
                {t('Cancel')}
              </Button>
            </Grid.Row>
          </TablerForm>
        </Grid.Col>
      </Grid.Row>
    </div>
  );
};

Form.propTypes = {
  campaign: PropTypes.shape({
    brand: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onCloseForm: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
};

export default Form;
