/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { MEDIA_TYPES } from '../../../../../constants';
import styles from './DraggableFilesArea.module.css';

const DraggableFilesArea = ({ files, onDragEnd, onRemoveFile }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable direction="horizontal" droppableId="droppable">
        {outerProvided => (
          <div
            {...outerProvided.droppableProps}
            ref={outerProvided.innerRef}
            className={styles.List}
          >
            {files.map((item, index) => {
              return (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {provided => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={styles.ImageContainer}
                      style={provided.draggableProps.style}
                    >
                      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/control-has-associated-label, jsx-a11y/interactive-supports-focus */}
                      <span
                        className={styles.RemoveButton}
                        onClick={() => onRemoveFile(item)}
                        role="button"
                      />
                      {item.type.toUpperCase() === MEDIA_TYPES.IMAGE && (
                        <div
                          className={styles.Image}
                          style={{ backgroundImage: `url("${item.path}")` }}
                        />
                      )}
                      {(item.type === 'file' || item.type.toUpperCase() === MEDIA_TYPES.VIDEO) && (
                        <div
                          className={styles.Image}
                          style={{
                            backgroundImage: `url("https://s3.eu-central-1.amazonaws.com/tailify-site-images/social_icons/icons8-play-button-96.png")`,
                          }}
                        />
                      )}
                      <span className={styles.FileName}>
                        {item.name ? item.name : item.path.split('/').splice(-1)[0]}
                      </span>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {outerProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

DraggableFilesArea.defaultProps = {
  files: [],
};

DraggableFilesArea.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({})),
  onDragEnd: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
};

export default DraggableFilesArea;
/* eslint-enable react/jsx-props-no-spreading */
