import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'tabler-react';

import './styles.css';

const GenderFilter = ({ currentGenderFilters, onApplyFilters }) => {
  const checkedGenders = new Set(currentGenderFilters);
  const handleGenderChange = event => {
    const {
      target: { value, checked },
    } = event;

    if (checked) {
      checkedGenders.add(value);
    } else {
      checkedGenders.delete(value);
    }

    onApplyFilters({ gender: [...checkedGenders] });
  };

  return (
    <div className="genders-filter" data-testid="genderFilter">
      <Form.Group>
        <Form.SelectGroup canSelectMultiple>
          <Form.SelectGroupItem
            key="MALE"
            checked={checkedGenders.has('MALE')}
            className="w-50"
            label="Male"
            name="gender"
            onChange={handleGenderChange}
            value="MALE"
          />
          <Form.SelectGroupItem
            key="FEMALE"
            checked={checkedGenders.has('FEMALE')}
            className="w-50"
            label="Female"
            name="gender"
            onChange={handleGenderChange}
            value="FEMALE"
          />
        </Form.SelectGroup>
      </Form.Group>
    </div>
  );
};

GenderFilter.defaultProps = {
  currentGenderFilters: [],
};

GenderFilter.propTypes = {
  currentGenderFilters: PropTypes.arrayOf(PropTypes.string),
  onApplyFilters: PropTypes.func.isRequired,
};
export default GenderFilter;
