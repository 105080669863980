import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { MEDIA_TYPES } from '../../../../constants';
import './carouselStyles.css';
import styles from './ContentMedia.module.css';

const ContentMedia = ({ media, className }) => {
  const mediaContent = media.map(({ id, path, type }) => (
    <div key={id} className="w-100" data-testid="ContentMedia">
      {type === MEDIA_TYPES.IMAGE ? (
        <img alt="" src={path} />
      ) : (
        <video className={`${styles.Video} w-100 d-block`} controls src={path} type="video/mp4" />
      )}
    </div>
  ));

  if (mediaContent.length === 0) {
    return (
      <div
        className={`${styles.NotUploaded} justify-content-center align-items-center d-flex ${className}`}
      >
        <p>No media uploaded yet</p>
      </div>
    );
  }

  if (media.length > 1) {
    return (
      <Carousel
        className="w-100"
        showThumbs={false}
        statusFormatter={(current, total) => `${current}/${total}`}
      >
        {mediaContent}
      </Carousel>
    );
  }
  return mediaContent;
};

ContentMedia.propTypes = {
  media: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      type: PropTypes.oneOf(Object.values(MEDIA_TYPES)),
    })
  ).isRequired,
};

export default ContentMedia;
