import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'tabler-react';
import styles from './PreviewGallery.module.css';

const PreviewGallery = ({ urls }) => {
  return (
    <>
      <Grid.Row className="mx-0">
        <div
          className={`${styles.LargeImageContainer}`}
          style={{ backgroundImage: `url("${urls[0]}")` }}
        >
          <div className={styles.LargeImageDivider} />
        </div>
        <div
          className={`pl-0 pr-0 ${styles.LargeImageContainer}`}
          style={{ backgroundImage: `url("${urls[1]}")` }}
        />
      </Grid.Row>
      <div className={styles.HorizontalDivider} />
      <Grid.Row className="mx-0">
        <div
          className={`pl-0 pr-0 ${styles.SmallImageContainer}`}
          style={{ backgroundImage: `url("${urls[2]}")` }}
        />
        <div
          className={`pl-0 pr-0 ${styles.SmallImageContainer}`}
          style={{ backgroundImage: `url("${urls[3]}")` }}
        >
          <div className={styles.SmallImageLeftDivider} />
          <div className={styles.SmallImageRightDivider} />
        </div>
        <div
          className={`pl-0 pr-0 ${styles.SmallImageContainer}`}
          style={{ backgroundImage: `url("${urls[4]}")` }}
        />
      </Grid.Row>
    </>
  );
};

PreviewGallery.defaultProps = {
  urls: [],
};

PreviewGallery.propTypes = {
  urls: PropTypes.arrayOf(PropTypes.string),
};

export default PreviewGallery;
