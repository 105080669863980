import React, { useState } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import {
  StringParam,
  ArrayParam,
  useQueryParams,
  BooleanParam,
  DelimitedArrayParam,
} from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { Icon } from 'tabler-react';
import Authorized from '../../../layouts/Authorized';
import MainContent from '../../../layouts/components/MainContent';
import PageHeader from '../../../layouts/components/PageHeader';
import PageActions from '../../../layouts/components/PageActions';
import PageTitle from '../../../layouts/components/PageTitle';
import InfluencerViewButtons from '../../components/InfluencerViewButtons';
import InfluencersListSidebar from '../../components/InfluencersListSidebar';
import InfluencersListQueryContainer from '../../components/InfluencersListQueryContainer/InfluencersListQueryContainer';
import InfluencersList from '../../components/InfluencersList';
import styles from './InfluencersListPage.module.css';

const InfluencersListPage = () => {
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const [filters, setFilters] = useQueryParams({
    query: StringParam,
    platform: StringParam,
    platforms: ArrayParam,
    agency: BooleanParam,
    channel: StringParam,
    gender: ArrayParam,
    brands: DelimitedArrayParam,
    countries: DelimitedArrayParam,
    engagementRate: ArrayParam,
    followerCount: ArrayParam,
    subscriberCount: ArrayParam,
    contentType: StringParam,
    priceRange: ArrayParam,
  });
  const [view, setView] = useState('table');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const handleApplyFilters = newFilters => setFilters(newFilters, 'pushIn');

  const handleResetFilters = platform => {
    document.getElementById('sidebar').scrollTop = 0;
    setFilters({ platform }, 'replace');
  };

  const { platform } = filters;

  if (!platform || !['INSTAGRAM', 'YOUTUBE'].includes(platform)) {
    return <Redirect to={`${pathname}?platform=INSTAGRAM${search && '&'}${search.substr(1)}`} />;
  }

  const setNetworkIcon = (network, colored) => {
    if (network === 'INSTAGRAM') {
      return <Icon className={`${styles.Icon} ${colored}`} name="instagram" />;
    }
    if (network === 'YOUTUBE') {
      return <Icon className={`${styles.Icon} ${colored}`} name="youtube-play" prefix="fa" />;
    }
    return null;
  };

  const getTableWidth = () => {
    let widthTable = 1070;
    if (window.innerWidth > 1285) {
      if (isSidebarOpen) {
        widthTable = window.innerWidth - 370;
      } else widthTable = window.innerWidth - 140;
    }
    return widthTable;
  };

  return (
    <Authorized>
      <InfluencersListSidebar
        filters={filters}
        isSidebarOpen={isSidebarOpen}
        onApplyFilters={handleApplyFilters}
        onClickButton={() => setIsSidebarOpen(current => !current)}
        onOpenSidebar={() => setIsSidebarOpen(true)}
        onResetFilters={handleResetFilters}
        onSetNetworkIcon={(network, colored) => setNetworkIcon(network, colored)}
      />
      <InfluencersListQueryContainer filters={filters}>
        {profiles => {
          return (
            <div className="container-fluid d-flex">
              <MainContent
                className={`${styles.InfluencersListPage} h-100 ${
                  isSidebarOpen ? styles.Open : styles.Closed
                }`}
                colOffsetSize="30"
              >
                <PageHeader>
                  {setNetworkIcon(platform)}
                  <PageTitle title={t('Influencers list')} />
                  <PageActions>
                    <InfluencerViewButtons setView={setView} view={view} />
                  </PageActions>
                </PageHeader>
                <InfluencersList
                  isSidebarOpen={isSidebarOpen}
                  platform={platform}
                  profiles={profiles}
                  tableWidth={getTableWidth()}
                  view={view}
                />
              </MainContent>
            </div>
          );
        }}
      </InfluencersListQueryContainer>
    </Authorized>
  );
};

export default InfluencersListPage;
