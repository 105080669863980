import gql from 'graphql-tag';

export default gql`
  mutation($shortlistedProfileId: ID!) {
    removeShortlistedProfile(input: { shortlistedProfileId: $shortlistedProfileId }) {
      shortlistedProfile {
        id
      }
    }
  }
`;
