import React from 'react';
import PropTypes from 'prop-types';
import InfluencerGenderAndLocation from '../InfluencerGenderAndLocation';
import styles from './InfluencerWidget.module.css';

const InfluencerWidget = ({ influencer, imageSize, hasInfo }) => {
  const { name, pictureUrl, username } = influencer;

  return (
    <div className={styles.InfluencerWidget}>
      <div
        className={`${styles.ProfileImage} ${imageSize}`}
        style={{
          backgroundImage: `url("${pictureUrl}")`,
          width: imageSize,
          height: imageSize,
        }}
      />
      <div className={`${styles.InfluencerData}`}>
        <div className={styles.DataTitle}>{name}</div>
        {username && <div className={styles.DataSubTitle}>{username}</div>}
        {hasInfo && <InfluencerGenderAndLocation influencer={influencer} />}
      </div>
    </div>
  );
};

InfluencerWidget.defaultProps = {
  hasInfo: false,
  imageSize: 60,
};

InfluencerWidget.propTypes = {
  hasInfo: PropTypes.bool,
  imageSize: PropTypes.oneOf([60, 80, 100]),
  influencer: PropTypes.shape({
    country: PropTypes.string,
    gender: PropTypes.string,
    name: PropTypes.string,
    pictureUrl: PropTypes.string,
    username: PropTypes.string,
  }).isRequired,
};

export default InfluencerWidget;
