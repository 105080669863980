import Cookies from 'universal-cookie';

const cookies = new Cookies();

const writeToken = token => cookies.set('token', token, { path: '/' });
const readToken = () => cookies.get('token');
const writeViewer = viewer => cookies.set('viewer', viewer, { path: '/' });
const readViewer = () => cookies.get('viewer');
const isBetaUser = () => readViewer().isBetaUser;
const canCreateCampaigns = () => readViewer().viewerCanCreateCampaigns;

const clearToken = () => {
  cookies.remove('token', { path: '/' });
  cookies.remove('viewer', { path: '/' });
};

const isAuthenticated = () => {
  return Boolean(cookies.get('token')) && Boolean(cookies.get('viewer'));
};

const viewer = {
  isBetaUser,
  isAuthenticated,
  writeToken,
  writeViewer,
  readToken,
  readViewer,
  canCreateCampaigns,
  clearToken,
};

export default viewer;
