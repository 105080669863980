import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Button, Avatar, Grid, Icon } from 'tabler-react';
import Drawer from '../Drawer';
import Footer from '../Footer/Footer';
import LogoutButton from '../../../users/components/LogoutButton';
import viewerUtils from '../../../utils/viewer';
import styles from './DrawerMenu.module.css';
import './sliderStyles.css';

const DrawerMenu = () => {
  const { t } = useTranslation();
  const viewer = viewerUtils.readViewer();

  // add default empty permissions
  const { permissions } = { permissions: [], ...viewer };
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <>
      <button
        className={`${styles.MenuLink} fa-2x`}
        onClick={event => {
          event.preventDefault();
          setMenuIsOpen(true);
        }}
        onKeyPress={() => {}}
        tabIndex="0"
        type="button"
      >
        <Icon name="more-horizontal" />
      </button>
      <Drawer
        className={`${styles.Drawer} ${menuIsOpen ? styles.DrawerActive : ''} `}
        handleClose={() => setMenuIsOpen(false)}
        isOpen={menuIsOpen}
      >
        <div className={styles.Wrapper}>
          <Grid.Row className="pt-3">
            <Grid.Col className="pt-2" offsetSm="1" offsetXs="2" xs="2">
              <LogoutButton />
            </Grid.Col>
            <Grid.Col className="text-right" offsetSm="5" offsetXs="4" xs="3">
              <Button
                className={styles.CloseBtn}
                color="primary"
                onClick={() => setMenuIsOpen(false)}
              >
                {t('Close')}
              </Button>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row className="pt-4">
            <Grid.Col className="d-flex pt-3">
              <Avatar
                className={`d-flex justify-content-center align-items-center mx-auto mt-4 ${styles.Avatar}`}
                imageURL={viewer.avatar}
                size="xxl"
              >
                {viewer.firstName && viewer.firstName[0]} {viewer.lastName && viewer.lastName[0]}
              </Avatar>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row className="pt-4">
            <Grid.Col className="pt-2">
              <div className={`mt-4 ${styles.ProfileName}`}>
                {viewer.firstName} {viewer.lastName}
              </div>
            </Grid.Col>
          </Grid.Row>
          <nav className={styles.NavContainer}>
            <ul className={`pl-0 ${styles.NavList}`}>
              {permissions.includes('campaigns') && (
                <li className={styles.NavItem}>
                  <NavLink activeClassName={styles.ActiveLink} to="/campaigns">
                    {t('Campaigns')}
                  </NavLink>
                </li>
              )}
              {permissions.includes('content') && (
                <li className={styles.NavItem}>
                  <NavLink activeClassName={styles.ActiveLink} to="/content">
                    {t('Content')}
                  </NavLink>
                </li>
              )}
              {permissions.includes('influencers') && (
                <li>
                  <NavLink activeClassName={styles.ActiveLink} to="/influencers">
                    {t('Influencers')}
                  </NavLink>
                </li>
              )}
              {permissions.includes('profile-management') && (
                <li>
                  <NavLink activeClassName={styles.ActiveLink} to="/settings/account">
                    {t('Account')}
                  </NavLink>
                </li>
              )}
            </ul>
          </nav>
        </div>
        <Footer className={styles.DrawerFooter} />
      </Drawer>
    </>
  );
};

export default DrawerMenu;
