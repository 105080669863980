import gql from 'graphql-tag';

export default gql`
  mutation($id: ID!, $caption: String, $media: [MediaFile], $price: Float!) {
    updateCreativeIteration(
      input: { creativeIterationId: $id, caption: $caption, media: $media, price: $price }
    ) {
      creativeIteration {
        id
        caption
        state
        media {
          id
          path
          type
          order
        }
        price
        type
        deliverable {
          id
          campaign {
            id
          }
        }
      }
    }
  }
`;
