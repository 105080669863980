import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { Form as TablerForm, Button } from 'tabler-react';
import Loader from '../../../../layouts/components/Loader';
import HandleError from '../../../../layouts/components/ErrorMessage/HandleError';
import GET_CAMPAIGN_CONTENT from '../../InfluencerContentForCampaignPageQueryContainer/campaignContent.query';
import GET_CAMPAIGN_CONTENT_SUMMARY from '../../ContentSummaryListQueryContainer/contentSummary.query';
import InputWithLoader from '../../../../layouts/components/InputWithLoader/InputWithLoader';
import SocialProfiles from '../../Typeahead/SocialProfiles';
import ADD_CONTENT_MUTATION from './addContent.mutation';
import styles from './Form.module.css';

const YOU_TUBE_REGEX = /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)&?/;

const Form = ({ campaign, isForInfluencer, onSubmitSuccess, influencerId }) => {
  const { t } = useTranslation();

  const profiles = campaign?.content?.influencer?.profiles;

  const { shortcode } = campaign;
  const [error, setError] = useState();
  const [url, setUrl] = useState('');
  const [numberOfPosts, setNumberOfPosts] = useState(0);
  const [numberOfStories, setNumberOfStories] = useState(0);
  const [numberOfVideos, setNumberOfVideos] = useState(0);
  const [isForInstagram, setIsForInstagram] = useState();
  const [typingTimeout, setTypingTimeout] = useState();
  const [typeLoading, setTypeLoading] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState();

  useEffect(() => {
    if (isForInfluencer && profiles.length === 1) {
      const [onlyProfile] = profiles;
      setSelectedProfile(onlyProfile);
      const { __typename } = onlyProfile;
      setIsForInstagram(__typename === 'InstagramProfile');
    }

    setUrl(url);
  }, [isForInfluencer, profiles, selectedProfile, url]);

  const numbersArray = new Array(20).fill().map((_, index) => index);

  const [addContent, { loading }] = useMutation(ADD_CONTENT_MUTATION, {
    update: onSubmitSuccess,
    refetchQueries: [
      influencerId
        ? ({
            query: GET_CAMPAIGN_CONTENT_SUMMARY,
            variables: { shortcode },
          },
          {
            query: GET_CAMPAIGN_CONTENT,
            variables: { shortcode, influencerId },
          })
        : {
            query: GET_CAMPAIGN_CONTENT_SUMMARY,
            variables: { shortcode },
          },
    ],
  });

  const handleSubmit = async event => {
    event.preventDefault();

    const variables = {
      campaignId: campaign.id,
      ...(isForInstagram
        ? {
            numberOfPosts,
            numberOfStories,
            ...(isForInfluencer
              ? { socialProfileId: selectedProfile.id }
              : { handle: url || selectedProfile.url }),
          }
        : {
            numberOfVideos,
            ...(isForInfluencer
              ? { socialProfileId: selectedProfile.id }
              : { youtubeChannel: url || selectedProfile.url }),
          }),
    };

    try {
      await addContent({
        variables,
      });
    } catch (err) {
      setError(err);
    }
  };

  const setInputsByPlatform = platformUrl => {
    if (YOU_TUBE_REGEX.test(platformUrl)) {
      setIsForInstagram(false);
    } else {
      setIsForInstagram(true);
    }
  };

  const handleUrlKeyUp = ({ target: { value: newUrl } }) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        setInputsByPlatform(newUrl);
        setTypeLoading(false);
      }, 500)
    );
  };

  const handleSelectProfile = ({ value }) => {
    const profileToSelect = profiles.find(({ id }) => id === value);
    setSelectedProfile(profileToSelect);
    const { __typename } = profileToSelect;
    setIsForInstagram(__typename === 'InstagramProfile');
  };

  return (
    <div className={styles.Form} data-testid="AddInfluencerContentForm">
      {/* have this here so we don't reset the form after submit */}
      {loading && <Loader />}
      <TablerForm onSubmit={handleSubmit}>
        <h2 className="text-center w-100 pb-3">{t('Add Influencer Content')}</h2>

        {!isForInfluencer && (
          <TablerForm.Label>
            {t('Instagram or YouTube URL')}
            <InputWithLoader
              className="mb-3"
              cross={false}
              isLoading={typeLoading}
              name="url"
              onChange={({ target }) => {
                setTypeLoading(true);
                setUrl(target.value);
              }}
              onKeyUp={handleUrlKeyUp}
              value={url}
            />
          </TablerForm.Label>
        )}

        {isForInfluencer && profiles.length > 1 && (
          <TablerForm.Label>
            {t('Influencer Profile')}
            <SocialProfiles onChange={handleSelectProfile} profiles={profiles} />
          </TablerForm.Label>
        )}

        {isForInstagram === true && (url !== '' || selectedProfile) && (
          <div className={styles.SelectAreaContainer}>
            <div className={styles.SelectArea}>
              <TablerForm.Label>
                {t('Number of posts')}
                <select
                  className="form-control custom-select"
                  data-testid="NumberOfPosts"
                  defaultValue={numberOfPosts}
                  onBlur={event => setNumberOfPosts(parseInt(event.target.value, 10))}
                >
                  {numbersArray.map(num => (
                    <option key={num}>{num}</option>
                  ))}
                </select>
              </TablerForm.Label>
            </div>
            <div className={styles.SelectArea}>
              <TablerForm.Label>
                {t('Number of stories')}
                <select
                  className="form-control custom-select"
                  data-testid="NumberOfStories"
                  defaultValue={numberOfStories}
                  onBlur={event => setNumberOfStories(parseInt(event.target.value, 10))}
                >
                  {numbersArray.map(num => (
                    <option key={num}>{num}</option>
                  ))}
                </select>
              </TablerForm.Label>
            </div>
          </div>
        )}
        {isForInstagram === false && (url !== '' || selectedProfile) && (
          <div className={styles.SelectAreaContainer}>
            <div className={styles.SelectArea}>
              <TablerForm.Label>
                {t('Number of videos')}
                <select
                  className="form-control custom-select"
                  data-testid="NumberOfVideos"
                  defaultValue={numberOfVideos}
                  onBlur={event => setNumberOfVideos(parseInt(event.target.value, 10))}
                >
                  {numbersArray.map(num => (
                    <option key={num}>{num}</option>
                  ))}
                </select>
              </TablerForm.Label>
            </div>
          </div>
        )}
        <TablerForm.Footer className="mt-3 text-center">
          <Button
            block
            className="w-auto mx-auto px-4 py-2"
            color="success"
            disabled={loading}
            type="submit"
          >
            {t('Add')}
          </Button>
          {error && (
            <p className="text-danger">
              <HandleError error={error} />
            </p>
          )}
        </TablerForm.Footer>
      </TablerForm>
    </div>
  );
};

Form.defaultProps = {
  influencerId: null,
  isForInfluencer: false,
};

Form.propTypes = {
  campaign: PropTypes.shape({
    content: PropTypes.shape({
      influencer: PropTypes.shape({
        profiles: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
    id: PropTypes.string.isRequired,
    shortcode: PropTypes.string,
  }).isRequired,
  influencerId: PropTypes.string,
  isForInfluencer: PropTypes.bool,
  onSubmitSuccess: PropTypes.func.isRequired,
};

export default Form;
