import React from 'react';
import PropTypes from 'prop-types';
import styles from './Loader.module.css';

const Loader = ({ message }) => (
  <div className={styles.AppLoader} data-testid="Loader">
    <div className={styles.LoaderSpinner}>
      <div className={`${styles.Dot} ${styles.Dot1}`} />
      <div className={`${styles.Dot} ${styles.Dot2}`} />
      <div className={`${styles.Dot} ${styles.Dot3}`} />
    </div>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter id="goo">
          <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />
          <feColorMatrix
            in="blur"
            mode="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7"
          />
        </filter>
      </defs>
    </svg>
    {message && <div className={styles.AppLoaderMessage}>{message}</div>}
  </div>
);

Loader.defaultProps = {
  message: '',
};

Loader.propTypes = {
  message: PropTypes.string,
};

export default Loader;
