import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'tabler-react';
import Drawer from '../../../../../layouts/components/Drawer';
import Form from './Form';

const ShowToReviewer = ({ creativeIteration }) => {
  const { t } = useTranslation();
  const [showToReviewerIsOpen, setShowToReviewerIsOpen] = useState(false);

  return (
    <>
      <Drawer
        handleClose={() => {
          setShowToReviewerIsOpen(false);
        }}
        isOpen={showToReviewerIsOpen}
      >
        {showToReviewerIsOpen && (
          <Form
            creativeIterationId={creativeIteration.id}
            onCloseForm={() => {
              setShowToReviewerIsOpen(false);
            }}
            onSubmitSuccess={() => {
              setShowToReviewerIsOpen(false);
            }}
          />
        )}
      </Drawer>
      <Button
        className="text-center w-100"
        color="warning"
        onClick={() => setShowToReviewerIsOpen(true)}
      >
        {t('Show To Reviewer')}
      </Button>
    </>
  );
};

ShowToReviewer.propTypes = {
  creativeIteration: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default ShowToReviewer;
