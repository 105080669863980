import React from 'react';
import PropTypes from 'prop-types';
import styles from './Drawer.module.css';

const Drawer = ({ children, className, isOpen, handleClose }) => {
  return (
    <>
      <div
        className={`${styles.Backdrop} ${isOpen ? styles.BackdropActive : ''}`}
        onClick={handleClose}
        onKeyPress={() => false}
        role="button"
        tabIndex="0"
      >
        backdrop
      </div>
      <div className={`${className} ${styles.SideDrawer} ${isOpen ? styles.Open : styles.Closed}`}>
        {children}
      </div>
    </>
  );
};

Drawer.defaultProps = {
  className: '',
};
Drawer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default Drawer;
