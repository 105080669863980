import gql from 'graphql-tag';

export default gql`
  mutation($campaignId: ID!, $socialProfileId: ID!, $rationale: String, $isRecommended: Boolean) {
    addToShortlist(
      input: {
        campaignId: $campaignId
        socialProfileId: $socialProfileId
        rationale: $rationale
        isRecommended: $isRecommended
      }
    ) {
      shortlistedSocialProfile {
        id
        node {
          ... on InstagramProfile {
            name
            id
          }
          ... on YouTubeChannel {
            title
            id
          }
        }
      }
    }
  }
`;
