import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getName as getCountryName } from 'i18n-iso-countries';
import { Grid, Card } from 'tabler-react';
import styles from './BaseInfo.module.css';
import InfluencerSocialProfileStats from './InfluencerSocialProfileStats';

const BaseInfo = ({ influencer }) => {
  const { t } = useTranslation();
  const { biography, name, pictureUrl, country, gender, email } = influencer;
  const genderCapitalCase = gender && gender[0] + gender.slice(1).toLowerCase();

  return (
    <div className={styles.InfluencerProfileDetails}>
      <Card className={`${styles.ProfileCard} px-3 pt-2`}>
        <Grid.Row>
          <Grid.Col className="d-flex justify-content-center">
            <div
              className={`${styles.ProfileImage}`}
              style={{
                backgroundImage: `url("${pictureUrl}")`,
              }}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row className="pt-3">
          <Grid.Col className="d-flex justify-content-center text-center">
            <h2 className={`${styles.Name} mb-0`}>{name}</h2>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row className="justify-content-center text-center">
          {email && (
            <p className={`${styles.PersonalDetails} mb-0 px-2`}>
              <a
                className="c-pointer"
                href={`mailto:${email}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {email}
              </a>
            </p>
          )}
        </Grid.Row>
        <Grid.Row className="pt-2 justify-content-center text-center">
          <p className={`${styles.PersonalDetails} mb-0 px-2`}>{getCountryName(country, 'en')}</p>
          {gender && (
            <p className={`${styles.PersonalDetails} mb-0 px-2`}>{t(genderCapitalCase)}</p>
          )}
        </Grid.Row>
        <Grid.Row className="pt-4">
          <Grid.Col className="d-flex justify-content-center">
            {biography && (
              <>
                <Grid.Col className="d-flex align-items-center col-6">
                  <p className={`${styles.PersonalDetails} mb-0`}>{biography}</p>
                </Grid.Col>
                <Grid.Col className="d-flex align-items-center">
                  <div className={styles.VerticalLine} />
                </Grid.Col>
              </>
            )}
            <Grid.Col className=" d-flex flex-wrap align-items-center justify-content-center col-6">
              {influencer.profiles.map(profile => {
                return <InfluencerSocialProfileStats key={profile.id} profile={profile} />;
              })}
            </Grid.Col>
          </Grid.Col>
        </Grid.Row>
      </Card>
    </div>
  );
};

BaseInfo.propTypes = {
  influencer: PropTypes.shape({
    biography: PropTypes.string,
    country: PropTypes.string,
    email: PropTypes.string,
    gender: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    pictureUrl: PropTypes.string,
    profiles: PropTypes.arrayOf(
      PropTypes.shape({
        biography: PropTypes.string,
        id: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default BaseInfo;
