import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './AudienceCities.module.css';

const AudienceCities = ({ cities }) => {
  const { t } = useTranslation();

  if (cities.length === 0) {
    return (
      <p className={`${styles.NoDataLabel} pt-4`}>{t('There is no audience data available.')}</p>
    );
  }

  const highestValue = Math.max(...cities.map(({ value }) => value));
  const topThreeCities = cities.sort((current, next) => next.value - current.value).slice(0, 3);
  return (
    <div>
      {topThreeCities.map(({ value, city }) => (
        <div key={city} className="pb-2">
          <div
            className={styles.Bar}
            style={{
              width:
                value === highestValue ? '100%' : `${((value * 100) / highestValue).toString()}%`,
            }}
          />
          <div className={styles.Label}>
            <span>
              {city} - {value}%
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

AudienceCities.propTypes = {
  cities: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default AudienceCities;
