import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import Loader from '../../../layouts/components/Loader';
import ErrorMessage from '../../../layouts/components/ErrorMessage';
import GET_BRANDS_LIST from './clients.query';

const ClientsListQueryContainer = ({ children }) => {
  const { loading, error, data } = useQuery(GET_BRANDS_LIST);

  if (error) return <ErrorMessage error={error} />;

  if (loading) return <Loader />;

  const { brands } = data;

  return <div className="scrollableContainer h-100">{children(brands)}</div>;
};

ClientsListQueryContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default ClientsListQueryContainer;
