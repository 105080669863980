import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SHORTLISTED_SOCIAL_PROFILE_STATES } from '../../../../constants';
import styles from './StateLabel.module.css';

const StateLabel = ({ state }) => {
  const { t } = useTranslation();
  switch (state) {
    case SHORTLISTED_SOCIAL_PROFILE_STATES.APPROVED:
      return <div className={`${styles.StateLabel} ${styles.StateApproved}`}>{t('Approved')}</div>;
    case SHORTLISTED_SOCIAL_PROFILE_STATES.REJECTED:
      return <div className={`${styles.StateLabel} ${styles.StateRejected}`}>{t('Rejected')}</div>;
    case SHORTLISTED_SOCIAL_PROFILE_STATES.PENDING:
      return <div className={`${styles.StateLabel} ${styles.StatePending}`}>{t('Pending')}</div>;
    default:
      return <div>{state}</div>;
  }
};

StateLabel.propTypes = {
  state: PropTypes.oneOf(Object.values(SHORTLISTED_SOCIAL_PROFILE_STATES)).isRequired,
};

export default StateLabel;
