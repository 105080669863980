import gql from 'graphql-tag';

export default gql`
  mutation($name: String!, $brand: String!, $state: CampaignState!) {
    createCampaign(input: { brand: $brand, name: $name, state: $state }) {
      campaign {
        id
      }
    }
  }
`;
