import React from 'react';
import { useQueryParam, StringParam, ArrayParam } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Authorized from '../../../layouts/Authorized';
import Sidebar from '../../../layouts/components/Sidebar';
import MainContent from '../../../layouts/components/MainContent';
import PageHeader from '../../../layouts/components/PageHeader';
import PageTitle from '../../../layouts/components/PageTitle';
import CampaignsListFilters from '../../../campaigns/components/CampaignsListFilters';
import InfluencerCampaignsQueryContainer from '../../components/InfluencerCampaignsQueryContainer/InfluencerCampaignsQueryContainer';
import InfluencerInfo from '../../components/InfluencerInfo';
import CampaignsList from '../../../campaigns/components/CampaignsList';

const allowedCampaignStates = ['active', 'upcoming', 'completed'];

const InfluencerCampaignsPage = () => {
  const [query, setQuery] = useQueryParam('query', StringParam);
  const [states, setStates] = useQueryParam('states', ArrayParam);

  const { t } = useTranslation();
  const { id } = useParams();

  const handleApplyFilterByState = newStates => setStates(newStates, 'pushIn');

  const handleApplyQuery = newSearch => setQuery(newSearch, 'pushIn');

  // This check here to prevent gql exception for invalid state
  if (states) {
    states.forEach(state => {
      if (!allowedCampaignStates.includes(state)) {
        setStates([]);
      }
    });
  }

  return (
    <Authorized>
      <InfluencerCampaignsQueryContainer id={id} query={query} states={states}>
        {(influencer, campaigns) => (
          <>
            <Sidebar colSize={550}>
              <InfluencerInfo influencer={influencer} />
            </Sidebar>
            <MainContent colOffsetSize={550}>
              <PageHeader>
                <PageTitle title={t('Influencer Campaigns List')} />
              </PageHeader>
              <CampaignsListFilters
                onApplyFilterByState={handleApplyFilterByState}
                onApplyQuery={handleApplyQuery}
                query={query}
                states={states}
              />
              <CampaignsList campaigns={campaigns} />
            </MainContent>
          </>
        )}
      </InfluencerCampaignsQueryContainer>
    </Authorized>
  );
};

export default InfluencerCampaignsPage;
