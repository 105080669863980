import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import styles from './Footer.module.css';

const Footer = ({ className }) => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();
  const icon = '©';
  const company = 'Tailify';

  return (
    <div className={`${styles.Footer} ${className}`}>
      <Trans company={company} i18nKey="trademarkLabel" icon={icon} year={year}>
        {{ icon }} {{ year }} <strong className={styles.CompanyText}>{{ company }}</strong>
      </Trans>
      <a className={styles.TextRight} href="https://www.tailify.com/privacy-policy/">
        {t('Privacy Policy')}
      </a>
    </div>
  );
};

Footer.defaultProps = {
  className: '',
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
