import React from 'react';
import { Grid } from 'tabler-react';
import PropTypes from 'prop-types';
import GenderFilter from '../GenderFilter';
import QueryFilter from '../QueryFilter';
import EngagementRateFilter from '../EngagementRateFilter';
import FollowersCountFilter from '../FollowersCountFilter';
import BrandsFilter from '../BrandsFilter';
import CountriesFilter from '../CountriesFilter';
import PriceFilter from '../PriceFilter';
import AgencyFilter from '../AgencyFilter';

const InstagramFilters = ({ filters, onApplyFilters }) => {
  const {
    query,
    gender,
    agency,
    engagementRate,
    followerCount,
    brands,
    countries,
    contentType,
    priceRange,
  } = filters;

  return (
    <>
      <Grid.Row className="mx-auto mb-3">
        <Grid.Col>
          <QueryFilter onApplyFilters={onApplyFilters} query={query} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className="mx-auto">
        <Grid.Col>
          <BrandsFilter brandsFilters={brands} onApplyFilters={onApplyFilters} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className="mx-auto">
        <Grid.Col>
          <CountriesFilter countriesFilters={countries} onApplyFilters={onApplyFilters} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className="mx-auto">
        <Grid.Col>
          <GenderFilter currentGenderFilters={gender} onApplyFilters={onApplyFilters} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className="mx-auto">
        <Grid.Col>
          <EngagementRateFilter engagementRate={engagementRate} onApplyFilters={onApplyFilters} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className="mx-auto">
        <Grid.Col>
          <FollowersCountFilter followerCount={followerCount} onApplyFilters={onApplyFilters} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className="mx-auto">
        <Grid.Col>
          <PriceFilter
            contentType={contentType}
            onApplyFilters={onApplyFilters}
            priceRange={priceRange}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className="mx-auto">
        <Grid.Col>
          <AgencyFilter agency={agency} onApplyFilters={onApplyFilters} />
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

InstagramFilters.defaultProps = {
  filters: {
    agency: false,
    brands: [],
    contentType: '',
    country: [],
    gender: [],
    query: '',
    priceRange: [],
  },
};

InstagramFilters.propTypes = {
  filters: PropTypes.shape({
    agency: PropTypes.bool,
    brands: PropTypes.arrayOf(PropTypes.string),
    contentType: PropTypes.string,
    countries: PropTypes.arrayOf(PropTypes.string),
    engagementRate: PropTypes.arrayOf(PropTypes.string),
    followerCount: PropTypes.arrayOf(PropTypes.string),
    gender: PropTypes.arrayOf(PropTypes.string),
    priceRange: PropTypes.arrayOf(PropTypes.string),
    query: PropTypes.string,
  }),
  onApplyFilters: PropTypes.func.isRequired,
};

export default InstagramFilters;
