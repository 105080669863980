import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';
import GET_CREATIVE_ITERATION_QUERY from './creativeIteration.query';
import ADD_COMMENT from './addComment.mutation';

const firstComments = 30;

const updateCache = (cache, { data }, subjectId) => {
  const {
    addComment: { comment },
  } = data;

  const cachedData = cache.readQuery({
    query: GET_CREATIVE_ITERATION_QUERY,
    variables: { id: subjectId, firstComments },
  });

  cachedData.creativeIteration.comments.edges.push({ node: comment, __typename: 'CommentEdge' });

  cache.writeQuery({
    query: GET_CREATIVE_ITERATION_QUERY,
    data: cachedData,
    variables: { id: subjectId, firstComments },
  });
};

const InfluencerContentQueryContainer = ({ children, creativeIterationId }) => {
  const { t } = useTranslation();
  const [addEntryError, setAddEntryError] = useState();

  const [addComment] = useMutation(ADD_COMMENT, {
    update: (cache, data) => updateCache(cache, data, creativeIterationId),
  });
  const handleAddNewEntry = async body => {
    setAddEntryError(null);
    try {
      await addComment({
        variables: {
          comment: {
            subjectId: creativeIterationId,
            body,
          },
        },
      });
    } catch (err) {
      setAddEntryError(err);
    }
  };

  const { loading, error, data } = useQuery(GET_CREATIVE_ITERATION_QUERY, {
    variables: {
      firstComments,
      id: creativeIterationId,
    },
  });

  if (error) {
    return <p>{t('Something went wrong. Please, try again later.')}</p>;
  }

  // we are not returning the loader here because it causes the page to flash when switching adverts
  if (loading) return null;

  const { creativeIteration: creativeIterationNode } = data;
  const {
    comments: { edges: commentNodes },
  } = creativeIterationNode;

  return children(
    commentNodes.map(commentNode => commentNode.node),
    handleAddNewEntry,
    addEntryError
  );
};

InfluencerContentQueryContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default InfluencerContentQueryContainer;
