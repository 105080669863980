import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Form } from 'tabler-react';
import BetaFeature from '../../../common/components/BetaFeature';
import styles from './CampaignsListFilters.module.css';

const CampaignsListFilters = ({ onApplyQuery, onApplyFilterByState, query, states }) => {
  const handleCampaignStateChange = event => {
    onApplyFilterByState(event.target.value);
  };
  const handleSearchSubmit = event => {
    event.preventDefault();
    const { search } = event.target.elements;
    onApplyQuery(search.value);
  };

  return (
    <div className={styles.CampaignFilters} data-testid="CampaignFilters">
      <Form onSubmit={handleSearchSubmit}>
        <div className="form-group mb-0">
          <div className="input-icon">
            <span className="input-icon-addon">
              <i className="fe fe-search" />
            </span>
            <input
              className={`form-control ${styles.SearchInput}`}
              data-testid="CampaignSearch"
              defaultValue={query}
              name="search"
              placeholder="Search campaigns"
              type="text"
            />
          </div>
        </div>
      </Form>
      <BetaFeature>
        <Grid.Row className="mt-3">
          <Grid.Col>
            <div data-testid="StateFilters">
              <Form.Group>
                <Form.SelectGroup>
                  <Form.SelectGroupItem
                    checked={states.length === 0}
                    defaultChecked
                    label="All"
                    name="state"
                    onChange={handleCampaignStateChange}
                    value=""
                  />
                  <Form.SelectGroupItem
                    checked={states.includes('active')}
                    label="Active"
                    name="state"
                    onChange={handleCampaignStateChange}
                    value="active"
                  />
                  <Form.SelectGroupItem
                    checked={states.includes('upcoming')}
                    label="Upcoming"
                    name="state"
                    onChange={handleCampaignStateChange}
                    value="upcoming"
                  />
                  <Form.SelectGroupItem
                    checked={states.includes('completed')}
                    label="Completed"
                    name="state"
                    onChange={handleCampaignStateChange}
                    value="completed"
                  />
                </Form.SelectGroup>
              </Form.Group>
            </div>
          </Grid.Col>
        </Grid.Row>
      </BetaFeature>
    </div>
  );
};

CampaignsListFilters.defaultProps = {
  query: '',
  states: [],
};

CampaignsListFilters.propTypes = {
  onApplyFilterByState: PropTypes.func.isRequired,
  onApplyQuery: PropTypes.func.isRequired,
  query: PropTypes.string,
  states: PropTypes.arrayOf(PropTypes.string),
};

export default CampaignsListFilters;
