import gql from 'graphql-tag';

export default gql`
  query($shortcode: String!) {
    campaign(shortcode: $shortcode) {
      id
      shortcode
      brand {
        name
      }
      name
      contentSummary {
        approved
        toApprove
        changesRequested
        posted
        influencer {
          id
          name
          pictureUrl
          profiles {
            ... on InstagramProfile {
              id
              username
            }
            __typename
          }
        }
      }
      permissions {
        viewerCanAddContent
      }
    }
  }
`;
