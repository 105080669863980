import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from 'tabler-react';
import C3Chart from 'react-c3js';
import styles from './AudienceGenders.module.css';

const AudienceGenders = ({ genders }) => {
  const { t } = useTranslation();

  if (genders.length === 0) {
    return (
      <p className={`${styles.NoDataLabel} pt-4`}>
        {t('There is no audience gender data available.')}
      </p>
    );
  }

  const [female, male] = genders.sort((curr, next) => {
    if (curr.gender < next.gender) {
      return -1;
    }
    if (curr.gender > next.gender) {
      return 1;
    }

    return 0;
  });

  const data = {
    columns: [
      ['data1', male.value],
      ['data2', female.value],
    ],
    type: 'donut',
    donut: { label: { show: false } },
    colors: {
      data1: '#67c3ff',
      data2: '#f77a75',
    },
    labels: false,
    names: {
      data1: 'Male',
      data2: 'Female',
    },
  };

  return (
    <Grid.Row>
      <Grid.Col lg={5}>
        <C3Chart
          data={data}
          donut={{ label: { show: false } }}
          interaction={{
            enabled: false,
          }}
          labels={{ show: false }}
          legend={{
            show: false,
          }}
          size={{ width: '120', height: '120' }}
          tooltip={{ show: false }}
        />
      </Grid.Col>
      <Grid.Col className="pt-4" lg={7}>
        <div className={`${styles.GenderLabel} ${styles.Female}`}>
          {female.value.toFixed(2)}%
          <br />
          <span>{t('female')}</span>
        </div>
        <div className={`${styles.GenderLabel} ${styles.Male}`}>
          {male.value.toFixed(2)}%
          <br />
          <span>{t('male')}</span>
        </div>
      </Grid.Col>
    </Grid.Row>
  );
};

AudienceGenders.propTypes = {
  genders: PropTypes.arrayOf(
    PropTypes.shape({
      gender: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default AudienceGenders;
