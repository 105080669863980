import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form as TablerForm, Button } from 'tabler-react';
import Loader from '../../../../layouts/components/Loader';
import styles from './Form.module.css';

const Form = ({ onSubmitSuccess }) => {
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [isManager, setIsManager] = useState(false);
  const error = false;
  const loading = false;

  const handleSubmit = event => {
    event.preventDefault();
    onSubmitSuccess();
  };

  return (
    <div className={styles.Form} data-testid="CreateTeamMemberForm">
      {/* have this here so we don't reset the form after submit */}
      {loading && <Loader />}
      <TablerForm onSubmit={handleSubmit}>
        <h2 className="text-center w-100 pb-3">{t('Add Team Member')}</h2>

        <TablerForm.Label className="text-left">
          {t('First Name')}
          <TablerForm.Input
            className="mb-3"
            cross={false}
            onChange={event => setFirstName(event.target.value)}
            value={firstName}
          />
        </TablerForm.Label>

        <TablerForm.Label className="text-left">
          {t('Last Name')}
          <TablerForm.Input
            className="mb-3"
            cross={false}
            onChange={event => setLastName(event.target.value)}
            value={lastName}
          />
        </TablerForm.Label>

        <TablerForm.Label className="text-left">
          {t('Email')}
          <TablerForm.Input
            className="mb-3"
            cross={false}
            onChange={event => setEmail(event.target.value)}
            value={email}
          />
        </TablerForm.Label>

        <TablerForm.Checkbox
          label="Manager"
          onChange={event => setIsManager(event.target.value)}
          value={isManager}
        />

        <TablerForm.Footer className="mt-3 text-center">
          {error && <p className="text-danger">{t(error)}</p>}
          <Button
            block
            className="w-auto mx-auto px-4 py-2"
            color="success"
            disabled={loading}
            type="submit"
          >
            {t('Add')}
          </Button>
        </TablerForm.Footer>
      </TablerForm>
    </div>
  );
};

Form.propTypes = {
  onSubmitSuccess: PropTypes.func.isRequired,
};

export default Form;
