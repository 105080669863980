import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form as TablerForm, Button } from 'tabler-react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import Loader from '../../../../layouts/components/Loader';
import BrandsTypeahead from '../../Typeahead/Brands';
import BRANDS_QUERY from '../../Typeahead/Brands/brandsData.query';
import HandleError from '../../../../layouts/components/ErrorMessage/HandleError';
import CREATE_CAMPAIGN from './createCampaign.mutation';
import styles from './Form.module.css';

const Form = ({ onSubmitSuccess }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [brand, setBrand] = useState('');
  const [name, setCampaignName] = useState('');
  const [state, setState] = useState('UPCOMING');
  const [error, setError] = useState('');

  const [createCampaign, { loading }] = useMutation(CREATE_CAMPAIGN, {
    update: () => {
      onSubmitSuccess();
      // Figure out a better way to redirect here
      // This should work in the test
      // expect(history.location.query.query).toStrictEqual(name);
      history.push(`/campaigns?query=${name}`);
    },
    refetchQueries: [{ query: BRANDS_QUERY }],
  });

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      await createCampaign({
        variables: {
          brand,
          name,
          state,
        },
      });
    } catch (err) {
      setError(err);
    }
  };

  return (
    <div className={styles.Form} data-testid="CreateCampaignForm">
      {/* have this here so we don't reset the form after submit */}
      {loading && <Loader />}
      <TablerForm onSubmit={handleSubmit}>
        <h2 className="text-center w-100 pb-3">{t('Create Campaign')}</h2>

        <TablerForm.Label className="text-left">
          {t('Brand')}
          <BrandsTypeahead
            autoFocus
            formatCreateLabel={newVal => `${t('New Brand')}: ${newVal}`}
            onChange={suggestion => {
              setBrand(suggestion.value);
            }}
            type="creatableSelect"
            value={[brand]}
          />
        </TablerForm.Label>

        <TablerForm.Label className="text-left">
          {t('Campaign name')}
          <TablerForm.Input
            className="mb-3"
            cross={false}
            highlightOnlyResult
            onChange={event => setCampaignName(event.target.value)}
            value={name}
          />
        </TablerForm.Label>

        <TablerForm.Label className="text-left">
          {t('State')}
          <select
            className="form-control custom-select"
            data-testid="createCampaignStateSelect"
            defaultValue={state}
            onBlur={event => setState(event.target.value)}
          >
            <option value="UPCOMING">Upcoming</option>
            <option value="ACTIVE">Active</option>
            <option value="CANCELLED">Cancelled</option>
            <option value="POSTPONED">Postponed</option>
            <option value="COMPLETED">Completed</option>
          </select>
        </TablerForm.Label>

        <TablerForm.Footer className="mt-3 text-center">
          {error && (
            <p className="text-danger">
              <HandleError error={error} />
            </p>
          )}
          <Button
            block
            className={`w-auto mx-auto px-4 py-2 ${loading && 'disabled'}`}
            color="success"
            type="submit"
          >
            {t('Add')}
          </Button>
        </TablerForm.Footer>
      </TablerForm>
    </div>
  );
};

Form.propTypes = {
  onSubmitSuccess: PropTypes.func.isRequired,
};

export default Form;
