import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'tabler-react';
import PlatformFilter from './PlatformFilter';
import ResetFilters from './ResetFilters';
import YouTubeFilters from './YouTubeFilters/YouTubeFilters';
import InstagramFilters from './InstagramFilters/InstagramFilters';

const platformFiltersMap = {
  YOUTUBE: YouTubeFilters,
  INSTAGRAM: InstagramFilters,
};

const InfluencerListFilters = ({ filters, onApplyFilters, onResetFilters }) => {
  const { platform } = filters;
  const FiltersComponent = platformFiltersMap[platform];

  return (
    <div className="mt-3">
      <Grid.Row className="d-flex mx-auto mb-4">
        <Grid.Col className="p-0">
          <PlatformFilter platform={platform} />
        </Grid.Col>
      </Grid.Row>
      <FiltersComponent filters={filters} onApplyFilters={onApplyFilters} />
      <Grid.Row className="mx-auto">
        <Grid.Col>
          <ResetFilters onResetFilters={onResetFilters} platform={platform} />
        </Grid.Col>
      </Grid.Row>
    </div>
  );
};

InfluencerListFilters.propTypes = {
  filters: PropTypes.shape({
    platform: PropTypes.string.isRequired,
  }).isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
};

export default InfluencerListFilters;
