import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Table } from 'tabler-react';
import { CREATIVE_ITERATION_TYPES } from '../../../../../constants';
import styles from './SocialProfilePrices.module.css';

const CONTENT_TYPE_TO_HUMAN = {
  [CREATIVE_ITERATION_TYPES.InstagramFeedImage]: 'Feed Image',
  [CREATIVE_ITERATION_TYPES.InstagramFeedVideo]: 'Feed Video',
  [CREATIVE_ITERATION_TYPES.InstagramFeedCarousel]: 'Feed Carousel',
  [CREATIVE_ITERATION_TYPES.InstagramStoryImage]: 'Story Image',
  [CREATIVE_ITERATION_TYPES.InstagramStoryVideo]: 'Story Video',
  [CREATIVE_ITERATION_TYPES.YouTubeVideo]: 'Video',
};

const SocialProfilePrices = ({ prices }) => {
  const { t } = useTranslation();
  return (
    <Grid.Row className={styles.PricesContainer}>
      <Grid.Col>
        <div data-testid="SocialProfilePrices">
          <Table className={styles.Table}>
            <Table.Header>
              <Table.Row>
                <Table.ColHeader alignContent="left" className={styles.TableHeader}>
                  {t('Type of content')}
                </Table.ColHeader>
                <Table.ColHeader alignContent="center" className={styles.TableHeader}>
                  {t('Price')}
                </Table.ColHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {prices.map(({ contentType, price }) => (
                <Table.Row key={contentType}>
                  <Table.Col alignContent="left" className={styles.TableCell}>
                    {t(CONTENT_TYPE_TO_HUMAN[contentType])}
                  </Table.Col>
                  <Table.Col alignContent="center" className={styles.TableCell}>
                    {price.toLocaleString('en-UK', {
                      style: 'currency',
                      currency: 'GBP',
                    })}
                  </Table.Col>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </Grid.Col>
    </Grid.Row>
  );
};

SocialProfilePrices.propTypes = {
  prices: PropTypes.arrayOf(
    PropTypes.shape({
      contentType: PropTypes.oneOf(Object.keys(CREATIVE_ITERATION_TYPES)),
      price: PropTypes.number,
    })
  ).isRequired,
};
export default SocialProfilePrices;
