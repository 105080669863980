import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import Loader from '../../../layouts/components/Loader';
import ErrorMessage from '../../../layouts/components/ErrorMessage';
import GET_DELIVERABLES from './deliverables.query';

const handleScroll = ({ currentTarget }) => {
  if (currentTarget.scrollTop + currentTarget.clientHeight >= currentTarget.scrollHeight) {
    return true;
  }

  return false;
};

const updateQuery = (previousResult, { fetchMoreResult }) => {
  if (!fetchMoreResult) {
    return previousResult;
  }

  // Merging the previous deliverables object with the one returned from fetchMore
  return {
    ...previousResult,
    deliverables: {
      ...previousResult.deliverables,
      ...fetchMoreResult.deliverables,
      edges: [...previousResult.deliverables.edges, ...fetchMoreResult.deliverables.edges],
    },
  };
};

const firstDeliverables = 30;

const ContentGalleryQueryContainer = ({ children, query, filterBy, creativeIterationState }) => {
  const { loading, error, data, fetchMore } = useQuery(GET_DELIVERABLES, {
    variables: {
      firstDeliverables,
      query,
      filterBy,
      creativeIterationState,
    },
    fetchPolicy: 'network-only',
  });

  if (error) return <ErrorMessage error={error} />;

  if (loading) return <Loader />;

  const {
    deliverables: {
      edges: deliverableNodes,
      pageInfo: { hasNextPage, endCursor: afterDeliverable },
    },
  } = data;

  return (
    <div
      className="scrollableContainer h-100 mx-auto"
      data-testid="ScrollableContainer"
      onScroll={event => {
        if (handleScroll(event) && hasNextPage) {
          fetchMore({
            variables: {
              firstDeliverables,
              afterDeliverable,
              query,
              filterBy,
            },
            updateQuery,
          });
        }
      }}
    >
      {children(deliverableNodes.map(deliverableNode => deliverableNode.node))}
    </div>
  );
};

ContentGalleryQueryContainer.defaultProps = {
  creativeIterationState: null,
  filterBy: {},
  query: '',
};

ContentGalleryQueryContainer.propTypes = {
  children: PropTypes.func.isRequired,
  creativeIterationState: PropTypes.string,
  filterBy: PropTypes.shape(),
  query: PropTypes.string,
};

export default ContentGalleryQueryContainer;
