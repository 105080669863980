import React from 'react';
import Humanize from 'humanize-plus';
import { getName as getCountryName } from 'i18n-iso-countries';

const getProfilePreparator = ({ platform, t }) => {
  const influencerPreparator = (
    {
      id: influencerId,
      country = '',
      gender = '',
      pictureUrl = '',
      name = '',
      profiles: influencerProfiles,
    },
    profile
  ) => {
    const { id: profileId } = profile;
    return {
      avatar: { pictureUrl, name },
      gender: gender && t(Humanize.capitalize(gender.toLocaleLowerCase())),
      country: getCountryName(country, 'en'),
      channels: influencerProfiles,
      details: { influencerId, profileId },
      shortlist: { profile },
    };
  };

  const preparators = {
    INSTAGRAM: profile => {
      const { id, name, username, url, followerCount, engagementRate, influencer } = profile;
      return {
        id,
        profileName: name,
        handle: username ? (
          <a
            className="color"
            href={url}
            rel="noopener noreferrer"
            target="_blank"
            title={`@${username}`}
          >
            {`@${username}`}
          </a>
        ) : (
          ''
        ),
        followerCount,
        engagementRate,
        ...influencerPreparator(influencer, profile),
      };
    },

    YOUTUBE: profile => {
      const { id, title, url, subscriberCount, viewRate, influencer } = profile;
      return {
        id,
        profileName: title ? (
          <a className="color" href={url} rel="noopener noreferrer" target="_blank" title={title}>
            {title}
          </a>
        ) : (
          ''
        ),
        subscriberCount,
        viewRate: viewRate && `${viewRate.toFixed(2)}%`,
        ...influencerPreparator(influencer, profile),
      };
    },
  };

  return preparators[platform];
};

export default getProfilePreparator;
