import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form as TablerForm, Button } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import Loader from '../../../../layouts/components/Loader';
import UPLOAD_CAMPAIGN_REPORT from '../uploadReport.mutation';
import HandleError from '../../../../layouts/components/ErrorMessage/HandleError';
import styles from './Form.module.css';

const Form = ({ campaign, onSubmitSuccess }) => {
  const { t } = useTranslation();
  const { id } = campaign;
  const [selectedFileName, setSelectedFileName] = useState();
  const [fileError, setFileError] = useState();
  const [error, setError] = useState();
  const reportFileRef = useRef();

  const [uploadCampaignReport, { loading }] = useMutation(UPLOAD_CAMPAIGN_REPORT, {
    update: () => {
      onSubmitSuccess();
    },
  });

  const handleFileChange = event => {
    setFileError(t(''));

    const file = reportFileRef.current ? reportFileRef.current.files[0] : null;

    if (file.size > 100000000) {
      setFileError(t('File is too large. Maximum file size is 100mb.'));
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
      return;
    }

    setSelectedFileName(reportFileRef.current.files[0].name);
  };

  const handleSubmit = async event => {
    event.preventDefault();

    try {
      await uploadCampaignReport({
        variables: {
          id,
          reportFile: reportFileRef.current ? reportFileRef.current.files[0] : null,
        },
      });
    } catch (err) {
      setError(err);
    }
  };

  const buttonIsDisabled = loading || !reportFileRef.current || !reportFileRef.current.files[0];

  return (
    <div className={styles.Form} data-testid="UploadReportForm">
      {/* have this here so we don't reset the form after submit */}
      {loading && <Loader />}
      <TablerForm onSubmit={handleSubmit}>
        <div className="row pb-3 no-gutters">
          <div className="col-12">
            <h2 className="mb-0 mt-1 text-center w-100">{t('Upload Report')}</h2>
          </div>
        </div>
        <div className="form-group mt-3">
          <TablerForm.Label>{t('Upload report file')}</TablerForm.Label>
          <div className="input-group">
            <div className="custom-file">
              <label
                className={`${styles.ReportFileLabel} ${
                  fileError ? styles.InvalidLabel : ''
                } custom-file-label`}
                data-testid="ReportFileLabel"
                htmlFor="reportFile"
              >
                {selectedFileName || t('Choose file')}
                <input
                  ref={reportFileRef}
                  accept="application/pdf"
                  aria-describedby="reportFile"
                  className={`${styles.ReportFileInput} custom-file-input form-control`}
                  id="reportFile"
                  onChange={handleFileChange}
                  type="file"
                />
                {fileError && (
                  <span className={`${styles.InvalidFeedback} invalid-feedback`}>{fileError}</span>
                )}
              </label>
            </div>
          </div>
        </div>

        <TablerForm.Footer className="mt-4 text-center">
          {error && (
            <p className="text-danger">
              <HandleError error={error} />
            </p>
          )}
          <Button
            block
            className={`w-auto mx-auto px-4 py-2 mt-3 ${buttonIsDisabled && 'disabled'}`}
            color="success"
            disabled={buttonIsDisabled}
            type="submit"
          >
            {t('Upload')}
          </Button>
        </TablerForm.Footer>
      </TablerForm>
    </div>
  );
};

Form.propTypes = {
  campaign: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
};

export default Form;
