import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import Loader from '../../../layouts/components/Loader';
import ErrorMessage from '../../../layouts/components/ErrorMessage';
import GET_CAMPAIGNS_LIST from './campaigns.query';

const handleScroll = ({ currentTarget }) => {
  if (currentTarget.scrollTop + currentTarget.clientHeight >= currentTarget.scrollHeight) {
    return true;
  }

  return false;
};

const updateQuery = (previousResult, { fetchMoreResult }) => {
  if (!fetchMoreResult) {
    return previousResult;
  }

  // Merging the previous campaigns object with the one returned from fetchMore
  return {
    ...previousResult,
    campaigns: {
      ...previousResult.campaigns,
      ...fetchMoreResult.campaigns,
      edges: [...previousResult.campaigns.edges, ...fetchMoreResult.campaigns.edges],
    },
  };
};

const firstCampaigns = 30;

const CampaignsListQueryContainer = ({ children, query, states }) => {
  const filterBy = states.length === 0 ? {} : { states: states.map(state => state.toUpperCase()) };

  const { loading, error, data, fetchMore } = useQuery(GET_CAMPAIGNS_LIST, {
    variables: {
      firstCampaigns,
      query,
      filterBy,
    },
    fetchPolicy: 'network-only',
  });

  if (error) return <ErrorMessage error={error} />;

  if (loading) return <Loader />;

  const {
    campaigns: {
      edges: campaignNodes,
      pageInfo: { hasNextPage, endCursor: afterCampaign },
    },
  } = data;

  return (
    <div
      className="scrollableContainer h-100"
      data-testid="ScrollableContainer"
      onScroll={event => {
        if (handleScroll(event) && hasNextPage) {
          fetchMore({
            variables: {
              firstCampaigns,
              afterCampaign,
              query,
              filterBy,
            },
            updateQuery,
          });
        }
      }}
    >
      {children(campaignNodes.map(campaingNode => campaingNode.node))}
    </div>
  );
};

CampaignsListQueryContainer.defaultProps = {
  query: '',
  states: [],
};

CampaignsListQueryContainer.propTypes = {
  children: PropTypes.func.isRequired,
  query: PropTypes.string,
  states: PropTypes.arrayOf(PropTypes.string),
};

export default CampaignsListQueryContainer;
