import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from 'tabler-react';
import ShortlistedSocialProfileStats from '../../../../../campaigns/components/ShortlistedSocialProfileCard/ShortlistedSocialProfileStats';
import SocialProfileDemographics from '../../../../../campaigns/components/ShortlistedProfileDetailsDrawer/Details/SocialProfileDemographics';
import Accordion from '../../../../../layouts/components/Accordion';
import SocialProfilePrices from '../SocialProfilePrices';
import '../SocialProfileAccordionItem.css';

const YouTubeDetails = ({ profile, isSelected }) => {
  const { t } = useTranslation();
  const { url, title, previewUrls } = profile;
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { averagePrices } = profile;

  return (
    <Accordion
      className={{ 'card-collapsed': isCollapsed, collapsibleCard: true }}
      handle={title}
      handleCollapse={() => setIsCollapsed(!isCollapsed)}
      header={t('YouTube Details')}
      iconName="youtube-play"
      iconPrefix="fa"
      isCollapsed={isCollapsed}
      isSelected={isSelected}
      url={url}
    >
      <Grid.Row>
        <Grid.Col className="d-flex justify-content-center text-center">
          <ShortlistedSocialProfileStats profile={profile} size="large" />
        </Grid.Col>
      </Grid.Row>
      {averagePrices.length > 0 && (
        <>
          <hr className="mb-0" />
          <Grid.Row>
            <Grid.Col className="d-flex justify-content-center text-center flex-column">
              <SocialProfilePrices prices={averagePrices} />
            </Grid.Col>
          </Grid.Row>
        </>
      )}
      <hr />
      {!isCollapsed && <SocialProfileDemographics profile={profile} />}
      <hr />
      <Grid.Row>
        <Grid.Col>
          {previewUrls.map(previewUrl => (
            <div
              key={previewUrl}
              className="detailsImage"
              style={{ backgroundImage: `url("${previewUrl}")` }}
            />
          ))}
        </Grid.Col>
      </Grid.Row>
    </Accordion>
  );
};

YouTubeDetails.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  profile: PropTypes.shape({
    averagePrices: PropTypes.arrayOf(PropTypes.shape({})),
    previewUrls: PropTypes.array,
    title: PropTypes.string,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default YouTubeDetails;
