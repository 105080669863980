import React from 'react';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Card, Grid } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import styles from './InfluencerInfo.module.css';

const InfluencerInfo = ({ influencer }) => {
  const { id, name, pictureUrl } = influencer;
  const { t } = useTranslation();
  return (
    <div className={styles.InfluencerInfo}>
      <Card className={`${styles.ProfileCard} px-3 pt-2`}>
        <Grid.Row>
          <Grid.Col className="d-flex justify-content-center">
            <div
              className={`${styles.ProfileImage}`}
              style={{
                backgroundImage: `url("${pictureUrl}")`,
              }}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row className="pt-5">
          <Grid.Col className="d-flex justify-content-center">
            <h2 className={`${styles.Name} mb-0 text-center`}>{name}</h2>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row className="pt-5">
          <Grid.Col className="d-flex justify-content-center">
            <ul className={`${styles.NavList} list-unstyled text-center`}>
              <li>
                <NavLink activeClassName={styles.ActiveLink} to={`/influencers/${id}/campaigns`}>
                  {t('Influencer Campaigns')}
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName={styles.ActiveLink} to={`/influencers/${id}/notes`}>
                  {t('Influencer Notes')}
                </NavLink>
              </li>
            </ul>
          </Grid.Col>
        </Grid.Row>
      </Card>
    </div>
  );
};

InfluencerInfo.fragments = {
  influencerInfo: gql`
    fragment InfluencerInfo on Influencer {
      name
      pictureUrl
    }
  `,
};

InfluencerInfo.propTypes = {
  influencer: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    pictureUrl: PropTypes.string,
  }).isRequired,
};
export default InfluencerInfo;
