import gql from 'graphql-tag';
import CampaignCardFragment from '../CampaignCard/campaignCard.fragment';

export default gql`
  mutation($id: ID!, $reportFile: Upload!) {
    uploadCampaignReport(input: { id: $id, reportFile: $reportFile }) {
      campaign {
        ...CampaignCard
      }
    }
  }
  ${CampaignCardFragment}
`;
