import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import Loader from '../../../layouts/components/Loader';
import ErrorMessage from '../../../layouts/components/ErrorMessage';
import GET_CAMPAIGN_CONTENT_SUMMARY from './contentSummary.query';

const ContentSummaryListQueryContainer = ({ shortcode, children }) => {
  const { loading, error, data } = useQuery(GET_CAMPAIGN_CONTENT_SUMMARY, {
    variables: {
      shortcode,
    },
    fetchPolicy: 'network-only',
  });

  if (error) return <ErrorMessage error={error} />;

  if (loading) return <Loader />;

  const { campaign } = data;

  return <div className="scrollableContainer h-100">{children(campaign)}</div>;
};

ContentSummaryListQueryContainer.propTypes = {
  children: PropTypes.func.isRequired,
  shortcode: PropTypes.string.isRequired,
};

export default ContentSummaryListQueryContainer;
