import React from 'react';
import { useQueryParam, StringParam, ArrayParam } from 'use-query-params';
import { Container, Grid } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import Authorized from '../../../layouts/Authorized';
import PageHeader from '../../../layouts/components/PageHeader';
import PageActions from '../../../layouts/components/PageActions';
import PageTitle from '../../../layouts/components/PageTitle';
import CampaignsSummaryQueryContainer from '../../components/CampaignsSummaryQueryContainer';
import CampaignsListQueryContainer from '../../components/CampaignsListQueryContainer';
import CampaignsList from '../../components/CampaignsList';
import CampaignsListFilters from '../../components/CampaignsListFilters';
import CreateCampaign from '../../components/CreateCampaign';
import CampaignsSummary from '../../components/CampaignsSummary';
import viewerUtils from '../../../utils/viewer';

const allowedCampaignStates = ['active', 'upcoming', 'completed'];

const CampaignsListPage = () => {
  const { t } = useTranslation();
  const [query, setQuery] = useQueryParam('query', StringParam);
  const [states, setStates] = useQueryParam('states', ArrayParam);

  const handleApplyFilterByState = newStates => setStates(newStates, 'pushIn');

  const handleApplyQuery = newSearch => setQuery(newSearch, 'pushIn');

  // This check here to prevent gql exception for invalid state
  if (states) {
    states.forEach(state => {
      if (!allowedCampaignStates.includes(state)) {
        setStates([]);
      }
    });
  }

  return (
    <Authorized>
      <CampaignsListQueryContainer query={query} states={states}>
        {campaigns => {
          return (
            <Container>
              <PageHeader>
                <PageTitle title={t('Campaigns')} />
                <PageActions>{viewerUtils.canCreateCampaigns() && <CreateCampaign />}</PageActions>
              </PageHeader>
              <CampaignsSummaryQueryContainer>
                {campaignsSummary => (
                  <Grid.Row>
                    <Grid.Col>
                      <CampaignsSummary campaignsSummary={campaignsSummary} />
                    </Grid.Col>
                  </Grid.Row>
                )}
              </CampaignsSummaryQueryContainer>
              <Grid.Row className="pb-3">
                <Grid.Col>
                  <CampaignsListFilters
                    onApplyFilterByState={handleApplyFilterByState}
                    onApplyQuery={handleApplyQuery}
                    query={query}
                    states={states}
                  />
                </Grid.Col>
              </Grid.Row>
              <CampaignsList campaigns={campaigns} />
            </Container>
          );
        }}
      </CampaignsListQueryContainer>
    </Authorized>
  );
};

export default CampaignsListPage;
