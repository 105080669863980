import gql from 'graphql-tag';
import ConversationEntry from '../../../common/components/Conversation/ConversationEntry';

export default gql`
  query CreativeIterationQuery($id: ID!, $firstComments: Int) {
    creativeIteration(id: $id) {
      id
      comments(first: $firstComments) {
        ... on CommentConnection {
          edges {
            node {
              ...ConversationEntry
            }
          }
        }
      }
    }
  }
  ${ConversationEntry.fragments.conversationEntry}
`;
