import React from 'react';
import { Grid } from 'tabler-react';
import PropTypes from 'prop-types';
import QueryFilter from '../QueryFilter';
import BrandsFilter from '../BrandsFilter';
import CountriesFilter from '../CountriesFilter';
import PriceFilter from '../PriceFilter';
import AgencyFilter from '../AgencyFilter';
import SubscriberCountFilter from '../SubscriberCountFilter';

const YouTubeFilters = ({ filters, onApplyFilters }) => {
  const { query, agency, brands, countries, subscriberCount, priceRange } = filters;

  return (
    <>
      <Grid.Row className="mx-auto mb-3">
        <Grid.Col>
          <QueryFilter onApplyFilters={onApplyFilters} query={query} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className="mx-auto">
        <Grid.Col>
          <BrandsFilter brandsFilters={brands} onApplyFilters={onApplyFilters} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className="mx-auto">
        <Grid.Col>
          <CountriesFilter countriesFilters={countries} onApplyFilters={onApplyFilters} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className="mx-auto">
        <Grid.Col>
          <SubscriberCountFilter
            onApplyFilters={onApplyFilters}
            subscriberCount={subscriberCount}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className="mx-auto">
        <Grid.Col>
          <PriceFilter
            contentType="YouTubeVideo"
            onApplyFilters={onApplyFilters}
            priceRange={priceRange}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className="mx-auto">
        <Grid.Col>
          <AgencyFilter agency={agency} onApplyFilters={onApplyFilters} />
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

YouTubeFilters.defaultProps = {
  filters: {
    agency: false,
    brands: [],
    country: [],
    priceRange: [],
    query: '',
  },
};

YouTubeFilters.propTypes = {
  filters: PropTypes.shape({
    agency: PropTypes.bool,
    brands: PropTypes.arrayOf(PropTypes.string),
    countries: PropTypes.arrayOf(PropTypes.string),
    priceRange: PropTypes.arrayOf(PropTypes.string),
    query: PropTypes.string,
    subscriberCount: PropTypes.arrayOf(PropTypes.string),
  }),
  onApplyFilters: PropTypes.func.isRequired,
};

export default YouTubeFilters;
