import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './Rationale.module.css';

const Rationale = ({ rationale }) => {
  const { t } = useTranslation();

  return (
    <div className="px-3 py-2">
      <div className={styles.RationaleHeader}>{t('Info')}</div>
      <div className={styles.RationaleContent}>{rationale}</div>
    </div>
  );
};

Rationale.propTypes = {
  rationale: PropTypes.string.isRequired,
};

export default Rationale;
