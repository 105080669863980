import React from 'react';
import { Icon } from 'tabler-react';
import { useHistory } from 'react-router-dom';
import logout from '../../../utils/logout';
import styles from './LogoutButton.module.css';

const LogoutButton = () => {
  const history = useHistory();

  const handleLogout = () => {
    logout();
    history.push('/login');
  };

  return <Icon className={`${styles.Icon} fa-2x`} name="lock" onClick={handleLogout} prefix="fa" />;
};

export default LogoutButton;
