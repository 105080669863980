import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InfluencerSocialNetworksWidget from '../../../InfluencerSocialNetworksWidget';

const ChannelsFormatter = ({ value }) => {
  const { t } = useTranslation();
  return (
    <div className="text-center" title={t('All Channels')}>
      <InfluencerSocialNetworksWidget className="colored" profiles={value} />
    </div>
  );
};

ChannelsFormatter.defaultProps = {
  value: [],
};

ChannelsFormatter.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ChannelsFormatter;
