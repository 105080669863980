import PropTypes from 'prop-types';
import Humanize from 'humanize-plus';

const HumanizeNumbersFormatter = ({ value }) => {
  return value && Humanize.compactInteger(value, 1);
};

HumanizeNumbersFormatter.propTypes = {
  value: PropTypes.number,
};

export default HumanizeNumbersFormatter;
