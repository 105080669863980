import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'tabler-react';

const getDetailsDrawerFormatter = (onClick, t) => {
  const DetailsDrawerFormatter = ({ value: { influencerId, profileId } }) => {
    return (
      <div title={t('Details')}>
        <Icon name="zoom-in" onClick={() => onClick(influencerId, profileId)} />
      </div>
    );
  };

  DetailsDrawerFormatter.defaultProps = {
    value: {},
  };

  DetailsDrawerFormatter.propTypes = {
    value: PropTypes.shape({
      influencerId: PropTypes.string.isRequired,
      profileId: PropTypes.string.isRequired,
    }),
  };

  return DetailsDrawerFormatter;
};

export default getDetailsDrawerFormatter;
