import React from 'react';
import TenantLogo from '../TenantLogo';
import DrawerMenu from '../DrawerMenu';
import styles from './Header.module.css';

const Header = () => (
  <div className={styles.Header}>
    <DrawerMenu className={styles.Menu} />
    <TenantLogo />
  </div>
);

export default Header;
