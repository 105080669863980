import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import RangeSlider from '../../RangeSlider';
import PriceContentTypesSelect from '../../../../campaigns/components/Typeahead/PriceContentTypes';

const MIN = '10';
const MAX = '20000';
const STEP = 10;
const CONTENT_TYPE = '';

const castValues = (range, onApplyFilters) => {
  return range.map(rate => {
    if (isNaN(Number(rate))) {
      onApplyFilters({ range: [] });
      return null;
    }
    return Number(rate);
  });
};

const PriceFilter = ({ contentType, priceRange, onApplyFilters }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState(castValues(priceRange, onApplyFilters));

  let selectedType = contentType;

  const handleTypeChange = newType => {
    selectedType = newType && newType.value;
    onApplyFilters({ contentType: selectedType });
  };

  useEffect(() => {
    setValues(castValues(priceRange, onApplyFilters));
  }, [priceRange, onApplyFilters]);

  return (
    <div data-testid="PriceFilter">
      <Form.Group>
        <Form.Label>
          {t('Price')}
          {contentType !== 'YouTubeVideo' && (
            <div data-testid="PriceFilterType">
              <PriceContentTypesSelect
                onChange={handleTypeChange}
                placeholder={t('Select price type...')}
                value={selectedType}
              />
            </div>
          )}
        </Form.Label>
      </Form.Group>
      {contentType && (
        <Form.Group>
          <Form.Label>
            <div data-testid="PriceFilterRange">
              <RangeSlider
                max={Number(MAX)}
                min={Number(MIN)}
                onApplyFilters={finalValues => {
                  onApplyFilters({ contentType: selectedType, priceRange: finalValues });
                }}
                setValues={setValues}
                step={STEP}
                values={values}
              >
                {arg =>
                  arg.toLocaleString('en-UK', {
                    style: 'currency',
                    currency: 'GBP',
                  })
                }
              </RangeSlider>
            </div>
          </Form.Label>
        </Form.Group>
      )}
    </div>
  );
};

PriceFilter.defaultProps = {
  contentType: CONTENT_TYPE,
  priceRange: [MIN, MAX],
};

PriceFilter.propTypes = {
  contentType: PropTypes.string,
  onApplyFilters: PropTypes.func.isRequired,
  priceRange: PropTypes.arrayOf(PropTypes.string),
};

export default PriceFilter;
