import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import C3Chart from 'react-c3js';
import styles from './AudienceAges.module.css';
import './chart.css';

const AudienceAges = ({ ages }) => {
  const { t } = useTranslation();

  if (ages.length === 0) {
    return (
      <p className={`${styles.NoDataLabel} pt-4`}>
        {t('There is no audience ages data available.')}
      </p>
    );
  }

  const sortedAges = ages.sort((current, next) => {
    if (current.minAge > next.minAge) {
      return 1;
    }
    if (current.minAge < next.minAge) {
      return -1;
    }
    return 0;
  });

  const categories = sortedAges.map(({ maxAge, minAge }) =>
    maxAge ? `${minAge}-${maxAge}` : `${minAge}+`
  );

  const series = sortedAges.map(({ value }) => value);
  const data = {
    columns: [['data1', ...series]],
    type: 'bar',
    labels: {
      format: value => `${parseInt(value, 10)}%`,
    },
    colors: {
      data1: '#2cb19d',
    },
    names: {
      data1: 'Maximum',
    },
  };

  return (
    <div className={`${styles.Chart} gendersChart`}>
      <C3Chart
        axis={{
          x: {
            show: true,
            type: 'category',
            categories,
          },
          y: {
            show: false,
          },
        }}
        bar={{ label: { show: true } }}
        data={data}
        interaction={{
          enabled: false,
        }}
        legend={{
          show: false,
        }}
        padding={{
          bottom: 0,
          top: 0,
        }}
        size={{ height: 120, width: 260 }}
      />
    </div>
  );
};

AudienceAges.propTypes = {
  ages: PropTypes.arrayOf(
    PropTypes.shape({
      maxAge: PropTypes.number,
      minAge: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default AudienceAges;
