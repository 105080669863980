import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import RangeSlider from '../../RangeSlider';
import styles from './FollowersCountFilter.module.css';

const MIN = '10000';
const MAX = '1000000';
const STEP = 1000;

const castValues = (followerCount, onApplyFilters) => {
  return followerCount.map(rate => {
    if (isNaN(Number(rate))) {
      onApplyFilters({ followerCount: [] });
      return null;
    }

    return Number(rate);
  });
};

const FollowersCountFilter = ({ followerCount, onApplyFilters }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState(castValues(followerCount, onApplyFilters));
  useEffect(() => {
    setValues(castValues(followerCount, onApplyFilters));
  }, [followerCount, onApplyFilters]);

  return (
    <Form.Group>
      <Form.Label>{t('Follower Count')}</Form.Label>
      <div data-testid="FollowersCountFilter">
        <RangeSlider
          className={styles.ThumbTooltip}
          max={Number(MAX)}
          min={Number(MIN)}
          onApplyFilters={finalValues => onApplyFilters({ followerCount: finalValues })}
          setValues={setValues}
          step={STEP}
          values={values}
        >
          {arg => arg.toLocaleString()}
        </RangeSlider>
      </div>
    </Form.Group>
  );
};

FollowersCountFilter.defaultProps = {
  followerCount: [MIN],
};

FollowersCountFilter.propTypes = {
  followerCount: PropTypes.arrayOf(PropTypes.string),
  onApplyFilters: PropTypes.func.isRequired,
};

export default FollowersCountFilter;
