import gql from 'graphql-tag';

export default gql`
  mutation(
    $id: ID!
    $followerCount: Int
    $subscriberCount: Int
    $engagementRate: Float
    $viewRate: Float
  ) {
    updateSocialProfile(
      input: {
        id: $id
        followerCount: $followerCount
        subscriberCount: $subscriberCount
        engagementRate: $engagementRate
        viewRate: $viewRate
      }
    ) {
      socialProfile {
        __typename
        ... on InstagramProfile {
          id
          followerCount
          engagementRate
        }
        ... on YouTubeChannel {
          id
          subscriberCount
          engagementRate
          viewRate
        }
      }
    }
  }
`;
