import React from 'react';
import PropTypes from 'prop-types';
import countries from 'i18n-iso-countries';
import Typeahead from './Typeahead';

const Countries = ({ onChange, isMulti, placeholder, value }) => {
  const names = Object.entries(countries.getNames('en')).map(([code, name]) => {
    return {
      label: Array.isArray(name) ? name[0] : name,
      value: code,
    };
  });

  const defaultValue = value.map(code => ({
    value: code,
    label: countries.getName(code, 'en'),
  }));

  return (
    <div className="mb-3">
      {names && (
        <Typeahead
          isMulti={isMulti}
          onChange={onChange}
          options={names}
          placeholder={placeholder}
          type="select"
          value={defaultValue}
        />
      )}
    </div>
  );
};

Countries.defaultProps = {
  value: [],
};

Countries.propTypes = {
  isMulti: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
};

export default Countries;
