import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { Form, Button } from 'tabler-react';
import { SHORTLISTED_SOCIAL_PROFILE_STATES } from '../../../../../constants';
import UPDATE_SHORTLISTED_PROFILE from '../updateShortlistedProfile.mutation';
import Loader from '../../../../../layouts/components/Loader';
import HandleError from '../../../../../layouts/components/ErrorMessage/HandleError';
import styles from './RejectSocialProfileForm.module.css';
import RejectSocialProfileSelect from './RejectSocialProfileSelect';

const RejectSocialProfileForm = ({ profile, className, onSubmitSuccess }) => {
  const { t } = useTranslation();
  const {
    id,
    node: { username, title },
  } = profile;

  const [error, setError] = useState('');
  const [reasonText, setReasonText] = useState('');
  const [reason, setReason] = useState('');
  const [reasonError, setReasonError] = useState('');

  const [rejectShortlistedProfile, { loading }] = useMutation(UPDATE_SHORTLISTED_PROFILE, {
    update: onSubmitSuccess,
  });

  const handleSubmit = async event => {
    event.preventDefault();
    const rejectionReason = reason === 'other' ? reasonText : reason;
    if (!rejectionReason) {
      setReasonError(t('Please enter rejection reason.'));
      return;
    }

    try {
      await rejectShortlistedProfile({
        variables: {
          id,
          state: SHORTLISTED_SOCIAL_PROFILE_STATES.REJECTED,
          rejectionReason,
        },
      });
    } catch (err) {
      setError(err);
    }
  };

  return (
    <div data-testid="RejectSocialProfileForm">
      {/* have this here so we don't reset the form after submit */}
      {loading && <Loader />}
      <Form className={className} onSubmit={handleSubmit}>
        <div className="clearfix pb-3">
          <h2 className="text-center mb-0 mt-1 float-left">
            {t('Reject ')}
            {username && <span className={styles.ProfileUsername}>{username}</span>}
            {title && title}
          </h2>
        </div>
        <RejectSocialProfileSelect
          onChange={newReason => setReason(newReason.value)}
          placeholder={t('Select a reason...')}
          value={reason}
        />
        {reason === 'other' && (
          <Form.Textarea
            className="mt-3 TestRejectionReasonTextArea"
            cross={false}
            feedback={reasonError}
            invalid={Boolean(reasonError)}
            name="reasonText"
            onChange={event => {
              setReasonError(false);
              setReasonText(event.target.value);
            }}
            rows={6}
          />
        )}
        <Form.Footer className="mt-3 text-center">
          {error && (
            <p className="text-danger">
              <HandleError error={error} />
            </p>
          )}
          <Button
            block
            className="w-auto mx-auto px-4 py-2"
            color="danger"
            disabled={loading || !reason}
            type="submit"
          >
            {t('Reject Profile')}
          </Button>
        </Form.Footer>
      </Form>
    </div>
  );
};

RejectSocialProfileForm.defaultProps = {
  className: '',
};

RejectSocialProfileForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func.isRequired,
  profile: PropTypes.shape().isRequired,
};

export default RejectSocialProfileForm;
