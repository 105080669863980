import gql from 'graphql-tag';
import InfluencerDataFragment from '../InfluencersList/InfluencerData.fragment';

export default gql`
  query(
    $filterBy: SocialProfileFilters
    $query: String
    $firstProfiles: Int
    $afterProfile: String
  ) {
    socialProfiles(
      filterBy: $filterBy
      query: $query
      first: $firstProfiles
      after: $afterProfile
    ) {
      edges {
        node {
          ... on InstagramProfile {
            id
            name
            username
            followerCount
            engagementRate
            previewUrls
            url
            influencer {
              ...InfluencerData
            }
          }
          ... on YouTubeChannel {
            id
            title
            subscriberCount
            engagementRate
            viewRate
            previewUrls
            url
            influencer {
              ...InfluencerData
            }
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${InfluencerDataFragment}
`;
