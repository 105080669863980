import gql from 'graphql-tag';

export const InstagramProfile = gql`
  fragment InstagramProfile on InstagramProfile {
    id
    pictureUrl
    username
    name
    engagementRate
    followerCount
    previewUrls
    biography
    audience {
      updatedAt
      genders {
        gender
        value
      }
      ages {
        maxAge
        minAge
        value
      }
      cities {
        city
        value
      }
      countries {
        country
        value
      }
    }
    influencer {
      id
      country
      gender
      name
      profiles {
        ... on InstagramProfile {
          url
          id
        }
        ... on YouTubeChannel {
          url
          id
        }
        __typename
      }
    }
  }
`;

export const YouTubeProfile = gql`
  fragment YouTubeProfile on YouTubeChannel {
    id
    title
    pictureUrl
    engagementRate
    subscriberCount
    viewRate
    previewUrls
    description
    audience {
      updatedAt
      genders {
        gender
        value
      }
      ages {
        maxAge
        minAge
        value
      }
      cities {
        city
        value
      }
      countries {
        country
        value
      }
    }
    influencer {
      id
      country
      gender
      name
      profiles {
        ... on InstagramProfile {
          url
          id
        }
        ... on YouTubeChannel {
          url
          id
        }
        __typename
      }
    }
  }
`;
