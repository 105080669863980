import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from 'tabler-react';
import Accordion from '../../../../layouts/components/Accordion';
import PostHistoryCard from './PostHistoryCard';

const PostHistoryAccordionItem = ({ influencer }) => {
  const { t } = useTranslation();
  const { profiles } = influencer;
  const [isCollapsed, setIsCollapsed] = useState(true);

  const deliverablesToShow = profiles
    .map(profile =>
      profile.deliverables.filter(deliverable => deliverable.creativeIterations[0].media.length > 0)
    )
    .reduce(
      (allProfileDeliverables, profileDeliverables) =>
        allProfileDeliverables.concat(profileDeliverables),
      []
    );

  return (
    <Accordion
      className={{ 'card-collapsed': isCollapsed, collapsibleCard: true }}
      handleCollapse={() => setIsCollapsed(!isCollapsed)}
      header={t('Post History')}
      iconName="rotate-ccw"
      isCollapsed={isCollapsed}
    >
      {deliverablesToShow.length > 0 ? (
        deliverablesToShow.map(deliverable => {
          const { id, socialProfile } = deliverable;
          return (
            <PostHistoryCard
              key={id}
              deliverable={deliverable}
              typename={socialProfile.__typename}
            />
          );
        })
      ) : (
        <Grid.Row>
          <Grid.Col>
            <div className="text-center text-muted profileNoContentLabel">
              {t('No posts found')}
            </div>
          </Grid.Col>
        </Grid.Row>
      )}
    </Accordion>
  );
};

PostHistoryAccordionItem.propTypes = {
  influencer: PropTypes.shape({
    profiles: PropTypes.arrayOf(
      PropTypes.shape({
        deliverables: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            socialProfile: PropTypes.shape({
              __typename: PropTypes.oneOf(['InstagramProfile', 'YouTubeChannel']).isRequired,
            }).isRequired,
          })
        ).isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default PostHistoryAccordionItem;
