import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'tabler-react';
import Drawer from '../../../layouts/components/Drawer';
import Form from './Form';

const AddInfluencerContent = ({ campaign, isForInfluencer, influencerId }) => {
  const { t } = useTranslation();
  const [addInfluencerContentFormIsOpen, setAddInfluencerContentFormIsOpen] = useState(false);
  const buttonText = isForInfluencer ? t('Add Influencer Content') : t('Add Content');

  return (
    <>
      <Drawer
        handleClose={() => {
          setAddInfluencerContentFormIsOpen(false);
        }}
        isOpen={addInfluencerContentFormIsOpen}
      >
        {addInfluencerContentFormIsOpen && (
          <Form
            campaign={campaign}
            influencerId={influencerId}
            isForInfluencer={isForInfluencer}
            onSubmitSuccess={() => {
              setAddInfluencerContentFormIsOpen(false);
            }}
          />
        )}
      </Drawer>
      <Button
        block
        className="w-auto float-right py-2"
        color="success"
        onClick={() => {
          setAddInfluencerContentFormIsOpen(true);
        }}
      >
        {buttonText}
      </Button>
    </>
  );
};

AddInfluencerContent.defaultProps = {
  influencerId: null,
  isForInfluencer: false,
};

AddInfluencerContent.propTypes = {
  campaign: PropTypes.shape().isRequired,
  influencerId: PropTypes.string,
  isForInfluencer: PropTypes.bool,
};

export default AddInfluencerContent;
