import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { Button } from 'tabler-react';
import StateLabel from '../StateLabel';
import { SHORTLISTED_SOCIAL_PROFILE_STATES } from '../../../../constants';
import Drawer from '../../../../layouts/components/Drawer';
import Loader from '../../../../layouts/components/Loader';
import HandleError from '../../../../layouts/components/ErrorMessage/HandleError';
import RejectSocialProfileForm from './RejectSocialProfileForm';
import UPDATE_SHORTLISTED_PROFILE from './updateShortlistedProfile.mutation';
import styles from './ReviewerActions.module.css';

const ReviewerActions = ({ profile }) => {
  const { t } = useTranslation();
  const { state, id } = profile;
  const [error, setError] = useState();
  const [rejectionFormIsOpen, setRejectionFormIsOpen] = useState(false);

  const [approveShortlistedProfile, { loading }] = useMutation(UPDATE_SHORTLISTED_PROFILE);

  const handleApproveShortlistedProfile = async event => {
    event.preventDefault();

    try {
      await approveShortlistedProfile({
        variables: {
          id,
          state: SHORTLISTED_SOCIAL_PROFILE_STATES.APPROVED,
        },
      });
    } catch (err) {
      setError(err);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (state === SHORTLISTED_SOCIAL_PROFILE_STATES.PENDING) {
    return (
      <>
        <Drawer handleClose={() => setRejectionFormIsOpen(false)} isOpen={rejectionFormIsOpen}>
          <RejectSocialProfileForm
            className={styles.Form}
            onSubmitSuccess={() => {
              setRejectionFormIsOpen(false);
            }}
            profile={profile}
          />
        </Drawer>
        <div className={styles.ActionContainer}>
          {error && (
            <p className="text-danger">
              <HandleError error={error} />
            </p>
          )}
          <Button
            className={`w-50 text-center ${styles.ActionApprove}`}
            color="success"
            disabled={loading}
            onClick={handleApproveShortlistedProfile}
          >
            {t('Approve')}
          </Button>
          <Button
            className={`w-50 text-center ${styles.ActionReject}`}
            color="danger"
            disabled={loading}
            onClick={() => setRejectionFormIsOpen(true)}
          >
            {t('Reject')}
          </Button>
        </div>
      </>
    );
  }

  return <StateLabel className="mx-0" state={state} />;
};

ReviewerActions.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.string.isRequired,
    state: PropTypes.oneOf(Object.values(SHORTLISTED_SOCIAL_PROFILE_STATES)).isRequired,
  }).isRequired,
};

export default ReviewerActions;
