import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { Form as TablerForm, Button } from 'tabler-react';
import Loader from '../../../../../../layouts/components/Loader';
import HandleError from '../../../../../../layouts/components/ErrorMessage/HandleError';
import DOWNLOAD_YOUTUBE_VIDEO from './downloadYouTubeVideo.mutation';
import styles from './Form.module.css';

const Form = ({ creativeIteration, onSubmitSuccess }) => {
  const { t } = useTranslation();
  const [url, setUrl] = useState('');
  const [error, setError] = useState();
  const [urlError, setUrlError] = useState();
  const [priceError, setPriceError] = useState();
  const defaultPrice = creativeIteration.price || '';
  const [price, setPrice] = useState(defaultPrice);
  const required = creativeIteration.media.length === 0;

  const [downloadYouTubeVideo, { loading }] = useMutation(DOWNLOAD_YOUTUBE_VIDEO, {
    update: () => onSubmitSuccess(),
  });

  const YOU_TUBE_REGEX = /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)&?/;

  const handleSubmit = async event => {
    event.preventDefault();

    if (!url.match(YOU_TUBE_REGEX)) {
      setUrlError(t('Please enter YouTube video URL.'));
      return;
    }

    if (!price) {
      setPriceError(t('Please enter price.'));
      return;
    }

    try {
      await downloadYouTubeVideo({
        variables: {
          creativeIterationId: creativeIteration.id,
          videoUrl: url,
          price,
        },
      });
    } catch (err) {
      setError(err);
    }
  };

  return (
    <div className={styles.Form} data-testid="UpdateYTCreativeForm">
      {loading && <Loader />}
      <TablerForm onSubmit={handleSubmit}>
        <h2 className="text-center w-100 pb-3">{t('Upload Content')}</h2>
        <TablerForm.Label>
          {t('YouTube Video URL')}
          <TablerForm.Input
            autoFocus={required}
            feedback={urlError}
            invalid={Boolean(urlError)}
            name="url"
            onChange={event => {
              setUrlError(false);
              setUrl(event.target.value);
            }}
            value={url}
          />
        </TablerForm.Label>
        <TablerForm.Label>
          {t('Price')}
          <TablerForm.Input
            className={styles.Price}
            feedback={priceError}
            invalid={Boolean(priceError)}
            min="0"
            onChange={({ target: { value } }) => {
              setPriceError(false);
              setPrice(parseFloat(value));
            }}
            step="0.01"
            type="number"
            value={price}
          />
        </TablerForm.Label>
        {error && (
          // temporary solution until adding toaster messages
          <h6 className="text-danger text-center mt-4">
            <HandleError error={error} />
          </h6>
        )}
        <TablerForm.Footer className="mt-3 text-center">
          <Button block className="w-auto mx-auto px-4 py-2" color="success" type="submit">
            {t('Save')}
          </Button>
        </TablerForm.Footer>
      </TablerForm>
    </div>
  );
};

Form.propTypes = {
  creativeIteration: PropTypes.shape({
    caption: PropTypes.string,
    id: PropTypes.string.isRequired,
    media: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
      })
    ),
    price: PropTypes.number,
  }).isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
};

export default Form;
