import gql from 'graphql-tag';

export default gql`
  fragment CampaignCard on Campaign {
    brand {
      name
    }
    hasContent
    hasReport
    hasShortlist
    id
    name
    reportUrl
    shortcode
    state
    permissions {
      viewerCanUploadReport
      viewerCanCreateShortlist
      viewerCanAddContent
      viewerCanEditCampaign
    }
  }
`;
