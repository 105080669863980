import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import Sidebar from '../../../layouts/components/Sidebar';
import InfluencerListFilters from '../InfluencerListFilters';
import FoldedSidebar from './FoldedSidebar';
import styles from './InfluencerListSidebar.module.css';

const InfluencerListSidebar = ({
  isSidebarOpen,
  filters,
  onApplyFilters,
  onClickButton,
  onOpenSidebar,
  onResetFilters,
  onSetNetworkIcon,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`h-100  ${styles.SidebarContainer} ${isSidebarOpen ? styles.Open : styles.Closed}`}
    >
      <Sidebar colSizeLg={3}>
        <Button
          className={`sidebarCloseButton ${styles.CloseButton}`}
          onClick={onClickButton}
          type="button"
        >
          <i
            className={`fe ${isSidebarOpen ? 'fe-chevron-left' : 'fe-chevron-right'}`}
            title={t('Close')}
          />
        </Button>
        {isSidebarOpen ? (
          <InfluencerListFilters
            filters={filters}
            onApplyFilters={onApplyFilters}
            onResetFilters={onResetFilters}
          />
        ) : (
          <FoldedSidebar
            onOpenSidebar={onOpenSidebar}
            onSetNetworkIcon={onSetNetworkIcon}
            platform={filters.platform}
          />
        )}
      </Sidebar>
    </div>
  );
};

InfluencerListSidebar.propTypes = {
  filters: PropTypes.shape({
    platform: PropTypes.string.isRequired,
  }).isRequired,
  isSidebarOpen: PropTypes.bool.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  onClickButton: PropTypes.func.isRequired,
  onOpenSidebar: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  onSetNetworkIcon: PropTypes.func.isRequired,
};

export default InfluencerListSidebar;
