import gql from 'graphql-tag';
import CampaignCardFragment from '../CampaignCard/campaignCard.fragment';

export default gql`
  query($query: String, $filterBy: CampaignFilters, $firstCampaigns: Int, $afterCampaign: String) {
    campaigns(query: $query, filterBy: $filterBy, first: $firstCampaigns, after: $afterCampaign) {
      edges {
        node {
          ...CampaignCard
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${CampaignCardFragment}
`;
