import gql from 'graphql-tag';

export default gql`
  fragment YouTubeDetails on YouTubeChannel {
    averagePrices {
      contentType
      price
    }
    id
    title
    url
    engagementRate
    viewRate
    subscriberCount
    previewUrls
    audience {
      updatedAt
      genders {
        gender
        value
      }
      ages {
        maxAge
        minAge
        value
      }
      cities {
        city
        value
      }
      countries {
        country
        value
      }
    }
    deliverables {
      id
      campaign {
        id
        shortcode
        brand {
          name
        }
        name
      }
      creativeIterations {
        id
        price
        state
        media {
          id
          path
          type
        }
      }
      socialProfile {
        ... on YouTubeChannel {
          id
          title
          __typename
        }
      }
    }
    __typename
  }
`;
