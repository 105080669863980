import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'tabler-react';
import styles from './InputWithLoader.module.css';

const InputWithLoader = ({ placeholder, isLoading, onChange, className, ...rest }) => {
  return (
    <div className={styles.InputWithLoader}>
      <Form.Input
        className={className}
        onChange={onChange}
        placeholder={placeholder}
        type="text"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
      {isLoading && (
        <div className={styles.IconContainer}>
          <i className={styles.InputLoader} />
        </div>
      )}
    </div>
  );
};

InputWithLoader.defaultProps = {
  className: '',
  isLoading: false,
  onChange: () => {},
  placeholder: '',
};

InputWithLoader.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default InputWithLoader;
