import { ApolloClient } from 'apollo-client';
import { setContext as client } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import viewerUtils from '../utils/viewer';
import fragmentMatcher from './fragmentMatcher';

const authLink = client(() => {
  if (viewerUtils.isAuthenticated()) {
    return {
      headers: {
        authorization: `Bearer ${viewerUtils.readToken()}`,
      },
    };
  }
  return { headers: {} };
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
});

const apolloCache = new InMemoryCache({ fragmentMatcher });

export default new ApolloClient({
  cache: apolloCache,
  link: authLink.concat(uploadLink),
});
