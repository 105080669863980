import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Form, Icon } from 'tabler-react';
import './PlatformFilter.css';

const YOUTUBE = 'YOUTUBE';
const INSTAGRAM = 'INSTAGRAM';

const PlatformFilter = ({ platform }) => {
  const history = useHistory();

  return (
    <div className="platform-filter pr-5" data-testid="PlatformFilter">
      <Form.Group className="mb-0">
        <Form.SelectGroup>
          <Form.SelectGroupItem
            key={INSTAGRAM}
            checked={platform === INSTAGRAM}
            className="w-50 px-3"
            label={
              <span>
                <Icon name="instagram" /> Instagram
              </span>
            }
            name="platform"
            onChange={({ target: { value } }) => history.push(`/influencers?platform=${value}`)}
            value={INSTAGRAM}
          />
          <Form.SelectGroupItem
            key={YOUTUBE}
            checked={platform === YOUTUBE}
            className="w-50 px-3"
            label={
              <span>
                <Icon name="youtube-play" prefix="fa" /> YouTube
              </span>
            }
            name="platform"
            onChange={({ target: { value } }) => history.push(`/influencers?platform=${value}`)}
            value={YOUTUBE}
          />
        </Form.SelectGroup>
      </Form.Group>
    </div>
  );
};

PlatformFilter.defaultProps = {
  platform: '',
};

PlatformFilter.propTypes = {
  platform: PropTypes.string,
};

export default PlatformFilter;
