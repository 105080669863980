import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import generateCreativeIterationTitle from '../../../utils/generateCreativeIterationTitle';
import PageTitle from '../../../layouts/components/PageTitle';
import { generateContentUrl } from '../../../utils/helpers';
import styles from './InfluencerContentForCampaignPageTitle.module.css';

const InfluencerContentForCampaignPageTitle = ({
  campaign,
  creativeIterationType,
  order,
  currentDeliverable,
}) => {
  const { t } = useTranslation();
  const { brand, name } = campaign;
  const {
    socialProfile: { username, title, __typename },
  } = currentDeliverable;

  const pageTitle = (
    <>
      {name} / <Link to={generateContentUrl(campaign)}>{t('Content')}</Link> /{' '}
      {__typename === 'InstagramProfile' ? (
        <span className={styles.Handle}>{username}</span>
      ) : (
        <span>{title}</span>
      )}{' '}
      / {generateCreativeIterationTitle(creativeIterationType, order)}
    </>
  );

  return <PageTitle subtitle={brand ? brand.name : null} title={pageTitle} />;
};

InfluencerContentForCampaignPageTitle.propTypes = {
  campaign: PropTypes.shape({
    brand: PropTypes.shape({ name: PropTypes.string }),
    name: PropTypes.string.isRequired,
  }).isRequired,
  creativeIterationType: PropTypes.oneOf([
    'InstagramFeedImage',
    'InstagramFeedVideo',
    'InstagramStoryImage',
    'InstagramStoryVideo',
    'InstagramFeedCarousel',
    'YouTubeVideo',
  ]).isRequired,
  currentDeliverable: PropTypes.shape({
    socialProfile: PropTypes.shape({
      __typename: PropTypes.string.isRequired,
      title: PropTypes.string,
      username: PropTypes.string,
    }).isRequired,
  }).isRequired,
  order: PropTypes.number.isRequired,
};

export default InfluencerContentForCampaignPageTitle;
