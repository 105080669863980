import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

const introspectionQueryResultData = {
  __schema: {
    types: [
      {
        kind: 'INTERFACE',
        name: 'Node',
        possibleTypes: [
          { name: 'Campaign' },
          { name: 'Influencer' },
          { name: 'CreativeIteration' },
        ],
      },
      {
        kind: 'UNION',
        name: 'SocialProfile',
        possibleTypes: [{ name: 'InstagramProfile' }, { name: 'YouTubeChannel' }],
      },
      {
        kind: 'INTERFACE',
        name: 'Commentable',
        possibleTypes: [{ name: 'Influencer' }, { name: 'CreativeIteration' }],
      },
    ],
  },
};

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

export default fragmentMatcher;
