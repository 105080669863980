import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Table, Button } from 'tabler-react';

const MembersTable = () => {
  const { t } = useTranslation();

  return (
    <div data-testid="TeamMembersTable">
      <Table cards className="mt-5 table-vcenter" responsive striped>
        <Table.Header>
          <Table.Row>
            <Table.ColHeader>First Name</Table.ColHeader>
            <Table.ColHeader>Last Name</Table.ColHeader>
            <Table.ColHeader>E-mail</Table.ColHeader>
            <Table.ColHeader>Password</Table.ColHeader>
            <Table.ColHeader>Manager</Table.ColHeader>
            <Table.ColHeader>Remove Member</Table.ColHeader>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Col> Firakata</Table.Col>
            <Table.Col> Baby</Table.Col>
            <Table.Col> firkatababy@abv.bg</Table.Col>
            <Table.Col> 123456</Table.Col>
            <Table.Col className="d-flex justify-content-center">
              <Form.Checkbox label=" " />
            </Table.Col>
            <Table.Col>
              <Button block className="w-auto py-2" color="danger" onClick={() => {}}>
                {t('Remove member')}
              </Button>
            </Table.Col>
          </Table.Row>

          <Table.Row>
            <Table.Col> Radkata</Table.Col>
            <Table.Col> Baby</Table.Col>
            <Table.Col> radkatababy@abv.bg</Table.Col>
            <Table.Col> 123456</Table.Col>
            <Table.Col className="d-flex justify-content-center">
              <Form.Checkbox label=" " />
            </Table.Col>
            <Table.Col>
              <Button block className="w-auto py-2" color="danger" onClick={() => {}}>
                {t('Remove member')}
              </Button>
            </Table.Col>
          </Table.Row>

          <Table.Row>
            <Table.Col> Vladkata</Table.Col>
            <Table.Col> Baby</Table.Col>
            <Table.Col> vladtababy@abv.bg</Table.Col>
            <Table.Col> 123456</Table.Col>
            <Table.Col className="d-flex justify-content-center">
              <Form.Checkbox label=" " />
            </Table.Col>
            <Table.Col>
              <Button block className="w-auto py-2" color="danger" onClick={() => {}}>
                {t('Remove member')}
              </Button>
            </Table.Col>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};

export default MembersTable;
