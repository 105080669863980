import React from 'react';
import PropTypes from 'prop-types';
import styles from './Sidebar.module.css';

const Sidebar = ({ children, colSize }) => (
  <div className={`${styles.Sidebar} h-100`} id="sidebar" style={{ width: `${colSize}px` }}>
    {children}
  </div>
);

Sidebar.defaultProps = {
  colSize: 300,
};

Sidebar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  colSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Sidebar;
