import React from 'react';
import { Grid } from 'tabler-react';
import PropTypes from 'prop-types';
import styles from './ContentTitle.module.css';

const ContentTitle = props => {
  const { title } = props;
  return (
    <Grid.Row>
      <Grid.Col>
        <h5 className={`text-capitalize ${styles.Title}`}>{title}</h5>
      </Grid.Col>
    </Grid.Row>
  );
};

ContentTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ContentTitle;
