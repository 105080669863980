import gql from 'graphql-tag';

export default gql`
  query(
    $query: String
    $filterBy: DeliverableFilters
    $firstDeliverables: Int
    $afterDeliverable: String
    $creativeIterationState: CreativeIterationState
  ) {
    deliverables(
      query: $query
      filterBy: $filterBy
      first: $firstDeliverables
      after: $afterDeliverable
    ) {
      edges {
        node {
          id
          creativeIterations(state: $creativeIterationState) {
            id
            state
            media {
              id
              path
              type
            }
          }
          campaign {
            id
            name
            brand {
              name
            }
            shortcode
          }
          socialProfile {
            ... on InstagramProfile {
              id
              username
              url
              influencer {
                id
                name
                pictureUrl
              }
            }
            ... on YouTubeChannel {
              id
              title
              url
              influencer {
                id
                name
                pictureUrl
              }
            }
            __typename
          }
          advert {
            id
            url
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
