import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import RangeSlider from '../../RangeSlider';
import styles from './SubscriberCountFilter.module.css';

const MIN = '10000';
const MAX = '1000000';
const STEP = 1000;

const castValues = (subscriberCount, onApplyFilters) => {
  return subscriberCount.map(rate => {
    if (isNaN(Number(rate))) {
      onApplyFilters({ subscriberCount: [] });
      return null;
    }

    return Number(rate);
  });
};

const SubscriberCountFilter = ({ subscriberCount, onApplyFilters }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState(castValues(subscriberCount, onApplyFilters));
  useEffect(() => {
    setValues(castValues(subscriberCount, onApplyFilters));
  }, [subscriberCount, onApplyFilters]);

  return (
    <Form.Group>
      <Form.Label>{t('Subscriber Count')}</Form.Label>
      <div data-testid="SubscriberCountFilter">
        <RangeSlider
          className={styles.ThumbTooltip}
          max={Number(MAX)}
          min={Number(MIN)}
          onApplyFilters={finalValues => onApplyFilters({ subscriberCount: finalValues })}
          setValues={setValues}
          step={STEP}
          values={values}
        >
          {arg => arg.toLocaleString()}
        </RangeSlider>
      </div>
    </Form.Group>
  );
};

SubscriberCountFilter.defaultProps = {
  subscriberCount: [MIN],
};

SubscriberCountFilter.propTypes = {
  onApplyFilters: PropTypes.func.isRequired,
  subscriberCount: PropTypes.arrayOf(PropTypes.string),
};

export default SubscriberCountFilter;
