import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'tabler-react';
import HandleError from '../../../../layouts/components/ErrorMessage/HandleError';
import styles from './AddEntryForm.module.css';

const AddEntryForm = ({ onSubmit, addEntryError }) => {
  const [bodyState, setBodyState] = useState('');

  const handleSubmit = event => {
    event.preventDefault();
    if (bodyState === '') {
      return;
    }

    onSubmit(bodyState);
    setBodyState('');
  };

  return (
    <form onSubmit={event => handleSubmit(event)}>
      <textarea
        className={styles.Textarea}
        data-testid="InputArea"
        onChange={event => setBodyState(event.target.value)}
        value={bodyState}
      />
      {addEntryError && (
        <p className="text-danger text-center">
          <HandleError error={addEntryError} />
        </p>
      )}
      <Button
        className={styles.SubmitButton}
        color="success"
        disabled={bodyState === ''}
        type="submit"
      >
        Send
      </Button>
    </form>
  );
};

AddEntryForm.defaultProps = {
  addEntryError: null,
};

AddEntryForm.propTypes = {
  addEntryError: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
};

export default AddEntryForm;
