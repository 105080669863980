import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, Icon } from 'tabler-react';
import InfluencerWidget from '../../../influencers/components/InfluencerWidget';
import Edit from './ManagerActions/Edit';
import PreviewGallery from './PreviewGallery';
import ShortlistedSocialProfileStats from './ShortlistedSocialProfileStats';
import Rationale from './Rationale';
import StateLabel from './StateLabel';
import ReviewerActions from './ReviewerActions';
import styles from './ShortlistedSocialProfileCard.module.css';

const ShortlistedSocialProfileCard = ({
  onDetailsOpen,
  shortlistedProfile,
  viewerCanApprove,
  viewerCanAddTalent,
  viewerCanRecommend,
}) => {
  const { id, state, isBooked, isRecommended, rationale, node: socialProfile } = shortlistedProfile;
  const username = socialProfile.username || socialProfile.title;
  const { pictureUrl, previewUrls, influencer } = socialProfile;

  return (
    <div
      className={`${styles.ShortlistedSocialProfileCard} cardItem`}
      data-testid="ShortlistedSocialProfileCard"
    >
      <Card>
        <div className={styles.ActionsContainer}>
          {viewerCanAddTalent && (
            <Edit shortlistedProfile={shortlistedProfile} viewerCanRecommend={viewerCanRecommend} />
          )}
        </div>
        <div
          className="c-pointer"
          data-testid="ShortlistedSocialProfileCardClickable"
          onClick={() => onDetailsOpen(id)}
          onKeyPress={() => {}}
          role="button"
          tabIndex="0"
        >
          <PreviewGallery urls={previewUrls} />
          <div className="container">
            <Grid.Row className="pt-3">
              <Grid.Col>
                <InfluencerWidget hasInfo influencer={{ username, pictureUrl, ...influencer }} />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row className="pt-3">
              <ShortlistedSocialProfileStats profile={socialProfile} />
              {isRecommended && (
                <Grid.Col className="col p-0 pr-3 d-flex justify-content-end">
                  <Icon className={`fa-2x ${styles.RecommendedMarker}`} name="star" prefix="fa" />
                </Grid.Col>
              )}
              {isBooked && (
                <Grid.Col className="col p-0 pr-3 d-flex justify-content-end">
                  <Icon className={styles.BookedMarker} name="calendar-check-o" prefix="fa" />
                </Grid.Col>
              )}
            </Grid.Row>
          </div>
          {rationale && <Rationale className="mx-0" rationale={rationale} />}
        </div>
        {viewerCanApprove ? (
          <ReviewerActions profile={shortlistedProfile} />
        ) : (
          <StateLabel className="mx-0" state={state} />
        )}
      </Card>
    </div>
  );
};

ShortlistedSocialProfileCard.propTypes = {
  onDetailsOpen: PropTypes.func.isRequired,
  shortlistedProfile: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isBooked: PropTypes.bool,
    isRecommended: PropTypes.bool,
    node: PropTypes.shape({}),
    previewUrls: PropTypes.array,
    rationale: PropTypes.string,
    state: PropTypes.string,
  }).isRequired,
  viewerCanAddTalent: PropTypes.bool.isRequired, // eslint-disable-line react/boolean-prop-naming
  viewerCanApprove: PropTypes.bool.isRequired, // eslint-disable-line react/boolean-prop-naming
  viewerCanRecommend: PropTypes.bool.isRequired, // eslint-disable-line react/boolean-prop-naming
};

export default ShortlistedSocialProfileCard;
