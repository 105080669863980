import gql from 'graphql-tag';

export default gql`
  mutation($firstName: String!, $lastName: String!, $email: String!) {
    updateAccountSettings(input: { firstName: $firstName, lastName: $lastName, email: $email }) {
      user {
        id
        email
        name
        firstName
        lastName
      }
    }
  }
`;
