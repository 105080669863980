import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typeahead from '../../../Typeahead';

const RejectSocialProfileSelect = ({ onChange, placeholder, value }) => {
  const { t } = useTranslation();
  const options = [
    { label: t('Unsuitable content'), value: 'Unsuitable content' },
    { label: t('Other'), value: 'other' },
  ];

  return (
    <div className="mb-3">
      <Typeahead
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        type="select"
        value={
          value
            ? {
                value,
                label: options.filter(option => option.value === value)[0].label,
              }
            : null
        }
      />
    </div>
  );
};

RejectSocialProfileSelect.defaultProps = {
  value: '',
};

RejectSocialProfileSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default RejectSocialProfileSelect;
