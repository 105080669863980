import React from 'react';
import { Card, Grid } from 'tabler-react';
import PropTypes from 'prop-types';
import PreviewGallery from '../../../../../campaigns/components/ShortlistedSocialProfileCard/PreviewGallery';
import InfluencerWidget from '../../../InfluencerWidget';
import InfluencerSocialNetworksWidget from '../../../InfluencerSocialNetworksWidget';
import styles from './SocialProfileCard.module.css';

const SocialProfileCard = ({ profile, onClick }) => {
  const { id: profileId, previewUrls, influencer } = profile;
  const { id: influencerId, profiles } = influencer;

  return (
    <div
      className={styles.SocialProfileCard}
      data-testid="SocialProfileCard"
      onClick={() => onClick(influencerId, profileId)}
      onKeyPress={() => {}}
      role="button"
      tabIndex="0"
    >
      <Card>
        <PreviewGallery urls={previewUrls} />
        <div className="container">
          <Grid.Row className="pt-3">
            <Grid.Col>
              <InfluencerWidget hasInfo influencer={influencer} />
            </Grid.Col>
          </Grid.Row>
        </div>
        <div className={`${styles.Icons} container`}>
          <Grid.Row className="pt-3 pb-3">
            <Grid.Col>
              <InfluencerSocialNetworksWidget className="colored" profiles={profiles} />
            </Grid.Col>
          </Grid.Row>
        </div>
      </Card>
    </div>
  );
};

SocialProfileCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    id: PropTypes.string.isRequired,
    influencer: PropTypes.shape({
      id: PropTypes.string.isRequired,
      profiles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
    previewUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};
export default SocialProfileCard;
