import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import countriesLocaleEn from 'i18n-iso-countries/langs/en.json';
import countries from 'i18n-iso-countries';

// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      // not needed for react as it escapes by default
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {},
      },
    },
  });

countries.registerLocale(countriesLocaleEn);

export default i18n;
