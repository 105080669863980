import gql from 'graphql-tag';

export default gql`
  mutation($id: ID!, $state: SocialProfileReviewState, $rejectionReason: String) {
    updateShortlistedProfile(input: { id: $id, state: $state, rejectionReason: $rejectionReason }) {
      shortlistedProfile {
        id
        state
        rejectionReason
      }
    }
  }
`;
