import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MEDIA_TYPES } from '../../../constants';
import { generateContentUrlFromDeliverable } from '../../../utils/helpers';
import styles from './ContentGalleryItem.module.css';

const ContentGalleryItem = ({ deliverable }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const {
    creativeIterations: [
      {
        media: [{ type, path }],
      },
    ],
    campaign,
    socialProfile: {
      url: profileUrl,
      influencer: { pictureUrl, name: influencerName },
    },
    advert,
  } = deliverable;

  return (
    <div className={styles.ContentGalleryItem} data-testid="ContentGalleryItem">
      <Link
        className={styles.MediaContainer}
        data-testid="MediaContainer"
        to={generateContentUrlFromDeliverable(deliverable)}
      >
        {type === MEDIA_TYPES.IMAGE ? (
          <img
            alt={campaign.name}
            className={styles.Media}
            onLoad={() => {
              setIsLoaded(true);
            }}
            src={path}
            style={{ display: isLoaded ? null : 'none' }}
          />
        ) : (
          <video className={`${styles.Media} w-100`} preload="metadata">
            <source src={path} />
          </video>
        )}
        <div className={styles.Dim} />
      </Link>
      {advert && (
        <div className={styles.TopRow}>
          <a
            className={styles.ExternalLink}
            data-testid="ExternalLink"
            href={advert.url}
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fe fe-external-link" />
          </a>
        </div>
      )}
      <div className={styles.BottomRow}>
        <a
          className={styles.Influencer}
          data-testid="InfluencerInfo"
          href={profileUrl}
          rel="noopener noreferrer"
          target="_blank"
        >
          <div
            className={styles.Avatar}
            style={{
              backgroundImage: `url("${pictureUrl}")`,
            }}
          />
          <div className={styles.Name}>{influencerName}</div>
        </a>
      </div>
    </div>
  );
};

ContentGalleryItem.propTypes = {
  deliverable: PropTypes.shape({
    advert: PropTypes.shape({
      url: PropTypes.string,
    }),
    campaign: PropTypes.shape({
      brand: PropTypes.shape({
        name: PropTypes.string,
      }),
      name: PropTypes.string,
      shortcode: PropTypes.string,
    }),
    creativeIterations: PropTypes.arrayOf(
      PropTypes.shape({
        media: PropTypes.arrayOf(
          PropTypes.shape({
            path: PropTypes.string,
            type: PropTypes.string,
          })
        ),
      })
    ),
    id: PropTypes.string,
    socialProfile: PropTypes.shape({
      id: PropTypes.string,
      influencer: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        pictureUrl: PropTypes.string,
      }),
      title: PropTypes.string,
      url: PropTypes.string,
      username: PropTypes.string,
    }),
  }).isRequired,
};

export default ContentGalleryItem;
