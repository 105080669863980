import React from 'react';
import PropTypes from 'prop-types';
import { Site, Page } from 'tabler-react';
import Header from './components/Header';

const Authorized = ({ children }) => {
  return (
    <Site>
      <Page.Main>
        <Header />
        {children}
      </Page.Main>
    </Site>
  );
};

Authorized.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Authorized;
