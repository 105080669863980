import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'tabler-react';
import Drawer from '../../../layouts/components/Drawer';
import Form from './Form';

const CreateTeamMember = () => {
  const { t } = useTranslation();

  const [createCampaignFormIsOpen, setCreateCampaignFormIsOpen] = useState(false);

  return (
    <div className="float-right">
      <Drawer
        handleClose={() => {
          setCreateCampaignFormIsOpen(false);
        }}
        isOpen={createCampaignFormIsOpen}
      >
        <Form
          onSubmitSuccess={() => {
            setCreateCampaignFormIsOpen(false);
            // trigger refetch of the shortlist after submit success
            // refetch();
          }}
        />
      </Drawer>
      <div data-testid="CreateTeamMemberButton">
        <Button
          block
          className="w-auto py-2"
          color="success"
          onClick={() => {
            setCreateCampaignFormIsOpen(true);
          }}
        >
          {t('Add Team Member')}
        </Button>
      </div>
    </div>
  );
};

export default CreateTeamMember;
