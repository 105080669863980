import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styles from './FoldedSidebar.module.css';

const FoldedSidebar = ({ onOpenSidebar, onSetNetworkIcon, platform }) => {
  const history = useHistory();
  const networks = ['INSTAGRAM', 'YOUTUBE'];

  return (
    <div className={`h-100  ${styles.SidebarFolded}`} data-testid="SidebarFolded">
      <form className={styles.Form}>
        {networks.map(network => (
          <label key={network} htmlFor={network}>
            <input
              checked={platform === network}
              data-testid={`${network}-IconFolded`}
              id={network}
              name="platform"
              onChange={({ target: { value } }) => {
                history.push(`/influencers?platform=${value}`);
                onOpenSidebar();
              }}
              onClick={onOpenSidebar}
              type="radio"
              value={network}
            />
            <span className={styles.Label} title={network}>
              {onSetNetworkIcon(network, 'colored')}
            </span>
          </label>
        ))}
      </form>
    </div>
  );
};

FoldedSidebar.propTypes = {
  onOpenSidebar: PropTypes.func.isRequired,
  onSetNetworkIcon: PropTypes.func.isRequired,
  platform: PropTypes.string.isRequired,
};

export default FoldedSidebar;
