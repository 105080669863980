import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import Loader from '../../../layouts/components/Loader';
import ErrorMessage from '../../../layouts/components/ErrorMessage';
import GET_CAMPAIGN_CONTENT from './campaignContent.query';

const InfluencerContentForCampaignPageQueryContainer = ({ children, shortcode, influencerId }) => {
  const { loading, error, data } = useQuery(GET_CAMPAIGN_CONTENT, {
    variables: {
      shortcode,
      influencerId,
    },
  });

  if (error) return <ErrorMessage error={error} />;

  if (loading) return <Loader />;

  const { campaign } = data;

  return <div className="h-100 scrollableContainer">{children(campaign)}</div>;
};

InfluencerContentForCampaignPageQueryContainer.propTypes = {
  children: PropTypes.func.isRequired,
  influencerId: PropTypes.string.isRequired,
  shortcode: PropTypes.string.isRequired,
};

export default InfluencerContentForCampaignPageQueryContainer;
