import gql from 'graphql-tag';

export default gql`
  mutation($id: ID!, $name: String!, $brand: String!, $state: CampaignState!) {
    updateCampaign(input: { id: $id, name: $name, brand: $brand, state: $state }) {
      campaign {
        id
        name
        brand {
          name
        }
        state
      }
    }
  }
`;
