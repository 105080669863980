import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Card } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import InfluencerGenderAndLocation from '../../../../influencers/components/InfluencerGenderAndLocation';
import ShortlistedSocialProfileStats from '../../ShortlistedSocialProfileCard/ShortlistedSocialProfileStats';
import InfluencerSocialNetworksWidget from '../../../../influencers/components/InfluencerSocialNetworksWidget';
import SocialProfileDemographics from './SocialProfileDemographics';
import styles from './Details.module.css';

const Details = ({ hasDemographics, shortlistedProfile }) => {
  const { t } = useTranslation();
  const { node: socialProfile, rationale, rejectionReason } = shortlistedProfile;
  const { influencer } = socialProfile;

  return (
    <div className={styles.ShortlistedProfileDetails}>
      <Card className={`${styles.ProfileCard} px-3 pt-2`}>
        <Grid.Row>
          <Grid.Col className="d-flex justify-content-center">
            <div
              className={`${styles.ProfileImage}`}
              style={{
                backgroundImage: `url("${socialProfile.pictureUrl}")`,
              }}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row className="pt-5">
          <Grid.Col className="d-flex justify-content-center">
            <h2 className={`${styles.Name} mb-0`}>{influencer.name}</h2>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col className="d-flex justify-content-center">
            <h2
              className={`${styles.Nickname} ${
                socialProfile.__typename === 'InstagramProfile' ? styles.Username : ''
              }`}
            >
              {socialProfile.username}
            </h2>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col className="d-flex justify-content-center">
            <InfluencerGenderAndLocation influencer={influencer} />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row className="py-3">
          <Grid.Col className="d-flex justify-content-center">
            <InfluencerSocialNetworksWidget
              className={`${styles.Icons} colored`}
              profiles={influencer.profiles}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col className="d-flex justify-content-center">
            <p className={`text-muted text-center ${styles.Bio}`}>
              {socialProfile.biography || socialProfile.description}
            </p>
          </Grid.Col>
        </Grid.Row>
        <hr />
        {rationale && (
          <>
            <Grid.Row>
              <Grid.Col className="d-flex justify-content-center">
                <p className={`text-muted text-justify ${styles.Rationale}`}>
                  <span className="font-weight-bold">{t('Info')}: </span>
                  {rationale}
                </p>
              </Grid.Col>
            </Grid.Row>
            <hr />
          </>
        )}
        {rejectionReason && (
          <>
            <Grid.Row>
              <Grid.Col className="d-flex justify-content-center">
                <p className={`text-muted text-justify ${styles.RejectionReason}`}>
                  <span className="font-weight-bold">{t('Rejеction Reason')}: </span>
                  {rejectionReason}
                </p>
              </Grid.Col>
            </Grid.Row>
            <hr />
          </>
        )}
        <Grid.Row>
          <Grid.Col className={`d-flex justify-content-center text-center ${styles.Stats}`}>
            <ShortlistedSocialProfileStats profile={socialProfile} size="large" />
          </Grid.Col>
        </Grid.Row>
        {hasDemographics && (
          <>
            <hr />
            <SocialProfileDemographics profile={socialProfile} />
          </>
        )}

        <hr />
        <Grid.Row>
          <Grid.Col>
            {socialProfile.previewUrls.map(url => (
              <div
                key={url}
                className={styles.Image}
                style={{ backgroundImage: `url("${url}")` }}
              />
            ))}
          </Grid.Col>
        </Grid.Row>
      </Card>
    </div>
  );
};

Details.propTypes = {
  hasDemographics: PropTypes.bool.isRequired,
  shortlistedProfile: PropTypes.shape({
    node: PropTypes.shape({
      biography: PropTypes.string,
      description: PropTypes.string,
      pictureUrl: PropTypes.string,
      previewUrls: PropTypes.array,
      username: PropTypes.string,
    }),
    rationale: PropTypes.string,
    rejectionReason: PropTypes.string,
  }).isRequired,
};

export default Details;
