import React from 'react';
import { Icon } from 'tabler-react';
import PropTypes from 'prop-types';
import styles from './InfluencerSocialNetworksWidget.module.css';

const InfluencerSocialNetworksWidget = ({ className, profiles }) => {
  const networkIconMap = {
    InstagramProfile: <Icon className={`${className} ${styles.Icon} pr-1`} name="instagram" />,
    YouTubeChannel: (
      <Icon className={`${className} ${styles.Icon} pr-1`} name="youtube-play" prefix="fa" />
    ),
  };

  return profiles.map(({ id, __typename, url }) => (
    <a key={id} href={url} rel="noopener noreferrer" target="_blank">
      {networkIconMap[__typename]}
    </a>
  ));
};

InfluencerSocialNetworksWidget.defaultProps = {
  className: '',
};
InfluencerSocialNetworksWidget.propTypes = {
  className: PropTypes.string,
  profiles: PropTypes.arrayOf(
    PropTypes.shape({
      __typename: PropTypes.oneOf(['InstagramProfile', 'YouTubeChannel']).isRequired,
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default InfluencerSocialNetworksWidget;
