/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Range, getTrackBackground } from 'react-range';
import styles from './RangeSlider.module.css';

const RangeSlider = ({
  className,
  children: childrenThumb,
  max,
  min,
  onApplyFilters,
  setValues,
  step,
  values,
}) => (
  <Range
    max={max}
    min={min}
    onChange={newValues => {
      setValues(newValues);
    }}
    onFinalChange={finalValues => {
      onApplyFilters(finalValues);
    }}
    renderThumb={({ index, props: thumbProps, isDragged }) => (
      <div
        className={styles.Thumb}
        data-testid={`${index === 0 ? 'Min' : 'Max'}Thumb`}
        {...thumbProps}
        style={{
          ...thumbProps.style,
        }}
      >
        <div
          className={`${styles.ThumbTooltip} ${className} ${
            index === 1 ? styles.MaxValueTooltip : null
          }`}
          style={{
            zIndex: isDragged ? '10' : null,
          }}
        >
          {childrenThumb(Number(values[index]))}
        </div>
      </div>
    )}
    renderTrack={({ props: trackProps, children }) => (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={styles.Track}
        // eslint-disable-next-line react/jsx-handler-names
        onMouseDown={trackProps.onMouseDown}
        // eslint-disable-next-line react/jsx-handler-names
        onTouchStart={trackProps.onTouchStart}
        style={{
          ...trackProps.style,
        }}
      >
        <div
          ref={trackProps.ref}
          className={styles.TrackLine}
          style={{
            background: getTrackBackground({
              values,
              // Magic: generates array of color codes based on values length
              colors: [
                '#a1a1a1',
                ...values.map((_, index) => (index % 2 === 0 ? '#66afe9' : '#a1a1a1')),
              ],
              min,
              max,
            }),
          }}
        >
          {children}
        </div>
      </div>
    )}
    step={step}
    values={values}
  />
);

RangeSlider.defaultProps = {
  className: '',
};

RangeSlider.propTypes = {
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
};

export default RangeSlider;
/* eslint-enable react/jsx-props-no-spreading */
