import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'tabler-react';
import Drawer from '../../../../../layouts/components/Drawer';
import Form from './Form';
import styles from './Edit.module.css';

const Edit = ({ shortlistedProfile, viewerCanRecommend }) => {
  const { t } = useTranslation();
  const [editShortlistedSocialProfile, setEditShortlistedSocialProfile] = useState(false);

  return (
    <>
      <Drawer
        handleClose={() => {
          setEditShortlistedSocialProfile(false);
        }}
        isOpen={editShortlistedSocialProfile}
      >
        {editShortlistedSocialProfile && (
          <Form
            onSubmitSuccess={() => {
              setEditShortlistedSocialProfile(false);
            }}
            shortlistedProfile={shortlistedProfile}
            viewerCanRecommend={viewerCanRecommend}
          />
        )}
      </Drawer>
      <Button
        className={`text-center w-auto float-right py-2 ${styles.EditButton}`}
        color="primary-light"
        onClick={() => {
          setEditShortlistedSocialProfile(true);
        }}
      >
        {t('Edit')}
      </Button>
    </>
  );
};

Edit.propTypes = {
  shortlistedProfile: PropTypes.shape().isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  viewerCanRecommend: PropTypes.bool.isRequired,
};

export default Edit;
