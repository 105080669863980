import gql from 'graphql-tag';
import ConversationEntry from '../../../common/components/Conversation/ConversationEntry';
import InfluencerInfo from '../InfluencerInfo';

export default gql`
  query Influencer($id: ID!, $firstNotes: Int, $afterNote: String) {
    influencer(id: $id) {
      id
      ...InfluencerInfo
      comments(first: $firstNotes, after: $afterNote) {
        ... on CommentConnection {
          edges {
            node {
              ...ConversationEntry
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
  ${InfluencerInfo.fragments.influencerInfo}
  ${ConversationEntry.fragments.conversationEntry}
`;
