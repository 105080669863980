import gql from 'graphql-tag';

export default gql`
  mutation($creativeIterationId: ID!, $state: CreativeIterationState!, $comment: String) {
    updateCreativeIterationState(
      input: { creativeIterationId: $creativeIterationId, state: $state, comment: $comment }
    ) {
      creativeIteration {
        id
        state
        comment
        reviewer {
          id
          name
        }
      }
    }
  }
`;
