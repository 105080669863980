import React from 'react';
import PropTypes from 'prop-types';
import { CREATIVE_ITERATION_STATES } from '../../../../constants';
import StateLabel from '../StateLabel';
import AddStats from '../../AddStats';
import ShowToReviewer from './ShowToReviewer';
import UploadContent from './UpdateCreative/UploadContent';
import EditContent from './UpdateCreative/EditContent';
import MarkAsPosted from './MarkAsPosted';

const ManagerActions = ({ deliverable, permissions }) => {
  const {
    advert,
    creativeIterations: [creativeIteration],
    type: deliverableType,
  } = deliverable;
  const { id: creativeIterationId, media, state, reviewerHasAccess } = creativeIteration;

  const {
    viewerCanAddStats,
    viewerCanShowToReviewer,
    viewerCanUploadContent,
    viewerCanMarkAsPosted,
  } = permissions;

  if (viewerCanUploadContent && media.length === 0) {
    return (
      <UploadContent creativeIteration={creativeIteration} deliverableType={deliverableType} />
    );
  }
  if (
    viewerCanShowToReviewer &&
    !reviewerHasAccess &&
    state === CREATIVE_ITERATION_STATES.PENDING
  ) {
    return (
      <>
        <ShowToReviewer creativeIteration={creativeIteration} />
        <div className="pt-2">
          <EditContent creativeIteration={creativeIteration} deliverableType={deliverableType} />
        </div>
      </>
    );
  }
  if (state !== CREATIVE_ITERATION_STATES.APPROVED && state !== CREATIVE_ITERATION_STATES.POSTED) {
    return (
      <>
        <StateLabel creativeIteration={creativeIteration} />
        <div className="pt-2">
          <EditContent creativeIteration={creativeIteration} deliverableType={deliverableType} />
        </div>
      </>
    );
  }
  if (viewerCanMarkAsPosted && state === CREATIVE_ITERATION_STATES.APPROVED) {
    return (
      <>
        <StateLabel creativeIteration={creativeIteration} />
        <div className="pt-2">
          <MarkAsPosted creativeIterationId={creativeIterationId} />
        </div>
      </>
    );
  }
  if (viewerCanAddStats && state === CREATIVE_ITERATION_STATES.POSTED) {
    return (
      <>
        <StateLabel creativeIteration={creativeIteration} />
        <div className="pt-2">
          <AddStats advert={advert} />
        </div>
      </>
    );
  }
  return <StateLabel creativeIteration={creativeIteration} />;
};

ManagerActions.propTypes = {
  deliverable: PropTypes.shape({
    advert: PropTypes.shape(),
    creativeIterations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        media: PropTypes.array.isRequired,
        reviewerHasAccess: PropTypes.bool.isRequired,
        state: PropTypes.oneOf(Object.values(CREATIVE_ITERATION_STATES)).isRequired,
      })
    ).isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    viewerCanAddStats: PropTypes.bool.isRequired,
    viewerCanMarkAsPosted: PropTypes.bool.isRequired,
    viewerCanShowToReviewer: PropTypes.bool.isRequired,
    viewerCanUploadContent: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ManagerActions;
