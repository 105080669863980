import gql from 'graphql-tag';
import ConversationEntry from '../../../common/components/Conversation/ConversationEntry';

export default gql`
  mutation($comment: AddCommentInput!) {
    addComment(input: $comment) {
      comment {
        ...ConversationEntry
      }
    }
  }
  ${ConversationEntry.fragments.conversationEntry}
`;
