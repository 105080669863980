import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'tabler-react';

const AvatarFormatter = ({ value: { pictureUrl, name } }) => {
  return (
    <div className="text-center" title={name}>
      <Avatar imageURL={pictureUrl} />
    </div>
  );
};

AvatarFormatter.defaultProps = {
  value: {},
};

AvatarFormatter.propTypes = {
  value: PropTypes.shape({
    name: PropTypes.string,
    pictureUrl: PropTypes.string,
  }),
};

export default AvatarFormatter;
