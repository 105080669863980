import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getName as getCountryName } from 'i18n-iso-countries';
import { Grid, Card, Button } from 'tabler-react';
import ConversationEntry from '../../../../../common/components/Conversation/ConversationEntry';
import HandleError from '../../../../../layouts/components/ErrorMessage/HandleError';
import styles from './InfluencerInternalNotesCard.module.css';

const InfluencerInternalNotesCard = ({ influencer, onSubmit, addEntryError }) => {
  const { t } = useTranslation();
  const { name, email, country, comments } = influencer;
  const { edges: notes } = comments;

  const [bodyState, setBodyState] = useState('');

  const handleSubmit = event => {
    event.preventDefault();
    if (bodyState === '') {
      return;
    }
    onSubmit(bodyState);
    setBodyState('');

    const element = event.target;
    element.style.height = '18px';
  };

  const handleUserKeyPress = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const autoGrow = event => {
    const element = event.target;
    element.style.height = '18px';
    element.style.height = `${element.scrollHeight}px`;
  };

  return (
    <div data-testid="InfluencerNotesCard">
      <Card className={`${styles.NoteCard}`}>
        <Grid.Row className={styles.Contact}>
          <Grid.Col className={`${styles.BorderRight} p-0 my-2`} width="4">
            <div className={`${styles.Title} font-weight-bold p-2`}>{t('Contact details')}</div>
          </Grid.Col>
          <Grid.Col className="p-0">
            <div className="px-2 py-3">
              <div>{name && name}</div>
              {email && (
                <>
                  <div className="font-weight-bold pt-2">{t('E-mail')}</div>
                  <a
                    className="c-pointer"
                    href={`mailto:${email}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {email}
                  </a>
                </>
              )}
              {country && (
                <>
                  <div className="font-weight-bold pt-2">{t('Adress')}</div>
                  <div>{getCountryName(country, 'en')}</div>
                </>
              )}
            </div>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col className="p-0">
            <div className={`${styles.NotesTitle} font-weight-bold px-2 py-3`}>
              {t('Influencer notes')}
            </div>
            <div className={`${styles.NotesContainer} py-3 px-2`}>
              {notes.length === 0
                ? '-'
                : notes.map(({ node: note }) => {
                    return <ConversationEntry key={note.id} conversationEntry={note} />;
                  })}
            </div>
            <div className={styles.Wrapper}>
              {addEntryError && (
                <p className="text-danger text-center">
                  <HandleError error={addEntryError} />
                </p>
              )}
              <form className={styles.Form} onSubmit={event => handleSubmit(event)}>
                <textarea
                  className={styles.Textarea}
                  data-testid="InputArea"
                  onChange={event => setBodyState(event.target.value)}
                  onInput={event => autoGrow(event)}
                  onKeyPress={handleUserKeyPress}
                  placeholder={t('Add a note...')}
                  value={bodyState}
                />
                <Button className={styles.SubmitButton} disabled={bodyState === ''} type="submit">
                  {t('Add')}
                </Button>
              </form>
            </div>
          </Grid.Col>
        </Grid.Row>
      </Card>
    </div>
  );
};

InfluencerInternalNotesCard.defaultProps = {
  addEntryError: null,
};

InfluencerInternalNotesCard.propTypes = {
  addEntryError: PropTypes.shape({}),
  influencer: PropTypes.shape({
    comments: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            body: PropTypes.string,
          }),
        })
      ),
    }),
    country: PropTypes.string,
    email: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default InfluencerInternalNotesCard;
