import React from 'react';
import Footer from '../Footer';
import styles from './FooterFixed.module.css';

const FooterFixed = () => (
  <div className={`${styles.SlideUp} ${styles.Footer}`}>
    <Footer />
  </div>
);

export default FooterFixed;
