import React, { useState, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import Files from 'react-files';
import { Form, Button, Icon } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import Loader from '../../../layouts/components/Loader';
import BetaFeature from '../../../common/components/BetaFeature';
import viewerUtils from '../../../utils/viewer';
import UPDATE_ACCOUNT_SETTINGS from './updateAccountSettings.mutation';
import styles from './AccountSettingsForm.module.css';

const AccountSettingsForm = () => {
  const { t } = useTranslation();
  const [avatar, setAvatar] = useState({});
  const [, setFileError] = useState('');
  const [user, setUser] = useState(viewerUtils.readViewer());
  const [errors, setErrors] = useState({});
  const [generalError, setGeneralError] = useState();
  const filesRef = useRef(null);

  const handleFilesChange = filesArray => setAvatar(filesArray[0]);
  const handleFilesError = error => setFileError(error.message);

  const [updateAccountSettings, { loading }] = useMutation(UPDATE_ACCOUNT_SETTINGS, {
    update: (_, { data }) => {
      viewerUtils.writeViewer({ ...user, ...data.updateAccountSettings.user });
    },
  });

  const handleSubmit = async event => {
    setErrors({});
    event.preventDefault();

    if (!user.firstName) {
      setErrors(prevErr => ({ ...prevErr, firstName: t(`First name can't be empty`) }));
      return;
    }
    if (!user.lastName) {
      setErrors(prevErr => ({ ...prevErr, lastName: t(`Last name can't be empty`) }));
      return;
    }
    if (!user.email) {
      setErrors(prevErr => ({ ...prevErr, email: t(`Email can't be empty`) }));
      return;
    }

    try {
      const { firstName, lastName, email } = event.target.elements;

      await updateAccountSettings({
        variables: {
          firstName: firstName.value,
          lastName: lastName.value,
          email: email.value,
        },
      });
    } catch (err) {
      setGeneralError(t('Something went wrong...'));
    }
  };

  return (
    <div className="w-100" data-testid="AccountSettingsForm">
      {loading && <Loader />}
      <Form className="w-100" onSubmit={handleSubmit}>
        <h1 className={`w-100  ${styles.FormTitle}`}>{t('Account Settings')}</h1>
        <p className={`w-100 pb-3 ${styles.FormSubtitle}`}>{t('All your settings in one place')}</p>
        <div className="form-group">
          <Form.Label className={styles.FormLabel}>
            {t('First Name')}
            <Form.Input
              className={`pt-3 ${styles.FormInput}`}
              cross={false}
              feedback={errors.firstName}
              invalid={Boolean(errors.firstName)}
              name="firstName"
              onChange={event => {
                const { value } = event.target;
                setUser(prevUser => {
                  return { ...prevUser, firstName: value };
                });
              }}
              value={user.firstName}
            />
          </Form.Label>
        </div>
        <div className="form-group">
          <Form.Label className={styles.FormLabel}>
            {t('Last Name')}
            <Form.Input
              className={styles.FormInput}
              cross={false}
              feedback={errors.lastName}
              invalid={Boolean(errors.lastName)}
              name="lastName"
              onChange={event => {
                const { value } = event.target;
                setUser(prevUser => ({ ...prevUser, lastName: value }));
              }}
              value={user.lastName}
            />
          </Form.Label>
        </div>

        <BetaFeature>
          <div className="form-group">
            <Form.Label className={styles.FormLabel}>{t('Avatar upload')}</Form.Label>
            <Files
              ref={filesRef}
              // explicitly added video/mp4 in order to work in Safari
              accepts={['image/jpeg', 'image/png']}
              className={styles.Dropzone}
              clickable
              maxFileSize={10000000}
              minFileSize={0}
              onChange={handleFilesChange}
              onError={handleFilesError}
            >
              <div
                className={`${styles.Avatar} justify-content-center align-items-center d-flex pl-2`}
                style={{
                  backgroundImage: `url("${
                    avatar.preview ? avatar.preview.url : user.profilePictureUrl
                  }"`,
                }}
              >
                {(!user.profilePictureUrl || avatar.preview) && <Icon name="plus" />}
              </div>
            </Files>
          </div>
        </BetaFeature>

        <div className="form-group">
          <Form.Label className={styles.FormLabel}>
            {t('Email')}
            <Form.Input
              className={styles.FormInput}
              cross={false}
              feedback={errors.email}
              invalid={Boolean(errors.email)}
              name="email"
              onChange={event => {
                const { value } = event.target;
                setUser(prevUser => ({ ...prevUser, email: value }));
              }}
              value={user.email}
            />
          </Form.Label>
        </div>

        <Form.Footer className="mt-4 text-center">
          {generalError && <p className="text-danger">{generalError}</p>}
          <Button block className="px-2" color="success" disabled={loading} type="submit">
            {t('Update Account Settings')}
          </Button>
        </Form.Footer>
      </Form>
    </div>
  );
};

export default AccountSettingsForm;
