import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import TimeAgo from 'react-timeago';
import styles from './ConversationEntry.module.css';

const ConversationEntry = ({
  conversationEntry: {
    author: { name },
    body,
    postedAt,
  },
}) => {
  return (
    <div className={styles.Entry} data-testid="ConversationEntry">
      <p className={styles.EntryBody}>
        <span className={styles.Username}>{name}</span>
        {body}
      </p>
      <span className={styles.Time}>
        <TimeAgo date={postedAt} />
      </span>
    </div>
  );
};

ConversationEntry.fragments = {
  conversationEntry: gql`
    fragment ConversationEntry on Comment {
      id
      body
      author {
        id
        name
      }
      postedAt
    }
  `,
};

ConversationEntry.propTypes = {
  conversationEntry: PropTypes.shape({
    author: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    body: PropTypes.string.isRequired,
    postedAt: PropTypes.string.isRequired,
  }).isRequired,
};

export default ConversationEntry;
