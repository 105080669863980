import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Container } from 'tabler-react';
import nl2br from 'react-newline-to-break';
import ContentMedia from '../ContentMedia';
import styles from './InstagramFeedTemplate.module.css';

const InstagramFeedTemplate = ({ children, deliverable }) => {
  const {
    creativeIterations: [{ media, caption }],
    socialProfile: { username },
  } = deliverable;

  return (
    <div className={`mt-3 ${styles.Template}`}>
      <Container className={styles.InstagramFeedContainer}>
        <Grid.Row className="p-2 d-flex align-items-center">
          <div className={styles.InstagramFeedAvatar} />
          <Grid.Col className={`ml-3 col-2 ${styles.InstagramFeedFadedText}`} />
          <Grid.Col className="d-flex justify-content-end align-items-center">
            <div className={`float-left ${styles.InstagramFeedDotAction}`} />
            <div className={`float-left ml-1 ${styles.InstagramFeedDotAction}`} />
            <div className={`float-left ml-1 ${styles.InstagramFeedDotAction}`} />
          </Grid.Col>
        </Grid.Row>

        <Grid.Row className="mt-3">
          <ContentMedia media={media} />
        </Grid.Row>

        <Grid.Row className={`mt-3 mb-2 ${styles.InstagramFeedText}`}>
          <Grid.Col className={styles.Caption}>
            <b>{username}</b> {nl2br(caption)}
          </Grid.Col>
        </Grid.Row>

        <Grid.Row className="p-3 d-flex align-items-center">
          <Grid.Col className={`col-2 ${styles.InstagramFeedFadedText}`} />
        </Grid.Row>
      </Container>
      {children}
    </div>
  );
};

InstagramFeedTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  deliverable: PropTypes.shape({
    creativeIterations: PropTypes.arrayOf(
      PropTypes.shape({
        caption: PropTypes.string,
        media: PropTypes.array.isRequired,
      })
    ),
    socialProfile: PropTypes.shape({
      username: PropTypes.string,
    }),
  }).isRequired,
};
export default InstagramFeedTemplate;
