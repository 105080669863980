import React from 'react';
import PropTypes from 'prop-types';
import viewerUtils from '../../../utils/viewer';
import styles from './BetaFeature.module.css';

const BetaFeature = ({ children, hasBadge }) => {
  if (!viewerUtils.isBetaUser()) {
    return null;
  }

  return (
    <div className={`${styles.Beta} ${hasBadge ? 'mt-3' : ''}`}>
      {hasBadge && <div className={styles.Badge}>BETA</div>}
      {children}
    </div>
  );
};

BetaFeature.defaultProps = {
  hasBadge: true,
};

BetaFeature.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  hasBadge: PropTypes.bool,
};

export default BetaFeature;
