import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'tabler-react';
import Drawer from '../../../layouts/components/Drawer';
import Form from './Form';

const AddStats = ({ advert }) => {
  const { t } = useTranslation();
  const [addStatsFormIsOpen, setAddStatsFormIsOpen] = useState(false);

  return (
    <>
      <Drawer
        handleClose={() => {
          setAddStatsFormIsOpen(false);
        }}
        isOpen={addStatsFormIsOpen}
      >
        {addStatsFormIsOpen && (
          <Form
            advert={advert}
            onSubmitSuccess={() => {
              setAddStatsFormIsOpen(false);
            }}
          />
        )}
      </Drawer>
      <Button
        block
        className="text-center w-100 "
        color="success"
        onClick={() => {
          setAddStatsFormIsOpen(true);
        }}
      >
        {t('Add Stats')}
      </Button>
    </>
  );
};

AddStats.propTypes = {
  advert: PropTypes.shape().isRequired,
};

export default AddStats;
