import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { Form, Button, Alert } from 'tabler-react';
import viewerUtils from '../../../utils/viewer';
import Loader from '../../../layouts/components/Loader';
import LOGIN from './login.mutation';
import styles from './LoginForm.module.css';

const LoginForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [error, setError] = useState('');

  const [login, { loading }] = useMutation(LOGIN, {
    update: (_, { data }) => {
      viewerUtils.writeToken(data.login.token);
      viewerUtils.writeViewer(data.login.user);

      const { from } = location.state || { from: { pathname: '/' } };
      history.replace(from);
    },
  });

  const handleSubmit = async event => {
    event.preventDefault();
    const { email, password } = event.target.elements;

    try {
      await login({
        variables: {
          email: email.value,
          password: password.value,
        },
      });
    } catch (err) {
      setError(err);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Form className={`${styles.FadeInZoom} ${styles.LoginForm}`} onSubmit={handleSubmit}>
      {error && (
        <Alert isDismissible type="danger">
          {t('Bad credentials, please try again.')}
        </Alert>
      )}
      <Form.Input
        className={`${styles.FormInput} mb-1`}
        name="email"
        placeholder={t('Enter your email address')}
      />
      <Form.Input
        className={`${styles.FormInput} mb-1`}
        name="password"
        placeholder={t('Enter your password')}
        type="password"
      />
      <Form.Footer className="mt-3">
        <Button
          block
          className={styles.FormSubmit}
          color="success"
          disabled={loading}
          type="submit"
          value={t('Submit')}
        >
          {t('Submit')}
        </Button>
      </Form.Footer>
    </Form>
  );
};

export default LoginForm;
