import React from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { Card } from 'tabler-react';
import PropTypes from 'prop-types';
import generateCreativeIterationTitle from '../../../../utils/generateCreativeIterationTitle';
import ContentStateMarker from './ContentStateMarker';
import ContentTitle from './ContentTitle';
import NoContentPreview from './NoContentPreview';
import ContentThumbnail from './ContentThumbnail';
import styles from './ContentPreviewCard.module.css';

const ContentPreviewCard = ({ deliverable }) => {
  const {
    id,
    creativeIterations: [
      {
        media: [media],
        state,
        type,
      },
    ],
    order,
  } = deliverable;

  const [, setDeliverable] = useQueryParam('deliverable', StringParam);

  return (
    <div
      className={styles.CardButton}
      data-testid="DeliverableCardButton"
      onClick={() => setDeliverable(id)}
      onKeyPress={() => {}}
      role="button"
      tabIndex="0"
    >
      <Card className={styles.Card}>
        <ContentStateMarker state={state} />
        <ContentTitle title={generateCreativeIterationTitle(type, order)} />
        {media ? <ContentThumbnail hasLabel media={media} /> : <NoContentPreview />}
      </Card>
    </div>
  );
};

ContentPreviewCard.propTypes = {
  deliverable: PropTypes.shape({
    creativeIterations: PropTypes.arrayOf(
      PropTypes.shape({
        media: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        state: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      })
    ).isRequired,
    id: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
  }).isRequired,
};

export default ContentPreviewCard;
