import React from 'react';
import PropTypes from 'prop-types';
import { useQueryParam, StringParam } from 'use-query-params';
import { Grid } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import ShortlistedSocialProfileCard from '../ShortlistedSocialProfileCard';
import ShortlistedProfileDetailsDrawer from '../ShortlistedProfileDetailsDrawer';
import styles from './ShortlistedSocialProfilesList.module.css';

const ShortlistedSocialProfilesList = ({
  hasDemographics,
  profiles,
  viewerCanAddTalent,
  viewerCanApprove,
  viewerCanRecommend,
}) => {
  const { t } = useTranslation();
  const [detailsForProfileId, setDetailsForProfileId] = useQueryParam('details', StringParam);
  let detailsForProfile;
  if (detailsForProfileId) {
    [detailsForProfile] = profiles.filter(profile => profile.id === detailsForProfileId);
  }

  if (profiles.length === 0) {
    return (
      <Grid.Row>
        <Grid.Col>
          <p className={`text-center text-muted ${styles.NoDataLabel}`}>
            {t('Shortlist is empty')}
          </p>
        </Grid.Col>
      </Grid.Row>
    );
  }

  return (
    <>
      <ShortlistedProfileDetailsDrawer
        handleClose={() => {
          setDetailsForProfileId(null);
        }}
        hasDemographics={hasDemographics}
        isOpen={Boolean(detailsForProfileId)}
        shortlistedProfile={detailsForProfile}
      />

      <Grid.Row className={`${styles.CardsList} no-gutters justify-content-between`}>
        {profiles.map(profile => {
          return (
            <ShortlistedSocialProfileCard
              key={profile.id}
              onDetailsOpen={clickedId => setDetailsForProfileId(clickedId)}
              shortlistedProfile={profile}
              viewerCanAddTalent={viewerCanAddTalent}
              viewerCanApprove={viewerCanApprove}
              viewerCanRecommend={viewerCanRecommend}
            />
          );
        })}
      </Grid.Row>
    </>
  );
};

ShortlistedSocialProfilesList.propTypes = {
  hasDemographics: PropTypes.bool.isRequired,
  profiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  viewerCanAddTalent: PropTypes.bool.isRequired, // eslint-disable-line react/boolean-prop-naming
  viewerCanApprove: PropTypes.bool.isRequired, // eslint-disable-line react/boolean-prop-naming
  viewerCanRecommend: PropTypes.bool.isRequired, // eslint-disable-line react/boolean-prop-naming
};

export default ShortlistedSocialProfilesList;
