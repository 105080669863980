import gql from 'graphql-tag';

export default gql`
  mutation(
    $id: ID!
    $rationale: String
    $isRecommended: Boolean
    $isBooked: Boolean
    $audienceData: Upload
    $state: SocialProfileReviewState
  ) {
    updateShortlistedProfile(
      input: {
        id: $id
        rationale: $rationale
        isRecommended: $isRecommended
        isBooked: $isBooked
        audienceData: $audienceData
        state: $state
      }
    ) {
      shortlistedProfile {
        id
        state
        rationale
        isRecommended
        isBooked
      }
    }
  }
`;
