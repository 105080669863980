import React from 'react';
import PropTypes from 'prop-types';
import Humanize from 'humanize-plus';
import { useTranslation } from 'react-i18next';
import { Grid, Card, Avatar } from 'tabler-react';
import styles from './CampaignsSummary.module.css';

const CampaignsSummary = ({ campaignsSummary }) => {
  const { t } = useTranslation();

  const {
    totalCount,
    totalShortlistedProfiles,
    totalFollowerCount,
    totalDeliverablesCount,
    company: { name, logoUrl },
  } = campaignsSummary;

  return (
    <div data-testid="CampaignsSummary">
      <Card className={styles.Card}>
        <Grid.Row className="ml-0">
          <span title={name}>
            <Avatar
              className={styles.Logo}
              icon={logoUrl ? '' : 'users'}
              imageURL={logoUrl}
              size="xl"
            />
          </span>
          <span className={`${styles.ClientName} align-self-center ml-3 mb-0`} title={name}>
            {name}
          </span>
        </Grid.Row>
        <Grid.Row>
          <div className="col d-flex align-items-left flex-wrap mt-3">
            <div className="text-center mr-4 p-2">
              <div className={styles.Number}>{totalCount || '-'}</div>
              <div className={styles.Text}>{t(`Campaign${totalCount === 1 ? '' : 's'}`)}</div>
            </div>
            <div className="text-center mr-4 p-2">
              <div className={styles.Number}>{totalShortlistedProfiles || '-'}</div>
              <div className={styles.Text}>
                {t(`Influencer${totalShortlistedProfiles === 1 ? '' : 's'}`)}
              </div>
            </div>
            <div className="text-center mr-4 p-2">
              <div className={styles.Number}>{totalDeliverablesCount || '-'}</div>
              <div className={styles.Text}>
                {t(`Post${totalDeliverablesCount === 1 ? '' : 's'}`)}
              </div>
            </div>
            <div className={`${styles.VerticalLine} my-2 mr-4 ml-0`} />
            <div className="text-center mr-4 p-2">
              <div className={styles.Number}>
                {Humanize.compactInteger(totalFollowerCount, 1) || '-'}
              </div>
              <div className={styles.Text}>{t('Total Followers')}</div>
            </div>
          </div>
        </Grid.Row>
      </Card>
    </div>
  );
};

CampaignsSummary.propTypes = {
  campaignsSummary: PropTypes.shape({
    company: PropTypes.shape({ logoUrl: PropTypes.string, name: PropTypes.string }),
    totalCount: PropTypes.number,
    totalDeliverablesCount: PropTypes.number,
    totalFollowerCount: PropTypes.number,
    totalShortlistedProfiles: PropTypes.number,
  }).isRequired,
};

export default CampaignsSummary;
