import React from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { useParams, useLocation, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid } from 'tabler-react';
import Authorized from '../../../layouts/Authorized';
import Sidebar from '../../../layouts/components/Sidebar';
import MainContent from '../../../layouts/components/MainContent';
import PageHeader from '../../../layouts/components/PageHeader';
import PageActions from '../../../layouts/components/PageActions';
import ContentDetailsCard from '../../components/ContentDetailsCard';
import InfluencerContentForCampaignPageTitle from '../../components/InfluencerContentForCampaignPageTitle';
import ContentPreviewList from '../../components/ContentPreviewList/ContentPreviewList';
import InfluencerContentQueryContainer from '../../components/InfluencerContentQueryContainer/InfluencerContentQueryContainer';
import Conversation from '../../../common/components/Conversation/Conversation';
import EntriesList from '../../../common/components/Conversation/EntriesList';
import AddInfluencerContent from '../../components/AddInfluencerContent';
import AddEntryForm from '../../../common/components/Conversation/AddEntryForm';
import BetaFeature from '../../../common/components/BetaFeature';
import InfluencerContentForCampaignPageQueryContainer from '../../components/InfluencerContentForCampaignPageQueryContainer';
import styles from './InfluencerContentForCampaignPage.module.css';

const InfluencerContentForCampaignPage = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { slug, influencerId } = useParams();
  const campaignShortcode = slug.replace(/.*-(.+)$/, '$1');
  const [currentDeliverableId] = useQueryParam('deliverable', StringParam);

  return (
    <Authorized>
      <InfluencerContentForCampaignPageQueryContainer
        influencerId={influencerId}
        shortcode={campaignShortcode}
      >
        {campaign => {
          const { content, permissions } = campaign;
          const [currentDeliverable] = [
            ...content.deliverables.filter(deliverable => deliverable.id === currentDeliverableId),
            content.deliverables[0],
          ];

          if (currentDeliverable.id !== currentDeliverableId) {
            // use redirect here because otherwize we are in the way of Reacts digest cycle
            return <Redirect to={`${location.pathname}?deliverable=${currentDeliverable.id}`} />;
          }

          const {
            creativeIterations: [creativeIteration],
            order,
          } = currentDeliverable;
          const { id: creativeIterationId, type } = creativeIteration;

          return (
            <>
              <Sidebar colSize={340}>
                <ContentPreviewList content={content} />
              </Sidebar>
              <MainContent colOffsetSize={340}>
                <div className={styles.ContentContainer}>
                  <PageHeader className="d-flex align-items-start">
                    <InfluencerContentForCampaignPageTitle
                      campaign={campaign}
                      creativeIterationType={type}
                      currentDeliverable={currentDeliverable}
                      order={order}
                    />
                    <PageActions size={3}>
                      {permissions.viewerCanAddContent && (
                        <AddInfluencerContent
                          campaign={campaign}
                          influencerId={influencerId}
                          isForInfluencer
                        />
                      )}
                    </PageActions>
                  </PageHeader>
                  <Grid.Row className="h-100 justify-content-center align-items-center">
                    {content.deliverables ? (
                      <Grid.Col lg="12 d-flex justify-content-center">
                        <ContentDetailsCard
                          deliverable={currentDeliverable}
                          permissions={permissions}
                        >
                          <BetaFeature>
                            <div className="mt-3">
                              <InfluencerContentQueryContainer
                                creativeIterationId={creativeIterationId}
                              >
                                {(comments, handleAddNewEntry, addEntryError) => {
                                  return (
                                    <Conversation>
                                      <EntriesList entries={comments} onFetchMore={() => {}} />
                                      <AddEntryForm
                                        addEntryError={addEntryError}
                                        onSubmit={handleAddNewEntry}
                                      />
                                    </Conversation>
                                  );
                                }}
                              </InfluencerContentQueryContainer>
                            </div>
                          </BetaFeature>
                        </ContentDetailsCard>
                      </Grid.Col>
                    ) : (
                      <Grid.Col lg="6">
                        <p className={`text-center text-muted ${styles.NoContentLabel}`}>
                          {t('No content found')}
                        </p>
                      </Grid.Col>
                    )}
                  </Grid.Row>
                </div>
              </MainContent>
            </>
          );
        }}
      </InfluencerContentForCampaignPageQueryContainer>
    </Authorized>
  );
};

export default InfluencerContentForCampaignPage;
