import React from 'react';
import PropTypes from 'prop-types';
import InstagramDetailsTemplate from './InstagramDetails';
import YouTubeDetailsTemplate from './YouTubeDetails';
import './SocialProfileAccordionItem.css';

const SocialProfileAccordionItem = ({ profile, isSelected }) => {
  const { __typename } = profile;
  const templateMap = {
    InstagramProfile: InstagramDetailsTemplate,
    YouTubeChannel: YouTubeDetailsTemplate,
  };

  const Template = templateMap[__typename];

  return <Template isSelected={isSelected} profile={profile} />;
};

SocialProfileAccordionItem.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  profile: PropTypes.shape({
    __typename: PropTypes.oneOf(['InstagramProfile', 'YouTubeChannel']),
  }).isRequired,
};

export default SocialProfileAccordionItem;
