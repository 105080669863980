import React from 'react';
import PropTypes from 'prop-types';
import Typeahead from './Typeahead';

const PriceContentTypes = ({ onChange, placeholder, value }) => {
  const options = [
    { label: 'Feed Image', value: 'InstagramFeedImage' },
    { label: 'Feed Video', value: 'InstagramFeedVideo' },
    { label: 'Feed Carousel', value: 'InstagramFeedCarousel' },
    { label: 'Story Image', value: 'InstagramStoryImage' },
    { label: 'Story Video', value: 'InstagramStoryVideo' },
  ];

  return (
    <div className="mb-3">
      <Typeahead
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        type="select"
        value={
          value
            ? {
                value,
                label: options.filter(option => option.value === value)[0].label,
              }
            : null
        }
      />
    </div>
  );
};

PriceContentTypes.defaultProps = {
  value: '',
};

PriceContentTypes.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default PriceContentTypes;
