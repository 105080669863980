import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon } from 'tabler-react';
import Drawer from '../../../../../../layouts/components/Drawer';
import Form from './Form';

const AddToShortlist = ({ profile }) => {
  const { t } = useTranslation();
  const [createShortlistIsOpen, setCreateShortlistIsOpen] = useState(false);

  return (
    <>
      {createShortlistIsOpen &&
        ReactDOM.createPortal(
          <Drawer
            handleClose={() => setCreateShortlistIsOpen(false)}
            isOpen={createShortlistIsOpen}
          >
            <Form
              onCloseForm={() => {
                setCreateShortlistIsOpen(false);
              }}
              onSubmitSuccess={() => {
                setCreateShortlistIsOpen(false);
              }}
              profile={profile}
            />
          </Drawer>,
          document.body
        )}
      <div title={t('Add to Shortlist')}>
        <Icon
          name="plus-square"
          onClick={() => {
            setCreateShortlistIsOpen(true);
          }}
        />
      </div>
    </>
  );
};

AddToShortlist.propTypes = {
  profile: PropTypes.shape().isRequired,
};
export default AddToShortlist;
