import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import SocialProfileStat from './SocialProfileStat';

const ShortlistedSocialProfileStats = ({
  profile: { __typename, engagementRate, followerCount, subscriberCount, viewRate },
  size,
}) => {
  const { t } = useTranslation();

  if (__typename === 'InstagramProfile') {
    return (
      <>
        <Grid.Col className="col-4">
          <SocialProfileStat
            category={t('Followers')}
            isCompact
            metric={followerCount}
            size={size}
          />
        </Grid.Col>
        <Grid.Col className="col-4">
          <SocialProfileStat
            category={t('Engagement')}
            isPercentage
            metric={engagementRate}
            size={size}
          />
        </Grid.Col>
      </>
    );
  }

  return (
    <>
      <Grid.Col className="col-4">
        <SocialProfileStat
          category={t('Subscribers')}
          isCompact
          metric={subscriberCount}
          size={size}
        />
      </Grid.Col>
      <Grid.Col className="col-4">
        <SocialProfileStat category={t('View rate')} isPercentage metric={viewRate} size={size} />
      </Grid.Col>
    </>
  );
};
ShortlistedSocialProfileStats.defaultProps = {
  size: 'small',
};

ShortlistedSocialProfileStats.propTypes = {
  profile: PropTypes.shape({
    __typename: PropTypes.oneOf(['InstagramProfile', 'YouTubeChannel']).isRequired,
    engagementRate: PropTypes.number,
    followerCount: PropTypes.number,
    subscriberCount: PropTypes.number,
    viewRate: PropTypes.number,
  }).isRequired,
  size: PropTypes.oneOf(['large', 'small']),
};

export default ShortlistedSocialProfileStats;
