import React from 'react';
import { Container } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import Authorized from '../../../layouts/Authorized';
import PageHeader from '../../../layouts/components/PageHeader';
import PageActions from '../../../layouts/components/PageActions';
import PageTitle from '../../../layouts/components/PageTitle';
import ClientsList from '../../components/ClientsList/ClientsList';
import CreateClient from '../../components/CreateClient/CreateClient';
import ClientsListQueryContainer from '../../components/ClientsListQueryContainer';

const ClientsListPage = () => {
  const { t } = useTranslation();

  return (
    <Authorized>
      <ClientsListQueryContainer>
        {clients => {
          return (
            <Container>
              <PageHeader>
                <PageTitle title={t('Clients')} />
                <PageActions>
                  <CreateClient />
                </PageActions>
              </PageHeader>
              <ClientsList clients={clients} />
            </Container>
          );
        }}
      </ClientsListQueryContainer>
    </Authorized>
  );
};

export default ClientsListPage;
