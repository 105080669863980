import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Accordion from '../../../../layouts/components/Accordion';
import InfluencerInternalNotesCard from './InfluencerInternalNotesCard';
import styles from './InternalNotesAccordionItem.module.css';

const InternalNotesAccordionItem = ({ influencer, onSubmit, addEntryError }) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <Accordion
      className={{ 'card-collapsed': isCollapsed, collapsibleCard: true }}
      handleCollapse={() => setIsCollapsed(!isCollapsed)}
      header={t('Internal Notes')}
      iconName="clipboard"
      isCollapsed={isCollapsed}
    >
      <h4 className={`${styles.NoteHeading} mb-3`}>{t('Tailify influencer notes')}</h4>
      <InfluencerInternalNotesCard
        addEntryError={addEntryError}
        influencer={influencer}
        onSubmit={onSubmit}
      />
    </Accordion>
  );
};

InternalNotesAccordionItem.defaultProps = {
  addEntryError: null,
};

InternalNotesAccordionItem.propTypes = {
  addEntryError: PropTypes.shape({}),
  influencer: PropTypes.shape().isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default InternalNotesAccordionItem;
