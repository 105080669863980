import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'tabler-react';
import styles from './PageTitle.module.css';

const PageTitle = ({ children, subtitle, title }) => {
  return (
    <Grid.Col className="pl-0">
      {subtitle && <h6 className={`mb-0 ${styles.SubTitle}`}>{subtitle}</h6>}
      <h2 className={`mb-0 ${styles.Title}`} data-testid="PageTitle">
        {title}
      </h2>
      {children && <div> {children} </div>}
    </Grid.Col>
  );
};

PageTitle.defaultProps = {
  children: null,
  subtitle: '',
};

PageTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default PageTitle;
