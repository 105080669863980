import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'tabler-react';
import styles from './PageActions.module.css';

const PageActions = ({ children, size }) => {
  return (
    <Grid.Col className={`px-0 ${styles.PageActions}`} lg={size}>
      {children}
    </Grid.Col>
  );
};

PageActions.defaultProps = {
  size: 0,
};

PageActions.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  size: PropTypes.number,
};

export default PageActions;
