import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { Form as TablerForm, Button } from 'tabler-react';
import Loader from '../../../../../../layouts/components/Loader';
import HandleError from '../../../../../../layouts/components/ErrorMessage/HandleError';
import POST_ADVERT from './postAdvert.mutation';
import styles from './Form.module.css';

const Form = ({ onSubmitSuccess, creativeIterationId }) => {
  const { t } = useTranslation();
  const [url, setUrl] = useState('');
  const [messageError, setMessageError] = useState();
  const [error, setError] = useState('');

  const [postAdvert, { loading }] = useMutation(POST_ADVERT, {
    update: () => {
      onSubmitSuccess();
    },
  });

  const handleSubmit = async event => {
    event.preventDefault();
    if (url === '') {
      setMessageError(t('Please enter a URL.'));
      return;
    }
    try {
      await postAdvert({
        variables: { creativeIterationId, url },
      });
    } catch (err) {
      setError(err);
    }
  };

  return (
    <div className={styles.Form} data-testid="MarkAsPostedForm" onSubmit={handleSubmit}>
      {loading && <Loader />}
      <TablerForm>
        <h2 className="text-center w-100 pb-3">{t('Posted Here')}</h2>
        <TablerForm.Label>
          {t('URL')}
          <TablerForm.Input
            autoFocus
            className="pt-3"
            feedback={messageError}
            invalid={Boolean(messageError)}
            name="url"
            onChange={event => setUrl(event.target.value)}
            type="url"
            value={url}
          />
        </TablerForm.Label>

        <TablerForm.Footer className="mt-3 text-center">
          {error && (
            <p className="text-danger">
              <HandleError error={error} />
            </p>
          )}
          <Button block className="w-auto mx-auto px-4 py-2" color="success" type="submit">
            {t('Save')}
          </Button>
        </TablerForm.Footer>
      </TablerForm>
    </div>
  );
};

Form.propTypes = {
  creativeIterationId: PropTypes.string.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
};

export default Form;
