import React from 'react';
import { Site } from 'tabler-react';
import PropTypes from 'prop-types';
import FooterFixed from './components/FooterFixed';
import TenantLogo from './components/TenantLogo';
import styles from './Unauthorized.module.css';

const Unauthorized = ({ children }) => {
  return (
    <Site>
      <div className={`${styles.SlideDown} ${styles.LogoContainer}`}>
        <TenantLogo />
      </div>
      {children}
      <FooterFixed />
    </Site>
  );
};

Unauthorized.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Unauthorized;
