import React from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

const customStyles = {
  multiValueLabel: styles => {
    return {
      ...styles,
      color: '#a1a1a1',
      padding: '2px 2px 2px 5px',
    };
  },
  option: (styles, state) => {
    return {
      ...styles,
      color: state.isSelected ? '#FFF' : '#a1a1a1',
      backgroundColor:
        state.isSelected || state.isFocused ? 'rgba(161, 161, 161, 0.4)' : styles.backgroundColor,
      '&:hover': {
        color: '#FFF',
        backgroundColor: 'rgba(161, 161, 161, 0.4)',
      },
      fontSize: '15px',
      padding: '5px 20px',
      cursor: 'pointer',
    };
  },
  noOptionsMessage: styles => {
    return {
      ...styles,
      fontSize: '15px',
      padding: '5px 20px',
    };
  },
  control: (styles, state) => ({
    ...styles,
    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(161, 161, 161, 0.25)' : 0,
    borderColor: state.isFocused ? 'rgba(161, 161, 161, 0.25)' : 'rgb(206, 212, 218)',
    borderSize: '1px',
    borderStyle: 'solid',
    '&:hover': {
      borderColor: state.isFocused ? 'rgba(161, 161, 161, 0.25)' : 'rgb(206, 212, 218)',
    },
    cursor: 'pointer',
  }),
  placeholder: styles => ({
    ...styles,
    color: '#a1a1a1',
    fontSize: '15px',
  }),
  singleValue: styles => ({
    ...styles,
    color: '#979797',
    fontSize: '15px',
  }),
};

const Typeahead = props => {
  const { options, type } = props;
  const typeaheadTemplateMap = {
    select: Select,
    creatableSelect: CreatableSelect,
  };
  const TypeaheadTemplate = typeaheadTemplateMap[type];

  if (!options) {
    return null;
  }

  return <TypeaheadTemplate {...props} styles={customStyles} />;
};

Typeahead.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.string.isRequired })
  ).isRequired,
  type: PropTypes.oneOf(['select', 'creatableSelect', 'asyncCreatable']).isRequired,
};

export default Typeahead;
