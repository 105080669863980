import React from 'react';
import { Container, Grid } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import Authorized from '../../../layouts/Authorized';
import PageHeader from '../../../layouts/components/PageHeader';
import PageTitle from '../../../layouts/components/PageTitle';
import ContentGalleryQueryContainer from '../../components/ContentGalleryQueryContainer';
import MasonryGallery from '../../../layouts/components/MasonryGallery';
import ContentGalleryItem from '../../components/ContentGalleryItem';
import styles from './ContentGalleryPage.module.css';

const ContentGalleryPage = () => {
  const { t } = useTranslation();
  const filterBy = { states: ['POSTED'] };
  const creativeIterationState = 'POSTED';

  return (
    <Authorized>
      <ContentGalleryQueryContainer
        creativeIterationState={creativeIterationState}
        filterBy={filterBy}
      >
        {deliverables => {
          return (
            <Container className={styles.Container}>
              <PageHeader>
                <PageTitle title={t('Content')} />
              </PageHeader>

              {deliverables.length === 0 ? (
                <Grid.Row className={`${styles.EmptyMessageContainer} align-items-center`}>
                  <Grid.Col>
                    <div className="text-center text-muted">{t('No content yet')}</div>
                  </Grid.Col>
                </Grid.Row>
              ) : (
                <MasonryGallery>
                  {deliverables.map(deliverable => (
                    <ContentGalleryItem key={deliverable.id} deliverable={deliverable} />
                  ))}
                </MasonryGallery>
              )}
            </Container>
          );
        }}
      </ContentGalleryQueryContainer>
    </Authorized>
  );
};

export default ContentGalleryPage;
