import gql from 'graphql-tag';

export default gql`
  query($query: String, $firstCampaigns: Int) {
    campaigns(query: $query, first: $firstCampaigns) {
      edges {
        node {
          id
          name
          brand {
            name
          }
        }
      }
    }
  }
`;
