import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getName as getCountryName } from 'i18n-iso-countries';
import styles from './AudienceCountries.module.css';

const AudienceCountries = ({ countries }) => {
  const { t } = useTranslation();
  if (countries.length === 0) {
    return (
      <p className={`${styles.NoDataLabel} pt-4`}>
        {t('There is no audience countries data available.')}
      </p>
    );
  }

  const highestValue = Math.max(...countries.map(({ value }) => value));
  const topThreeCountries = countries
    .sort((current, next) => next.value - current.value)
    .slice(0, 3);
  return (
    <div>
      {topThreeCountries.map(({ value, country }) => (
        <div key={country} className="pb-2">
          <div
            className={styles.Bar}
            style={{
              width:
                value === highestValue ? '100%' : `${((value * 100) / highestValue).toString()}%`,
            }}
          />
          <div className={styles.Label}>
            <span>
              {getCountryName(country, 'en')} - {value}%
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

AudienceCountries.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      country: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default AudienceCountries;
