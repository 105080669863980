import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactDataGrid from 'react-data-grid';
import { useMutation } from '@apollo/react-hooks';
import UPDATE_SOCIAL_PROFILE from '../updateSocialProfile.mutation';
import './TableView.css';
import getColumnConfig from './getColumnConfig';
import getProfilePreparator from './getProfilePreparator';
import executeUpdate from './executeUpdate';

const TableView = ({ profiles, platform, onClick }) => {
  const { t } = useTranslation();

  const preparator = getProfilePreparator({ platform, t });
  const columnsConfig = getColumnConfig({ onClick, t, platform });
  const [updateSocialProfile] = useMutation(UPDATE_SOCIAL_PROFILE);

  const onGridRowsUpdated = ({ fromRow, updated }) => {
    executeUpdate(fromRow, updated, updateSocialProfile, profiles);
  };

  return (
    <ReactDataGrid
      columns={columnsConfig}
      enableCellSelect
      enableRowSelect={null}
      minHeight={(profiles.length + 1) * 45}
      onGridRowsUpdated={onGridRowsUpdated}
      rowGetter={index => {
        if (index < 0) {
          return {};
        }

        return preparator(profiles[index]);
      }}
      rowHeight={45}
      rowsCount={profiles.length}
      rowScrollTimeout={null}
    />
  );
};

TableView.propTypes = {
  onClick: PropTypes.func.isRequired,
  platform: PropTypes.oneOf(['INSTAGRAM', 'YOUTUBE']).isRequired,
  profiles: PropTypes.arrayOf(
    PropTypes.shape({
      engagementRate: PropTypes.number,
      followerCount: PropTypes.number,
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      subscriberCount: PropTypes.number,
      title: PropTypes.string,
      url: PropTypes.string,
      username: PropTypes.string,
    })
  ).isRequired,
};

export default TableView;
