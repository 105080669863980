import React from 'react';
import { Form } from 'tabler-react';
import PropTypes from 'prop-types';

const AgencyFilter = ({ agency, onApplyFilters }) => {
  return (
    <div data-testid="managedByAgencyFilter">
      <Form.Group>
        <Form.Switch
          checked={agency}
          className="p-0 c-pointer"
          label="Managed by agency"
          name="managedByAgency"
          onChange={() => onApplyFilters({ agency: !agency })}
          value={agency}
        />
      </Form.Group>
    </div>
  );
};

AgencyFilter.defaultProps = {
  agency: false,
};

AgencyFilter.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  agency: PropTypes.bool,
  onApplyFilters: PropTypes.func.isRequired,
};

export default AgencyFilter;
