import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import Drawer from '../../../layouts/components/Drawer';
import Form from './Form';

const UploadReport = ({ campaign, className }) => {
  const { t } = useTranslation();
  const [uploadReportFormIsOpen, setUploadReportFormIsOpen] = useState(false);
  return (
    <>
      <Drawer handleClose={() => setUploadReportFormIsOpen(false)} isOpen={uploadReportFormIsOpen}>
        {uploadReportFormIsOpen && (
          <Form
            campaign={campaign}
            onSubmitSuccess={() => {
              setUploadReportFormIsOpen(false);
            }}
          />
        )}
      </Drawer>
      <Button
        className={`${className} text-center w-100`}
        color="primary-light"
        onClick={() => setUploadReportFormIsOpen(true)}
      >
        {t('Upload Report')}
      </Button>
    </>
  );
};

UploadReport.defaultProps = {
  className: '',
};

UploadReport.propTypes = {
  campaign: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
};

export default UploadReport;
