import React from 'react';
import { Grid } from 'tabler-react';
import PropTypes from 'prop-types';
import { CREATIVE_ITERATION_STATES } from '../../../../../constants';
import styles from './ContentStateMarker.module.css';

const ContentStateMarker = ({ state }) => {
  let statusClassName;
  switch (state) {
    case CREATIVE_ITERATION_STATES.POSTED:
      statusClassName = styles.Posted;
      break;
    case CREATIVE_ITERATION_STATES.APPROVED:
      statusClassName = styles.Approved;
      break;
    case CREATIVE_ITERATION_STATES.PENDING:
      statusClassName = styles.Pending;
      break;
    case CREATIVE_ITERATION_STATES.CHANGES_REQUESTED:
      statusClassName = styles.ChangesRequested;
      break;
    default:
      statusClassName = styles.Pending;
      break;
  }

  return (
    <Grid.Row className={styles.StateMarker}>
      <Grid.Col className="px-1">
        <div className={`w-100 mx-auto my-0 ${styles.State} ${statusClassName}`} />
      </Grid.Col>
    </Grid.Row>
  );
};

ContentStateMarker.propTypes = {
  state: PropTypes.oneOf(Object.values(CREATIVE_ITERATION_STATES)).isRequired,
};

export default ContentStateMarker;
