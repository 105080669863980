import React from 'react';
import { Container, Grid } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import Unauthorized from '../../../layouts/Unauthorized';
import styles from './ForbiddenPage.module.css';

const ForbiddenPage = () => {
  const { t } = useTranslation();

  return (
    <Unauthorized>
      <Container className="h-100">
        <Grid.Row className="h-100 align-items-center justify-content-center">
          <Grid.Col>
            <h1 className={`${styles.ErrorCode} text-center`}>{t('403')}</h1>
            <p className={`${styles.ErrorMessage} text-center font-italic`}>{t('Forbidden')}</p>
          </Grid.Col>
        </Grid.Row>
      </Container>
    </Unauthorized>
  );
};

export default ForbiddenPage;
