import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { Form as TablerForm, Button } from 'tabler-react';
import Loader from '../../../../layouts/components/Loader';
import GET_CAMPAIGN_SHORTLIST from '../../CampaignShortlistQueryContainer/campaignShortlist.query';
import HandleError from '../../../../layouts/components/ErrorMessage/HandleError';
import ADD_TO_SHORTLIST from './addToShortlist.mutation';
import styles from './Form.module.css';

const Form = ({ campaignId, onSubmitSuccess, viewerCanRecommend }) => {
  const { t } = useTranslation();
  const [url, setUrl] = useState('');
  const [rationale, setRationale] = useState('');
  const [isRecommended, setIsRecommended] = useState(false);
  const [error, setError] = useState('');

  const [urlError, setUrlError] = useState();
  const { slug } = useParams();
  const shortcode = slug.split('-').pop();

  const [addToShortlist, { loading }] = useMutation(ADD_TO_SHORTLIST, {
    update: () => {
      onSubmitSuccess();
    },
    refetchQueries: [
      {
        query: GET_CAMPAIGN_SHORTLIST,
        variables: { shortcode },
      },
    ],
  });

  const handleSubmit = async event => {
    event.preventDefault();
    if (!url) {
      setUrlError(t('Please enter URL.'));
      return;
    }
    try {
      await addToShortlist({
        variables: {
          campaignId,
          identifier: url,
          rationale,
          isRecommended,
        },
      });
    } catch (err) {
      setError(err);
    }
  };

  return (
    <div className={styles.Form} data-testid="ShortlistSocialProfileForm">
      {loading && <Loader />}
      <TablerForm onSubmit={handleSubmit}>
        <h2 className="text-center w-100 pb-3">{t('Add Influencer')}</h2>
        <TablerForm.Label>
          {t('Instagram or YouTube URL')}
          <TablerForm.Input
            className="pt-3"
            cross={false}
            feedback={urlError}
            invalid={Boolean(urlError)}
            name="url"
            onChange={event => setUrl(event.target.value)}
            value={url}
          />
        </TablerForm.Label>
        <TablerForm.Label>
          {t('Info')}
          <TablerForm.Textarea
            className="pt-3"
            name="rationale"
            onChange={event => setRationale(event.target.value)}
          />
        </TablerForm.Label>
        {viewerCanRecommend && (
          <TablerForm.Checkbox
            checked={isRecommended}
            label={t('Recommend influencer')}
            name="isRecommended"
            onChange={event => setIsRecommended(event.target.checked)}
          />
        )}
        <TablerForm.Footer className="mt-3 text-center">
          {error && (
            <p className="text-danger" data-testid="Error">
              <HandleError error={error} />
            </p>
          )}
          <Button
            block
            className="w-auto mx-auto px-4 py-2"
            color="success"
            disabled={loading}
            type="submit"
          >
            {t('Add')}
          </Button>
        </TablerForm.Footer>
      </TablerForm>
    </div>
  );
};

Form.propTypes = {
  campaignId: PropTypes.string.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  viewerCanRecommend: PropTypes.bool.isRequired, // eslint-disable-line react/boolean-prop-naming
};

export default Form;
