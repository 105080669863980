import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from 'tabler-react';
import Drawer from '../../../layouts/components/Drawer';
import { generateShortlistUrl } from '../../../utils/helpers';
import Form from './Form';

const CreateShortlist = ({ campaign, className }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [createShortlistIsOpen, setCreateShortlistIsOpen] = useState(false);

  return (
    <>
      <Drawer
        handleClose={() => {
          setCreateShortlistIsOpen(false);
        }}
        isOpen={createShortlistIsOpen}
      >
        {createShortlistIsOpen && (
          <Form
            campaign={campaign}
            onCloseForm={() => {
              setCreateShortlistIsOpen(false);
            }}
            onSubmitSuccess={() => {
              setCreateShortlistIsOpen(false);
              history.push(generateShortlistUrl(campaign));
            }}
          />
        )}
      </Drawer>

      <Button
        block
        className={`${className} text-center w-100`}
        color="primary-light"
        onClick={() => setCreateShortlistIsOpen(true)}
      >
        {t('Add Shortlist')}
      </Button>
    </>
  );
};

CreateShortlist.defaultProps = {
  className: '',
};

CreateShortlist.propTypes = {
  campaign: PropTypes.shape().isRequired,
  className: PropTypes.string,
};

export default CreateShortlist;
