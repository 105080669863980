import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Container } from 'tabler-react';
import nl2br from 'react-newline-to-break';
import ContentMedia from '../ContentMedia';
import styles from './YoutubeVideoTemplate.module.css';

const YoutubeVideoTemplate = ({ children, deliverable }) => {
  const {
    creativeIterations: [{ title, media, caption }],
    socialProfile: { title: socialProfileTitle },
  } = deliverable;

  return (
    <div className={`mt-2 ${styles.Template}`}>
      <div className={`mt-2 ${styles.YoutubeCardContainer}`}>
        <ContentMedia media={media} />
        <Container>
          <Grid.Row className="pt-3 px-3">
            <h2 className={styles.YoutubeCardHeaderTitle}>{title}</h2>
          </Grid.Row>

          <Grid.Row className={`mx-1 pb-3 pt-2 ${styles.YoutubeCardBorder}`}>
            <Grid.Col className={`col-2 ${styles.YoutubeCardFadedText}`} />
            <Grid.Col className={`col-2 offset-7 ${styles.YoutubeCardFadedText}`} />
            <Grid.Col className="px-0 d-flex justify-content-end align-items-center" lg="1">
              <div className={`float-left ${styles.YoutubeCardDotAction}`} />
              <div className={`float-left ml-1 ${styles.YoutubeCardDotAction}`} />
              <div className={`float-left ml-1 ${styles.YoutubeCardDotAction}`} />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row className="pt-3">
            <Grid.Col width="1">
              <div className={`${styles.YoutubeCardAvatar}`} />
            </Grid.Col>
            <Grid.Col className="ml-3">
              <Grid.Row>
                <Grid.Col>
                  <div className={`${styles.YoutubeCardAvatarTitle}`}>{socialProfileTitle}</div>
                  <div className={`col-3 ${styles.YoutubeCardFadedText}`} />
                </Grid.Col>
                <Grid.Col className={`col-2 mr-3 ${styles.YoutubeCardFadedButton}`} offset="1" />
              </Grid.Row>
              <Grid.Row className="mt-4 pb-4">
                <Grid.Col>
                  <p className={styles.YoutubeCardDescription}>{nl2br(caption)}</p>
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
          </Grid.Row>
        </Container>
      </div>
      {children}
    </div>
  );
};

YoutubeVideoTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  deliverable: PropTypes.shape({
    creativeIterations: PropTypes.arrayOf(
      PropTypes.shape({
        caption: PropTypes.string,
        media: PropTypes.array,
        title: PropTypes.string,
      })
    ),
    socialProfile: PropTypes.shape({
      title: PropTypes.string,
    }),
  }).isRequired,
};

export default YoutubeVideoTemplate;
