import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { Form as TablerForm, Button } from 'tabler-react';
import { CREATIVE_ITERATION_STATES } from '../../../../../../constants';
import UPDATE_CREATIVE_ITERATION_STATE from '../../updateCreativeIterationState.mutation';
import Loader from '../../../../../../layouts/components/Loader';
import HandleError from '../../../../../../layouts/components/ErrorMessage/HandleError';
import styles from './Form.module.css';

const Form = ({ creativeIteration }) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState('');
  const [messageError, setMessageError] = useState();
  const [updateCreativeIterationState, { loading }] = useMutation(UPDATE_CREATIVE_ITERATION_STATE);
  const [error, setError] = useState('');

  const handleSubmit = async event => {
    event.preventDefault();
    if (!comment) {
      setMessageError(t('Please enter your request.'));
      return;
    }

    try {
      await updateCreativeIterationState({
        variables: {
          creativeIterationId: creativeIteration.id,
          state: CREATIVE_ITERATION_STATES.CHANGES_REQUESTED,
          comment,
        },
      });
    } catch (err) {
      setError(err);
    }
  };

  return (
    <div data-testid="RequestChangesForm">
      {/* have this here so we don't reset the form after submit */}
      {loading && <Loader />}
      <TablerForm className={styles.Form} onSubmit={handleSubmit}>
        <h2 className="text-center w-100 pb-3">{t('Request Changes')}</h2>
        <TablerForm.Label>
          {t('Comment')}
          <TablerForm.Textarea
            className={`TestRequestChangesTextArea pt-3 ${styles.TextArea}`}
            cross={false}
            feedback={messageError}
            invalid={Boolean(messageError)}
            name="url"
            onChange={event => setComment(event.target.value)}
            value={comment}
          />
        </TablerForm.Label>
        <TablerForm.Footer className="mt-3 text-center">
          {error && (
            <p className="text-danger">
              <HandleError error={error} />
            </p>
          )}
          <Button
            block
            className={`TestRequestChangesSubmitButton w-auto mx-auto px-4 py-2 ${styles.SubmitButton}`}
            color="primary-light"
            type="submit"
          >
            {t('Request Changes')}
          </Button>
        </TablerForm.Footer>
      </TablerForm>
    </div>
  );
};

Form.propTypes = {
  creativeIteration: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default Form;
