import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '../../../layouts/components/Drawer';
import Details from './Details';

const ShortlistedProfileDetailsDrawer = ({
  handleClose,
  hasDemographics,
  isOpen,
  shortlistedProfile,
}) => {
  return (
    <Drawer handleClose={handleClose} isOpen={isOpen}>
      {isOpen && (
        <Details hasDemographics={hasDemographics} shortlistedProfile={shortlistedProfile} />
      )}
    </Drawer>
  );
};

ShortlistedProfileDetailsDrawer.defaultProps = {
  shortlistedProfile: null,
};

ShortlistedProfileDetailsDrawer.propTypes = {
  handleClose: PropTypes.func.isRequired,
  hasDemographics: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  shortlistedProfile: PropTypes.shape(),
};

export default ShortlistedProfileDetailsDrawer;
