import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import Loader from '../../../layouts/components/Loader';
import ErrorMessage from '../../../layouts/components/ErrorMessage';
import GET_CAMPAIGN_SHORTLIST from './campaignShortlist.query';

const CampaignShortlistQueryContainer = ({ shortcode, children }) => {
  const { loading, error, data } = useQuery(GET_CAMPAIGN_SHORTLIST, {
    variables: {
      shortcode,
    },
    fetchPolicy: 'network-only',
  });

  if (error) return <ErrorMessage error={error} />;

  if (loading) return <Loader />;

  const { campaign } = data;

  const {
    shortlists: [shortlist],
  } = campaign;
  const {
    profiles: { edges: profiles },
  } = shortlist;

  return (
    <div className="scrollableContainer h-100" data-testid="ScrollableContainer">
      {children({
        campaign,
        shortlist,
        profiles,
      })}
    </div>
  );
};

CampaignShortlistQueryContainer.propTypes = {
  children: PropTypes.func.isRequired,
  shortcode: PropTypes.string.isRequired,
};

export default CampaignShortlistQueryContainer;
