import React from 'react';
import { Grid } from 'tabler-react';
import PropTypes from 'prop-types';
import InfluencerWidget from '../../../influencers/components/InfluencerWidget/InfluencerWidget';
import ContentPreviewCard from './ContentPreviewCard/ContentPreviewCard';
import styles from './ContentPreviewList.module.css';

const ContentPreviewList = ({ content }) => {
  const { deliverables, influencer } = content;
  const [
    {
      socialProfile: { username },
    },
  ] = deliverables;

  return (
    <div className={styles.ContentPreviewList} data-testid="ContentPreviewList">
      <Grid.Row>
        <Grid.Col>
          <InfluencerWidget imageSize={60} influencer={{ username, ...influencer }} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col>
          <h4 className={styles.ContentTitle}>Influencer Content</h4>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        {deliverables.map(deliverable => (
          <Grid.Col key={deliverable.id} className="col-12">
            <ContentPreviewCard deliverable={deliverable} />
          </Grid.Col>
        ))}
      </Grid.Row>
    </div>
  );
};

ContentPreviewList.propTypes = {
  content: PropTypes.shape({
    deliverables: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    influencer: PropTypes.shape().isRequired,
  }).isRequired,
};

export default ContentPreviewList;
