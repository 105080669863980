import React from 'react';
import PropTypes from 'prop-types';
import Humanize from 'humanize-plus';
import styles from './SocialProfileStat.module.css';

const SocialProfileStat = ({ metric, category, isCompact, isPercentage, size }) => {
  let displayMetric;

  if (isCompact) {
    displayMetric = metric === null ? '-' : Humanize.compactInteger(metric, 1);
  } else {
    displayMetric = metric ? metric.toFixed(2) : '-';
  }
  return (
    <>
      <div
        className={`${styles.StatMetric} ${metric && isPercentage ? styles.Percentage : ''} ${
          styles[size]
        }`}
      >
        {displayMetric}
      </div>
      <div className={`${styles.StatCategory} ${styles[size]}`}>{category}</div>
    </>
  );
};

SocialProfileStat.defaultProps = {
  isCompact: false,
  isPercentage: false,
  metric: null,
  size: 'small',
};

SocialProfileStat.propTypes = {
  category: PropTypes.string.isRequired,
  isCompact: PropTypes.bool,
  isPercentage: PropTypes.bool,
  metric: PropTypes.number,
  size: PropTypes.oneOf(['small', 'large']),
};
export default SocialProfileStat;
