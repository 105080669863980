import React from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Grid } from 'tabler-react';
import Unauthorized from '../../../layouts/Unauthorized';
import LoginForm from '../../components/LoginForm';
import viewerUtils from '../../../utils/viewer';

import 'tabler-react/dist/Tabler.css';

const LoginPage = () => {
  if (viewerUtils.isAuthenticated()) {
    return (
      <Redirect
        to={{
          pathname: '/campaigns',
        }}
      />
    );
  }

  return (
    <Unauthorized>
      <Container className="h-100">
        <Grid.Row className="h-100 align-items-center justify-content-center">
          <Grid.Col lg={4} md={6}>
            <LoginForm />
          </Grid.Col>
        </Grid.Row>
      </Container>
    </Unauthorized>
  );
};

export default LoginPage;
