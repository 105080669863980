import React from 'react';
import { Grid, Container } from 'tabler-react';
import Authorized from '../../../layouts/Authorized';
import AccountSettingsForm from '../../components/AccountSettingsForm';

const AccountPage = () => {
  return (
    <Authorized>
      <Container>
        <Grid.Row className="mt-5 justify-content-center align-items-center">
          <Grid.Col className="d-flex " data-testid="AccountForm" sm="6">
            <AccountSettingsForm />
          </Grid.Col>
        </Grid.Row>
      </Container>
    </Authorized>
  );
};

export default AccountPage;
