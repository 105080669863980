import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import Loader from '../../../layouts/components/Loader';
import ErrorMessage from '../../../layouts/components/ErrorMessage';
import GET_CAMPAIGNS_SUMMARY from './campaignsSummary.query';

const CampaignsSummaryQueryContainer = ({ children }) => {
  const { loading, error, data } = useQuery(GET_CAMPAIGNS_SUMMARY, { fetchPolicy: 'network-only' });

  if (error) return <ErrorMessage error={error} />;

  if (loading) return <Loader />;

  const { campaignsSummary } = data;

  return <div className="h-100">{children(campaignsSummary)}</div>;
};

CampaignsSummaryQueryContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default CampaignsSummaryQueryContainer;
