import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'tabler-react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InfluencerContentCard from '../InfluencerContentCard';
import styles from './ContentSummaryList.module.css';

const ContentSummaryList = ({ contentSummary }) => {
  const { t } = useTranslation();
  const { slug } = useParams();

  if (contentSummary.length === 0) {
    return (
      <Grid.Row className={`${styles.EmptyMessageContainer} align-items-center`}>
        <Grid.Col>
          <div className="text-center text-muted">{t('No content yet')}</div>
        </Grid.Col>
      </Grid.Row>
    );
  }

  return (
    <Grid.Row>
      {contentSummary.map(content => {
        const {
          influencer: { id },
        } = content;

        return (
          <Grid.Col key={id} lg="6" md="12" sm="12" width="12" xs="12">
            <Link to={`/campaigns/${slug}/content/${id}`}>
              <InfluencerContentCard content={content} />
            </Link>
          </Grid.Col>
        );
      })}
    </Grid.Row>
  );
};

ContentSummaryList.propTypes = {
  contentSummary: PropTypes.arrayOf(
    PropTypes.shape({
      influencer: PropTypes.shape({
        id: PropTypes.string,
      }),
    })
  ).isRequired,
};

export default ContentSummaryList;
