import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, Button } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import styles from './ClientCard.module.css';

const ClientCard = ({ client }) => {
  const { t } = useTranslation();

  return (
    <div data-testid="ClientCard">
      <Card className={styles.Card}>
        <p className={`${styles.Title} mb-0`}>{client.name}</p>
        <Grid.Row className="d-flex justify-content-between">
          <Grid.Col className="mt-3 col-4">
            <Button className={`${styles.Button} w-100`} color="primary">
              {t('Edit members')}
            </Button>
          </Grid.Col>
        </Grid.Row>
      </Card>
    </div>
  );
};

ClientCard.propTypes = {
  client: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
};

export default ClientCard;
