import gql from 'graphql-tag';

export default gql`
  mutation ShowToReviewer($creativeIterationId: ID!, $reviewerHasAccess: Boolean!) {
    showToReviewer(
      input: { creativeIterationId: $creativeIterationId, reviewerHasAccess: $reviewerHasAccess }
    ) {
      creativeIteration {
        id
        state
        caption
        reviewerHasAccess
        type
        media {
          id
          path
          type
        }
      }
    }
  }
`;
