import gql from 'graphql-tag';
import {
  InstagramProfile,
  YouTubeProfile,
} from '../ShortlistedSocialProfileCard/ShortlistedSocialProfileCard.fragment';

export default gql`
  query CampaignShortlist($shortcode: String!) {
    campaign(shortcode: $shortcode) {
      id
      name
      brand {
        name
      }
      shortlists {
        id
        name
        stats {
          approvedProfilesCount
          totalProfilesCount
        }
        profiles {
          edges {
            id
            isRecommended
            isBooked
            rationale
            reviewedAt
            state
            reviewedBy {
              id
              name
            }
            rejectionReason
            node {
              ...InstagramProfile
              ...YouTubeProfile
            }
          }
        }
        hasDemographicsEnabled
        permissions {
          viewerCanAdd
          viewerCanApprove
          viewerCanRecommend
        }
      }
    }
  }
  ${InstagramProfile}
  ${YouTubeProfile}
`;
