import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import logout from '../../../utils/logout';
import styles from './ErrorMessage.module.css';
import HandleError from './HandleError';

const ErrorMessage = ({ error }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleLogout = () => {
    logout();
    history.push('/login');
  };

  return (
    <div className="row h-100 align-items-center justify-content-center">
      <div className="col">
        <p className="text-center text-muted">
          <HandleError error={error} />
          <button className={styles.LoginButton} onClick={handleLogout} type="button">
            {t('Try logging in again.')}
          </button>
        </p>
      </div>
    </div>
  );
};

ErrorMessage.propTypes = {
  error: PropTypes.shape({
    isAxiosError: PropTypes.bool,
    message: PropTypes.string.isRequired,
    networkError: PropTypes.shape({ statusCode: PropTypes.number }),
    response: PropTypes.shape({ status: PropTypes.number }),
  }).isRequired,
};

export default ErrorMessage;
