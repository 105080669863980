import PropTypes from 'prop-types';

const PercentageFormatter = ({ value }) => {
  return value && `${value.toFixed(2)}%`;
};

PercentageFormatter.propTypes = {
  value: PropTypes.number,
};

export default PercentageFormatter;
