import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'tabler-react';
import Drawer from '../../../../../layouts/components/Drawer';
import IGForm from './IGForm';
import YTForm from './YTForm';

const EditContent = ({ deliverableType, creativeIteration }) => {
  const { t } = useTranslation();
  const [editContentFormIsOpen, setEditContentFormIsOpen] = useState(false);
  return (
    <>
      <Drawer
        handleClose={() => {
          setEditContentFormIsOpen(false);
        }}
        isOpen={editContentFormIsOpen}
      >
        {editContentFormIsOpen &&
          (deliverableType === 'VIDEO' ? (
            <YTForm
              creativeIteration={creativeIteration}
              onSubmitSuccess={() => {
                setEditContentFormIsOpen(false);
              }}
            />
          ) : (
            <IGForm
              creativeIteration={creativeIteration}
              deliverableType={deliverableType}
              onSubmitSuccess={() => {
                setEditContentFormIsOpen(false);
              }}
            />
          ))}
      </Drawer>
      <Button
        className="text-center w-100"
        color="primary-light"
        onClick={() => setEditContentFormIsOpen(true)}
      >
        {t('Edit Content')}
      </Button>
    </>
  );
};

EditContent.propTypes = {
  creativeIteration: PropTypes.shape({}).isRequired,
  deliverableType: PropTypes.string.isRequired,
};

export default EditContent;
