import gql from 'graphql-tag';
import InstagramDetails from '../InfluencerProfileDetailsDrawer/SocialProfileAccordionItem/InstagramDetails/InstagramDetails.fragment';
import YouTubeDetails from '../InfluencerProfileDetailsDrawer/SocialProfileAccordionItem/YouTubeDetails/YouTubeDetails.fragment';
import ConversationEntry from '../../../common/components/Conversation/ConversationEntry';

export default gql`
  query influencerProfileDetails($id: ID!, $firstNotes: Int) {
    influencer(id: $id) {
      id
      name
      biography
      pictureUrl
      email
      country
      gender
      profiles {
        ...InstagramDetails
        ...YouTubeDetails
        __typename
      }
      comments(first: $firstNotes) {
        ... on CommentConnection {
          edges {
            node {
              ...ConversationEntry
            }
          }
        }
      }
    }
  }
  ${InstagramDetails}
  ${YouTubeDetails}
  ${ConversationEntry.fragments.conversationEntry}
`;
