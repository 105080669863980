import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getName as getCountryName } from 'i18n-iso-countries';
import styles from './InfluencerGenderAndLocation.module.css';

const InfluencerGenderAndLocation = ({ influencer: { country, gender } }) => {
  const { t } = useTranslation();
  const genderLabels = { MALE: 'Male', FEMALE: 'Female' };
  return (
    <p className={`mb-0 ${styles.Info}`}>
      {country && <span>{getCountryName(country, 'en')}</span>}
      {gender && <span>{t(genderLabels[gender])}</span>}
    </p>
  );
};

InfluencerGenderAndLocation.propTypes = {
  influencer: PropTypes.shape({
    country: PropTypes.string,
    gender: PropTypes.oneOf(['MALE', 'FEMALE']),
  }).isRequired,
};

export default InfluencerGenderAndLocation;
