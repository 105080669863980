import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './BottomBar.module.css';

const BottomBar = ({ profiles, totalCount }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.BottomBar}>
      <div
        className={styles.TotalCount}
        title={t(`Viewing ${profiles.length} / Total ${totalCount}`)}
      >{`${profiles.length} / ${totalCount}`}</div>
    </div>
  );
};

BottomBar.propTypes = {
  profiles: PropTypes.arrayOf(
    PropTypes.shape({
      length: PropTypes.number,
    })
  ).isRequired,
  totalCount: PropTypes.number.isRequired,
};
export default BottomBar;
