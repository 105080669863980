import React from 'react';
import PropTypes from 'prop-types';
import styles from './MainContent.module.css';

const MainContent = ({ children, colOffsetSize, className }) => (
  <div
    className={`${styles.MainContent} ${className}`}
    style={{ marginLeft: `${colOffsetSize}px` }}
  >
    {children}
  </div>
);

MainContent.defaultProps = {
  className: '',
  colOffsetSize: 300,
};

MainContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  colOffsetSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default MainContent;
