import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import LoginPage from './users/pages/LoginPage';
import PrivateRoute from './routes/components/PrivateRoute';
import {
  CampaignsListPage,
  CampaignShortlistPage,
  CampaignContentSummaryPage,
  InfluencerContentForCampaignPage,
} from './campaigns/pages';
import ContentGalleryPage from './content/pages/ContentGalleryPage';
import {
  InfluencersListPage,
  InfluencerProfilePage,
  InfluencerNotesPage,
  InfluencerCampaignsPage,
} from './influencers/pages';
import { ClientsListPage, EditMembersPage } from './clients/pages';
import NotFoundPage from './errors/pages/NotFoundPage';
import AccountPage from './users/pages/AccountPage';
import ForbiddenPage from './errors/pages/ForbiddenPage';
import './i18n';
import './App.css';
import './theme.scss';

const App = () => {
  return (
    <QueryParamProvider ReactRouterRoute={Route}>
      <Switch>
        <Route component={LoginPage} exact path="/login" />
        <PrivateRoute exact path="/">
          <Redirect to="/campaigns" />
        </PrivateRoute>
        <PrivateRoute exact path="/campaigns">
          <CampaignsListPage />
        </PrivateRoute>
        <PrivateRoute exact path="/campaigns/:slug/content">
          <CampaignContentSummaryPage />
        </PrivateRoute>
        <PrivateRoute exact path="/campaigns/:slug/shortlist">
          <CampaignShortlistPage />
        </PrivateRoute>
        <PrivateRoute exact path="/campaigns/:slug/content/:influencerId">
          <InfluencerContentForCampaignPage />
        </PrivateRoute>
        <PrivateRoute exact path="/content">
          <ContentGalleryPage />
        </PrivateRoute>
        <PrivateRoute exact path="/influencers">
          <InfluencersListPage />
        </PrivateRoute>
        <PrivateRoute exact path="/influencers/:id">
          <InfluencerProfilePage />
        </PrivateRoute>
        <PrivateRoute exact path="/influencers/:id/notes">
          <InfluencerNotesPage />
        </PrivateRoute>
        <PrivateRoute exact path="/influencers/:id/campaigns">
          <InfluencerCampaignsPage />
        </PrivateRoute>
        <PrivateRoute exact path="/settings/account">
          <AccountPage />
        </PrivateRoute>
        <PrivateRoute exact path="/clients">
          <ClientsListPage />
        </PrivateRoute>
        <PrivateRoute exact path="/clients/:id/members">
          <EditMembersPage />
        </PrivateRoute>
        <Route exact path="/error/404">
          <NotFoundPage />
        </Route>
        <Route exact path="/error/403">
          <ForbiddenPage />
        </Route>
        <Route path="*">
          <Redirect to="/error/404" />
        </Route>
      </Switch>
    </QueryParamProvider>
  );
};

export default App;
