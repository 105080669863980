import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Redirect } from 'react-router-dom';
import logout from '../../../../utils/logout';

const HandleError = ({ error }) => {
  // console.log(error);

  const location = useLocation();

  const { t } = useTranslation();

  let networkError;

  if (error.isAxiosError) {
    networkError = { statusCode: error.response.status };
  } else {
    ({ networkError } = error);
  }

  if (networkError && 'statusCode' in networkError && networkError.statusCode === 401) {
    logout();
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    );
  }

  let errorMessage;
  // handle old API
  const splitted = error.message.split(':');
  errorMessage = `${splitted[0]}.`;

  // handle new API
  if (splitted[1]) {
    [, errorMessage] = splitted;
  }

  return t(errorMessage);
};

export default HandleError;
