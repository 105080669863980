import React from 'react';
import PropTypes from 'prop-types';
import { Card, Icon, Grid } from 'tabler-react';
import './Accordion.css';

const Accordion = ({
  className,
  children,
  iconName,
  handleCollapse,
  header,
  handle,
  isCollapsed,
  isSelected,
  url,
  iconPrefix,
}) => {
  return (
    <Card className={className}>
      <Card.Header>
        <span
          className={`container ${isSelected && 'selected'}`}
          onClick={handleCollapse}
          onKeyPress={() => {}}
          role="button"
          tabIndex="0"
        >
          <Grid.Row className="pl-3 pr-3">
            <Grid.Col className="col-1 pl-0">
              <Icon className="profileIcon" name={iconName} prefix={iconPrefix} />
            </Grid.Col>
            <Grid.Col className="col-10 pl-0">
              <h5 className="profileTitle m-0">{header}</h5>
              {handle && (
                <a className="profileHandle" href={url} rel="noopener noreferrer" target="_blank">
                  {handle}
                </a>
              )}
            </Grid.Col>
            <Grid.Col className="col-1 pt-1 pr-0">
              <Icon name={`${isCollapsed ? 'chevron-down' : 'chevron-up'}`} />
            </Grid.Col>
          </Grid.Row>{' '}
        </span>
      </Card.Header>
      <Card.Body>{children}</Card.Body>
    </Card>
  );
};

Accordion.defaultProps = {
  handle: '',
  iconPrefix: 'fe',
  isSelected: false,
  url: '',
};

Accordion.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.shape().isRequired,
  handle: PropTypes.string,
  handleCollapse: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  iconPrefix: PropTypes.string,
  isCollapsed: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool,
  url: PropTypes.string,
};

export default Accordion;
