import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'tabler-react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './ResetFilters.module.css';

const ResetFilters = ({ platform, onResetFilters }) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const isDisabled = search === '?platform=INSTAGRAM' || search === '?platform=YOUTUBE';

  return (
    <Button
      className={`${styles.ResetFiltersButton} w-100`}
      color={isDisabled ? 'primary-light' : 'primary'}
      disabled={isDisabled}
      onClick={() => {
        onResetFilters(platform);
      }}
    >
      {t('Reset')}
    </Button>
  );
};

ResetFilters.propTypes = {
  onResetFilters: PropTypes.func.isRequired,
  platform: PropTypes.string.isRequired,
};

export default ResetFilters;
