import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './QueryFilter.module.css';

const QueryFilter = ({ query, onApplyFilters }) => {
  const { t } = useTranslation();
  const [typingTimeout, setTypingTimeout] = useState();
  const [searchTerm, setSearchTerm] = useState(query);

  useEffect(() => {
    setSearchTerm(query);
  }, [query]);

  const handleKeyUp = ({ keyCode }) => {
    if (keyCode === 13) {
      onApplyFilters({ query: searchTerm });
    }

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        onApplyFilters({ query: searchTerm });
      }, 500)
    );
  };

  return (
    <div className={`form-group ${styles.QueryFilter}`}>
      <div className="input-icon">
        <span className="input-icon-addon">
          <i className="fe fe-search" />
        </span>
        <input
          autoFocus
          className={`form-control ${styles.SearchInput}`}
          onChange={({ target }) => setSearchTerm(target.value)}
          onKeyUp={handleKeyUp}
          placeholder={t('Search')}
          type="text"
          value={searchTerm}
        />
      </div>
    </div>
  );
};

QueryFilter.defaultProps = {
  query: '',
};

QueryFilter.propTypes = {
  onApplyFilters: PropTypes.func.isRequired,
  query: PropTypes.string,
};

export default QueryFilter;
