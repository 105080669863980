import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { Grid, Form as TablerForm, Button } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import Loader from '../../../../../../layouts/components/Loader';
import HandleError from '../../../../../../layouts/components/ErrorMessage/HandleError';
import SHOW_TO_REVIEWER from './showToReviewer.mutation';
import styles from './Form.module.css';

const Form = ({ creativeIterationId, onSubmitSuccess, onCloseForm }) => {
  const { t } = useTranslation();
  const [error, setError] = useState();

  const [showToReviewer, { loading }] = useMutation(SHOW_TO_REVIEWER, {
    update: onSubmitSuccess,
  });

  const handleSubmit = async event => {
    event.preventDefault();

    try {
      await showToReviewer({
        variables: {
          creativeIterationId,
          reviewerHasAccess: true,
        },
      });
    } catch (err) {
      setError(err);
    }
  };

  return (
    <div className={styles.Form} data-testid="ShowToReviewerForm">
      <Grid.Row className="h-100 justify-content-center align-items-center">
        {loading && <Loader />}
        <Grid.Col>
          <TablerForm onSubmit={handleSubmit}>
            <h4 className="text-center pb-3">Are you sure you want to show this to a reviewer?</h4>
            <TablerForm.Footer className="mt-3 text-center">
              {error && (
                <p className="text-danger">
                  <HandleError error={error} />
                </p>
              )}
              <Button className="mr-4 px-4 py-2" color="success" disabled={error} type="submit">
                {t('Yes')}
              </Button>
              <Button
                className="px-4 py-2"
                color="danger"
                onClick={() => {
                  onCloseForm();
                }}
                type="button"
              >
                {t('Cancel')}
              </Button>
            </TablerForm.Footer>
          </TablerForm>
        </Grid.Col>
      </Grid.Row>
    </div>
  );
};

Form.propTypes = {
  creativeIterationId: PropTypes.string.isRequired,
  onCloseForm: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
};

export default Form;
