import React from 'react';
import { Container } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import Authorized from '../../../layouts/Authorized';
import PageHeader from '../../../layouts/components/PageHeader';
import PageActions from '../../../layouts/components/PageActions';
import PageTitle from '../../../layouts/components/PageTitle';
import MembersTable from '../../components/MembersTable';
import CreateTeamMember from '../../components/CreateTeamMember/CreateTeamMember';

const EditMembersPage = () => {
  const { t } = useTranslation();

  return (
    <Authorized>
      <Container>
        <PageHeader className="mt-5">
          <PageTitle title={t('Members')} />
          <PageActions>
            <CreateTeamMember />
          </PageActions>
        </PageHeader>
        <MembersTable />
      </Container>
    </Authorized>
  );
};

export default EditMembersPage;
