import React from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Grid, Card, Icon } from 'tabler-react';
import { MEDIA_TYPES } from '../../../../../constants';
import { campaignSlug } from '../../../../../utils/helpers';
import styles from './PostHistoryCard.module.css';

const PostHistoryCard = ({ deliverable, typename }) => {
  const { t } = useTranslation();
  const [influencerId] = useQueryParam('influencerId', StringParam);
  const {
    id,
    creativeIterations: [{ media, price }],
    campaign,
    socialProfile: { title, username },
  } = deliverable;
  const {
    name,
    brand: { name: brandName },
  } = campaign;
  const firstMedia = media.find(el => el !== null);
  const { type, path } = firstMedia;
  const networkIconMap = {
    InstagramProfile: <Icon className={styles.Icon} name="instagram" />,
    YouTubeChannel: <Icon className={styles.Icon} name="youtube-play" prefix="fa" />,
  };

  return (
    <Link
      rel="noopener noreferrer"
      target="_blank"
      to={`/campaigns/${campaignSlug(campaign)}/content/${
        username || title
      }/${influencerId}?deliverable=${id}`}
    >
      <div data-testid="PostHistoryCard">
        <Card className={`${styles.PostHistoryCard} p-2`}>
          <Grid.Row>
            <Grid.Col>
              <div className={styles.ProfileImage}>
                {media.length > 0 && type === MEDIA_TYPES.IMAGE ? (
                  <div
                    className={`mw-100 ${styles.Thumbnail}`}
                    style={{
                      backgroundImage: `url("${path}")`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                    }}
                  />
                ) : (
                  <video className={styles.Thumbnail} preload="metadata" width="100%">
                    <source src={`${path}#t=0.1`} />
                  </video>
                )}
              </div>
              <div className={`${styles.PostHistoryData} pl-2 pr-3`}>
                <Grid.Row className="m-0">
                  <Grid.Col className="p-0" width="4">
                    <div className="font-weight-bold">{t('Brand - Campaign')}</div>
                  </Grid.Col>
                  <Grid.Col className="p-0" width="8">
                    <div>{`${brandName || '-'} - ${name || '-'}`}</div>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row className="m-0">
                  <Grid.Col className="p-0" width="4">
                    <div className="font-weight-bold">{t('Price')}</div>
                  </Grid.Col>
                  <Grid.Col className="p-0" width="8">
                    <div>
                      {price
                        ? price.toLocaleString('en-UK', {
                            style: 'currency',
                            currency: 'GBP',
                          })
                        : '-'}
                    </div>
                  </Grid.Col>
                </Grid.Row>
              </div>
              {networkIconMap[typename]}
            </Grid.Col>
          </Grid.Row>
        </Card>
      </div>
    </Link>
  );
};

PostHistoryCard.propTypes = {
  deliverable: PropTypes.shape({
    campaign: PropTypes.shape({
      brand: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    creativeIterations: PropTypes.arrayOf(
      PropTypes.shape({
        media: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        price: PropTypes.number,
      })
    ).isRequired,
    id: PropTypes.string.isRequired,
    postedAt: PropTypes.string,
    socialProfile: PropTypes.shape({
      __typename: PropTypes.oneOf(['InstagramProfile', 'YouTubeChannel']).isRequired,
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      username: PropTypes.string,
    }),
  }).isRequired,
  typename: PropTypes.string.isRequired,
};

export default PostHistoryCard;
