import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Loader from '../../../layouts/components/Loader';
import ErrorMessage from '../../../layouts/components/ErrorMessage';
import ADD_NOTE from '../InfluencerNotesQueryContiner/addNote.mutation';
import GET_INFLUENCER_PROFILE_DETAILS from './influencerProfileDetails.query';

const firstNotes = 1000;

const updateCache = (cache, { data }, subjectId) => {
  const {
    addComment: { comment },
  } = data;

  const cachedData = cache.readQuery({
    query: GET_INFLUENCER_PROFILE_DETAILS,
    variables: { id: subjectId, firstNotes },
  });

  cachedData.influencer.comments.edges.unshift({ node: comment, __typename: 'CommentEdge' });

  cache.writeQuery({
    query: GET_INFLUENCER_PROFILE_DETAILS,
    data: cachedData,
    variables: { id: subjectId, firstNotes },
  });
};

const InfluencerProfileDetailsQueryContainer = ({ children, id }) => {
  const { t } = useTranslation();
  const [addNoteError, setAddNoteError] = useState();

  const [addNote] = useMutation(ADD_NOTE, {
    update: (cache, data) => updateCache(cache, data, id),
  });

  const { loading, error, data } = useQuery(GET_INFLUENCER_PROFILE_DETAILS, {
    variables: {
      id,
      firstNotes,
    },
  });
  if (error) return <ErrorMessage error={error} />;

  if (loading) return <Loader />;

  const { influencer } = data;

  if (influencer === null) {
    return (
      <div className="d-flex h-100 justify-content-center align-items-center">
        <p className="text-muted">{t('Influencer information not found')}</p>
      </div>
    );
  }

  const handleAddNewEntry = async body => {
    setAddNoteError(null);
    try {
      await addNote({
        variables: {
          comment: {
            subjectId: id,
            body,
          },
        },
      });
    } catch (err) {
      setAddNoteError(err);
    }
  };

  return children(influencer, handleAddNewEntry, addNoteError);
};

InfluencerProfileDetailsQueryContainer.propTypes = {
  children: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default InfluencerProfileDetailsQueryContainer;
