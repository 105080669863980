import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import InfluencerInfo from '../../components/InfluencerInfo';
import Authorized from '../../../layouts/Authorized';
import Sidebar from '../../../layouts/components/Sidebar';
import MainContent from '../../../layouts/components/MainContent';
import PageHeader from '../../../layouts/components/PageHeader';
import PageTitle from '../../../layouts/components/PageTitle';
import Conversation from '../../../common/components/Conversation/Conversation';
import AddEntryForm from '../../../common/components/Conversation/AddEntryForm';
import EntriesList from '../../../common/components/Conversation/EntriesList';
import InfluencerNotesQueryContainer from '../../components/InfluencerNotesQueryContiner';

const InfluencerNotesPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  return (
    <Authorized>
      <InfluencerNotesQueryContainer id={id}>
        {(influencer, notes, onAddNewEntry, handleFetchMore) => (
          <>
            <Sidebar colSize={550}>
              <InfluencerInfo influencer={influencer} />
            </Sidebar>
            <MainContent colOffsetSize={550}>
              <PageHeader>
                <PageTitle title={t('Influencer Notes Page')} />
              </PageHeader>
              <Conversation>
                <AddEntryForm onSubmit={onAddNewEntry} />

                <EntriesList entries={notes} onFetchMore={handleFetchMore} />
              </Conversation>
            </MainContent>
          </>
        )}
      </InfluencerNotesQueryContainer>
    </Authorized>
  );
};

export default InfluencerNotesPage;
