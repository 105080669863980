import React from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import Typeahead from '../Typeahead';
import CAMPAIGNS_DATA from './campaignsData.query';

let options = [];
const Campaigns = ({ onChange, placeholder }) => {
  const { t } = useTranslation();
  const firstCampaigns = 1000;

  const [getData, { called, loading, data }] = useLazyQuery(CAMPAIGNS_DATA);

  const fetchData = inputValue => {
    options = [];

    if (inputValue.length > 1) {
      getData({
        variables: {
          query: inputValue,
          firstCampaigns,
        },
      });
    }
  };

  if (called && data) {
    options = [
      ...new Set(
        data.campaigns.edges.map(({ node: { id, name, brand: { name: brandName } } }) => ({
          id,
          name,
          brandName,
        }))
      ),
    ].map(({ id, name, brandName }) => ({
      value: id,
      label: `${brandName}: ${name}`,
    }));
  }

  return (
    <div className="mb-3">
      <Typeahead
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        isLoading={loading}
        noOptionsMessage={() => `${t('Type campaign name')}`}
        onChange={onChange}
        onInputChange={inputValue => fetchData(inputValue)}
        options={options}
        placeholder={placeholder}
        type="select"
      />
    </div>
  );
};

Campaigns.defaultProps = {
  onChange: () => {},
  placeholder: '',
};

Campaigns.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default Campaigns;
