import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Files from 'react-files';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { Form as TablerForm, Button } from 'tabler-react';
import DraggableFilesArea from '../../DraggableFilesArea';
import Loader from '../../../../../../layouts/components/Loader';
import HandleError from '../../../../../../layouts/components/ErrorMessage/HandleError';
import UPDATE_CREATIVE_ITERATION from './updateCreativeIteration.mutation';
import styles from './Form.module.css';

const POST_TYPE = 'POST';
const STORY_TYPE = 'STORY';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const Form = ({ creativeIteration, deliverableType, onSubmitSuccess }) => {
  const [files, setFiles] = useState([...creativeIteration.media]);
  const [fileErrorMessages, setFileErrorMessages] = useState([]);
  const [caption, setCaption] = useState(creativeIteration.caption);
  const [hasCaptionError, setHasCaptionError] = useState(false);
  const [error, setError] = useState();
  const defaultPrice = creativeIteration.price || '';
  const [price, setPrice] = useState(defaultPrice);
  const filesRef = useRef(null);
  const { t } = useTranslation();

  let disableDropArea = false;
  if (deliverableType === STORY_TYPE && files.length > 0) {
    disableDropArea = true;
  }
  const [updateCreativeIteration, { loading }] = useMutation(UPDATE_CREATIVE_ITERATION, {
    update: () => onSubmitSuccess(),
  });

  const handleDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(files, result.source.index, result.destination.index);

    setFiles(newItems);
  };

  const handleSubmit = async event => {
    event.preventDefault();

    try {
      await updateCreativeIteration({
        variables: {
          id: creativeIteration.id,
          media: files.map(({ file, id }) => ({ file, id })),
          caption,
          price,
        },
      });
    } catch (err) {
      setError(err);
    }
  };

  const handleFilesChange = filesArray => {
    // return if array is empty to avoid executing this method recursively when
    // clearing items from react-files
    if (filesArray.length === 0) {
      return;
    }

    // return because you can't have 2 creativeMedias to story
    if (disableDropArea) {
      return;
    }

    // makes file object to have the same properties as he once coming from the api
    const reducedFiles = filesArray.map((file, index) => {
      return {
        id: file.id,
        order: index,
        path: file.preview.url,
        type: file.preview.type,
        file,
        name: file.name,
      };
    });

    setFiles(prevFiles => [...prevFiles, ...reducedFiles]);

    // empty react-files items because we are storing the items in this
    // component state and if not we are duplicating all previously attached files
    filesRef.current.removeFiles();
  };

  const handleFilesError = err => setFileErrorMessages([...fileErrorMessages, err.message]);

  const handleRemoveFiles = () => {
    setFiles([]);
    filesRef.current.removeFiles();
    setFileErrorMessages([]);
  };

  const handleRemoveFile = fileToRemove => {
    filesRef.current.removeFiles();

    setFiles(prevFiles => {
      const newFiles = prevFiles.filter(file => file.id !== fileToRemove.id);

      return newFiles;
    });
  };

  const handleCaptionChange = ({ target: { value } }) => {
    const regex = /(^|\s)#?ad($|\s)/i;

    setHasCaptionError(false);

    // If the type of the ad is 'POST' the caption could not be empty and should contain ' AD ' substring
    if (deliverableType === POST_TYPE && !value.match(regex)) {
      setHasCaptionError(true);
    }
    setCaption(value);
  };

  return (
    <div className={styles.Form} data-testid="UpdateCreativeForm">
      {loading && <Loader />}
      <TablerForm className="text-center" onSubmit={handleSubmit}>
        <h2>{t('Upload Content')}</h2>
        <div className="text-left">
          {t('Media')}
          {files.length > 0 && (
            <span
              className={styles.RemoveFilesButton}
              onClick={handleRemoveFiles}
              onKeyPress={() => {}}
              role="button"
              tabIndex="0"
            >
              {t(`Clear {{length}} file${files.length > 1 ? 's' : ''}`, { length: files.length })}
            </span>
          )}
          <div className={styles.Files}>
            <div className="mb-3">
              <Files
                ref={filesRef}
                // explicitly added video/mp4 in order to work in Safari
                accepts={['image/jpeg', 'image/png', 'image/gif', 'video/*', 'video/mp4']}
                className={`${styles.Dropzone} ${disableDropArea ? styles.DisabledDropzone : ''}`}
                clickable={!disableDropArea}
                maxFileSize={30000000}
                minFileSize={0}
                multiple={deliverableType === POST_TYPE}
                onChange={handleFilesChange}
                onError={handleFilesError}
              >
                <div className={styles.DropMessage}>{t('Drop files here or click to upload.')}</div>
              </Files>
            </div>

            <div className="mb-3">
              <DraggableFilesArea
                files={files}
                onDragEnd={handleDragEnd}
                onRemoveFile={handleRemoveFile}
              />
            </div>
          </div>
          {fileErrorMessages.length > 0 && (
            <div className={styles.FileError}>
              <ul
                className={`${styles.ErrorMessages} ${
                  fileErrorMessages.length === 1 ? `${styles.ListUnstyled}` : ''
                }`}
              >
                {fileErrorMessages.map(errorMessage => (
                  <li key={errorMessage}>{errorMessage}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <TablerForm.Label className="text-left">
          {t('Caption')}
          <TablerForm.Textarea
            className={styles.Textarea}
            onChange={handleCaptionChange}
            rows={6}
            value={caption}
          />
          <div className={styles.FileError}>
            {hasCaptionError && t("'AD' or '#AD' should be included in the caption.")}
          </div>
        </TablerForm.Label>
        <TablerForm.Label className="text-left">
          {t('Price')}
          <input
            className={`form-control ${styles.Price}`}
            min="0"
            onChange={({ target: { value } }) => {
              setPrice(parseFloat(value));
            }}
            step="0.01"
            type="number"
            value={price}
          />
        </TablerForm.Label>
        {error && (
          // temporary solution until adding toaster messages
          <h6 className="text-danger text-center mt-4">
            <HandleError error={error} />
          </h6>
        )}
        <TablerForm.Footer className="mt-3 text-center">
          <Button
            block
            className="w-auto mx-auto px-4 py-2"
            color="success"
            disabled={
              files.length === 0 ||
              !price ||
              hasCaptionError ||
              (deliverableType === POST_TYPE && caption === '')
            }
            type="submit"
          >
            {t('Save')}
          </Button>
        </TablerForm.Footer>
      </TablerForm>
    </div>
  );
};

Form.propTypes = {
  creativeIteration: PropTypes.shape({
    caption: PropTypes.string,
    id: PropTypes.string.isRequired,
    media: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
      })
    ),
    price: PropTypes.number,
  }).isRequired,
  deliverableType: PropTypes.string.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
};

export default Form;
