import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Grid, Container } from 'tabler-react';
import Authorized from '../../../layouts/Authorized';
import Sidebar from '../../../layouts/components/Sidebar';
import MainContent from '../../../layouts/components/MainContent';
import PageHeader from '../../../layouts/components/PageHeader';
import PageTitle from '../../../layouts/components/PageTitle';
import ShortlistedSocialProfilesList from '../../components/ShortlistedSocialProfilesList';
import StatCard from '../../../common/components/StatCard';
import PageActions from '../../../layouts/components/PageActions';
import ShortlistSocialProfile from '../../components/ShortlistSocialProfile';
import CampaignShortlistQueryContainer from '../../components/CampaignShortlistQueryContainer';
import styles from './CampaignShortlistPage.module.css';

const CampaignShortlistPage = () => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const shortcode = slug.split('-').pop();

  return (
    <Authorized>
      <CampaignShortlistQueryContainer shortcode={shortcode}>
        {({ campaign, profiles, shortlist }) => {
          const {
            hasDemographicsEnabled,
            permissions: { viewerCanAdd, viewerCanApprove, viewerCanRecommend },
          } = shortlist;
          const { approvedProfilesCount, totalProfilesCount } = shortlist.stats;
          const { id: campaignId } = campaign;

          return (
            <>
              <Sidebar colSize="300">
                <StatCard
                  stat={{ value: approvedProfilesCount, outOf: totalProfilesCount }}
                  title={{ current: t('Approved'), total: t('Total Influencers') }}
                />
              </Sidebar>
              <MainContent className={styles.MainContent} colOffsetSize="300">
                <Container>
                  <div className={styles.ShortlistInnerContainer}>
                    <Grid.Row>
                      <Grid.Col>
                        <PageHeader>
                          <PageTitle
                            subtitle={campaign.brand.name}
                            title={`${campaign.name} / ${
                              shortlist.name ? shortlist.name : t('Shortlist')
                            }`}
                          />
                          <PageActions>
                            {viewerCanAdd && (
                              <ShortlistSocialProfile
                                campaignId={campaignId}
                                viewerCanRecommend={viewerCanRecommend}
                              />
                            )}
                          </PageActions>
                        </PageHeader>
                      </Grid.Col>
                    </Grid.Row>

                    <ShortlistedSocialProfilesList
                      hasDemographics={hasDemographicsEnabled}
                      profiles={profiles}
                      viewerCanAddTalent={viewerCanAdd}
                      viewerCanApprove={viewerCanApprove}
                      viewerCanRecommend={viewerCanRecommend}
                    />
                  </div>
                </Container>
              </MainContent>
            </>
          );
        }}
      </CampaignShortlistQueryContainer>
    </Authorized>
  );
};

export default CampaignShortlistPage;
