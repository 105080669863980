import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Countries from '../../../../campaigns/components/Typeahead/Countries';

const CountriesFilter = ({ countriesFilters, onApplyFilters }) => {
  const { t } = useTranslation();
  let selectedCountries = countriesFilters;

  const handleCountryChange = newCountries => {
    selectedCountries = newCountries && newCountries.map(country => country.value);
    onApplyFilters({ countries: selectedCountries });
  };

  return (
    <div className="country-filter" data-testid="countryFilter">
      <Countries
        isMulti
        onChange={handleCountryChange}
        placeholder={t('Country')}
        value={selectedCountries}
      />
    </div>
  );
};

CountriesFilter.defaultProps = {
  countriesFilters: [],
};

CountriesFilter.propTypes = {
  countriesFilters: PropTypes.arrayOf(PropTypes.string),
  onApplyFilters: PropTypes.func.isRequired,
};
export default CountriesFilter;
