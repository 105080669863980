import React from 'react';
import PropTypes from 'prop-types';
import Typeahead from 'react-select';
import { Icon } from 'tabler-react';

const SocialProfiles = ({ onChange, profiles }) => {
  const profilesOptions = profiles.map(({ __typename, id, title, username }) => ({
    label:
      __typename === 'YouTubeChannel' ? (
        <span data-testid="YouTubeOption">
          <Icon className="colored pr-1" name="youtube-play" prefix="fa" /> {title}
        </span>
      ) : (
        <span data-testid="InstagramOption">
          <Icon className="colored pr-1" name="instagram" /> {username}
        </span>
      ),
    value: id,
  }));

  return (
    <Typeahead
      filterOption={(option, rawInput) => {
        const words = rawInput.split(' ');
        return words.reduce(
          (acc, cur) =>
            acc && option.label.props.children[2].toLowerCase().includes(cur.toLowerCase()),
          true
        );
      }}
      onChange={onChange}
      options={profilesOptions}
      type="select"
    />
  );
};

SocialProfiles.propTypes = {
  onChange: PropTypes.func.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default SocialProfiles;
