import React, { useRef } from 'react';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import Loader from '../../../layouts/components/Loader';
import ErrorMessage from '../../../layouts/components/ErrorMessage';
import ScrollToTop from '../../../layouts/components/ScrollToTop';
import BottomBar from '../InfluencersList/BottomBar';
import GET_PROFILES_LIST from './profiles.query';
import styles from './InfluencersListQueryContainer.module.css';

const firstProfiles = 30;
const MIN_PRICE = 10;
const MAX_PRICE = 20000;

const handleScroll = ({ currentTarget }) => {
  if (currentTarget.scrollTop + currentTarget.clientHeight >= currentTarget.scrollHeight) {
    return true;
  }
  return false;
};

const updateQuery = (previousResult, { fetchMoreResult }) => {
  if (!fetchMoreResult || !previousResult.socialProfiles.pageInfo.hasNextPage) {
    return previousResult;
  }

  // Merging the previous profiles object with the one returned from fetchMore
  return {
    ...previousResult,
    socialProfiles: {
      ...previousResult.socialProfiles,
      ...fetchMoreResult.socialProfiles,
      edges: [...previousResult.socialProfiles.edges, ...fetchMoreResult.socialProfiles.edges],
    },
  };
};

const InfluencersListQueryContainer = ({ children, filters }) => {
  const {
    query = '',
    platform,
    agency = '',
    gender = [],
    engagementRate = [],
    followerCount = [],
    subscriberCount = [],
    brands = [],
    countries = [],
    contentType = '',
    priceRange = [],
  } = filters;
  const engagementRateObject = {};
  const followerCountObject = {};
  const subscriberCountObject = {};

  const rangeObject = { greaterThan: MIN_PRICE, lessThan: MAX_PRICE };
  const averagePriceObject = {};

  if (engagementRate.length > 0) {
    engagementRateObject.greaterThan = Number(engagementRate[0]);
    engagementRateObject.lessThan = Number(engagementRate[1]);
  }

  if (followerCount.length > 0) {
    followerCountObject.greaterThan = Number(followerCount[0]);
  }

  if (subscriberCount.length > 0) {
    subscriberCountObject.greaterThan = Number(subscriberCount[0]);
  }

  if (priceRange.length > 0) {
    rangeObject.greaterThan = Number(priceRange[0]);
    rangeObject.lessThan = Number(priceRange[1]);
  }

  if (contentType !== '') {
    averagePriceObject.contentType = contentType;
    averagePriceObject.range = rangeObject;
  }

  let filterBy = {
    platforms: [platform],
    brands,
    countries,
    averagePrice: averagePriceObject,
  };

  if (platform === 'INSTAGRAM') {
    filterBy = {
      ...filterBy,
      ...{
        gender,
        engagementRate: engagementRateObject,
        followerCount: followerCountObject,
      },
    };
  } else {
    filterBy = {
      ...filterBy,
      ...{
        subscriberCount: subscriberCountObject,
      },
    };
  }

  if (agency === true || agency === false) {
    filterBy.managedByAgency = agency;
  }

  const { loading, error, data, fetchMore } = useQuery(GET_PROFILES_LIST, {
    variables: {
      firstProfiles,
      query,
      filterBy,
    },
  });
  const scrollableToTop = useRef(0);

  if (error) return <ErrorMessage error={error} />;

  if (loading) return <Loader />;

  const {
    socialProfiles: {
      edges: profilesNodes,
      totalCount,
      pageInfo: { hasNextPage, endCursor: afterProfile },
    },
  } = data;

  return (
    <>
      <div
        ref={scrollableToTop}
        className={`scrollableContainer ${styles.customHeight}`}
        data-testid="ScrollableContainer"
        onScroll={event => {
          if (handleScroll(event) && hasNextPage) {
            fetchMore({
              variables: {
                firstProfiles,
                afterProfile,
                query,
                filterBy,
              },
              updateQuery,
            });
          }
        }}
      >
        {children(
          profilesNodes.map(profileNode => profileNode.node),
          totalCount
        )}
      </div>
      <ScrollToTop scrollableToTop={scrollableToTop} />
      <BottomBar profiles={profilesNodes} totalCount={totalCount} />
    </>
  );
};

InfluencersListQueryContainer.defaultProps = {
  filters: {
    brands: [],
    gender: [],
    platform: '',
    query: '',
    agency: false,
    countries: [],
    contentType: '',
    priceRange: [],
  },
};

InfluencersListQueryContainer.propTypes = {
  children: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    agency: PropTypes.bool,
    brands: PropTypes.arrayOf(PropTypes.string),
    contentType: PropTypes.string,
    countries: PropTypes.arrayOf(PropTypes.string),
    engagementRate: PropTypes.arrayOf(PropTypes.string),
    followerCount: PropTypes.arrayOf(PropTypes.string),
    gender: PropTypes.arrayOf(PropTypes.string),
    platform: PropTypes.string,
    priceRange: PropTypes.arrayOf(PropTypes.string),
    query: PropTypes.string,
    subscriberCount: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default InfluencersListQueryContainer;
