import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Brands from '../../../../campaigns/components/Typeahead/Brands';

const BrandsFilter = ({ brandsFilters, onApplyFilters }) => {
  const { t } = useTranslation();
  let selectedBrands = brandsFilters;

  const handleBrandsChange = newBrands => {
    selectedBrands = newBrands && newBrands.map(brand => brand.value);
    onApplyFilters({ brands: selectedBrands });
  };

  return (
    <div className="brands-filter" data-testid="brandsFilter">
      <Brands
        isMulti
        onChange={handleBrandsChange}
        placeholder={t('Brand')}
        type="select"
        value={selectedBrands}
      />
    </div>
  );
};

BrandsFilter.defaultProps = {
  brandsFilters: [],
};

BrandsFilter.propTypes = {
  brandsFilters: PropTypes.arrayOf(PropTypes.string),
  onApplyFilters: PropTypes.func.isRequired,
};
export default BrandsFilter;
