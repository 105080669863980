import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form as TablerForm, Button, Grid, Card, Icon } from 'tabler-react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import CampaignsTypeahead from '../../../../../../../campaigns/components/Typeahead/Campaigns';
import HandleError from '../../../../../../../layouts/components/ErrorMessage/HandleError';
import INFLUENCER_SHORTLIST from './addToShortlist.mutation';
import styles from './Form.module.css';

const Form = ({ profile, onSubmitSuccess }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    id: socialProfileId,
    name,
    username,
    title,
    influencer: { pictureUrl },
    url,
    __typename,
  } = profile;

  const [campaignId, setCampaignId] = useState('');
  const [rationale, setRationale] = useState('');
  const [isRecommended, setIsRecommended] = useState(false);
  const [error, setError] = useState('');

  const [addToShortlist] = useMutation(INFLUENCER_SHORTLIST, {
    update: () => {
      onSubmitSuccess();
      history.push('/influencers');
    },
  });

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      await addToShortlist({
        variables: {
          campaignId,
          socialProfileId,
          rationale,
          isRecommended,
        },
      });
    } catch (err) {
      setError(err);
    }
  };

  const networkIconMap = {
    InstagramProfile: <Icon className={styles.Icon} name="instagram" />,
    YouTubeChannel: <Icon className={styles.Icon} name="youtube-play" prefix="fa" />,
  };
  return (
    <div data-testid="ShortlistProfileForm">
      {/* have this here so we don't reset the TablerForm after submit */}
      <TablerForm className={styles.Form} onSubmit={handleSubmit}>
        <h2 className="text-center w-100 pb-3">{t('Add To Shortlist')}</h2>
        <Card className={`${styles.ProfileCard} py-2`}>
          <Grid.Row className="w-100">
            <Grid.Col className="col-3">
              <div
                className={`${styles.ProfileImage}`}
                style={{
                  backgroundImage: `url("${pictureUrl}")`,
                }}
              />
            </Grid.Col>
            <Grid.Col className="col-8 d-flex flex-column justify-content-center">
              <h2 className={`${styles.Name} mb-0`}>{name || title}</h2>
              <a href={url} rel="noopener noreferrer" target="_blank">
                <h2 className={styles.Username}>{`@${username}` || title}</h2>
              </a>
            </Grid.Col>
            <Grid.Col className="col-1 d-flex flex-column justify-content-top">
              <a href={url} rel="noopener noreferrer" target="_blank">
                {networkIconMap[__typename]}
              </a>
            </Grid.Col>
          </Grid.Row>
        </Card>

        <TablerForm.Label>
          <CampaignsTypeahead
            onChange={selectedCampaign => {
              setCampaignId(selectedCampaign.value);
            }}
            placeholder={t('Campaign')}
          />
        </TablerForm.Label>
        <TablerForm.Label>
          <TablerForm.Textarea
            className={styles.TextArea}
            name="rationale"
            onChange={event => setRationale(event.target.value)}
            placeholder={t('Rationale')}
            value={rationale}
          />
        </TablerForm.Label>

        <TablerForm.Checkbox
          checked={isRecommended}
          label={t('Recommend influencer')}
          name="isRecommended"
          onChange={event => setIsRecommended(event.target.checked)}
        />
        <TablerForm.Footer className="mt-3 text-center">
          {error && (
            <p className="text-danger">
              <HandleError error={error} />
            </p>
          )}
          <Button block className="w-auto mx-auto px-4 py-2" color="success" type="submit">
            {t('Add')}
          </Button>
        </TablerForm.Footer>
      </TablerForm>
    </div>
  );
};

Form.propTypes = {
  onSubmitSuccess: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    __typename: PropTypes.oneOf(['InstagramProfile', 'YouTubeChannel']),
    id: PropTypes.string.isRequired,
    influencer: PropTypes.shape({
      pictureUrl: PropTypes.string,
    }),
    name: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    username: PropTypes.string,
  }).isRequired,
};

export default Form;
