import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from 'tabler-react';
import styles from './ScrollToTop.module.css';

const ScrollToTop = ({ scrollableToTop }) => {
  const [isScrolled, setIsScrolled] = useState();

  const handleScroll = ({ currentTarget }) => {
    if (currentTarget.scrollTop > 100) {
      setIsScrolled(true);
    } else setIsScrolled(false);
  };

  useEffect(() => {
    scrollableToTop.current.addEventListener('scroll', event => {
      handleScroll(event);
    });
  });

  const handleClick = () => {
    // eslint-disable-next-line no-param-reassign
    scrollableToTop.current.scrollTop = 0;
  };

  if (!isScrolled) {
    return null;
  }

  return (
    <Button
      className={`${styles.ScrollToTopButton} scrollToTop w-auto py-2`}
      color="primary"
      onClick={handleClick}
    >
      <Icon name="chevron-up" />
    </Button>
  );
};

ScrollToTop.propTypes = {
  scrollableToTop: PropTypes.shape({
    current: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({
        scrollTop: PropTypes.number,
      }),
    ]),
  }).isRequired,
};
export default ScrollToTop;
