import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '../../../layouts/components/Drawer';
import InfluencerProfileDetailsQueryContainer from '../InfluencerProfileDetailsQueryContainer/InfluencerProfileDetailsQueryContainer';
import BaseInfo from './BaseInfo';
import SocialProfileAccordionItem from './SocialProfileAccordionItem';
import PostHistoryAccordionItem from './PostHistoryAccordionItem';
import InternalNotesAccordionItem from './InternalNotesAccordionItem';

const InfluencerProfileDetailsDrawer = ({ influencerId, profileId, isOpen, handleClose }) => {
  return (
    <div className="float-right" data-testid="InfluencerProfileDetailsDrawer">
      <Drawer handleClose={handleClose} isOpen={isOpen}>
        {isOpen && (
          <InfluencerProfileDetailsQueryContainer id={influencerId}>
            {(influencer, onAddNewEntry, addEntryError) => {
              return (
                <>
                  <BaseInfo influencer={influencer} />
                  {influencer.profiles.map(profile => {
                    return (
                      <SocialProfileAccordionItem
                        key={profile.id}
                        isSelected={profileId === profile.id}
                        profile={profile}
                      />
                    );
                  })}
                  <PostHistoryAccordionItem influencer={influencer} />
                  <InternalNotesAccordionItem
                    addEntryError={addEntryError}
                    influencer={influencer}
                    onSubmit={onAddNewEntry}
                  />
                </>
              );
            }}
          </InfluencerProfileDetailsQueryContainer>
        )}
      </Drawer>
    </div>
  );
};

InfluencerProfileDetailsDrawer.defaultProps = {
  influencerId: null,
  profileId: null,
};

InfluencerProfileDetailsDrawer.propTypes = {
  handleClose: PropTypes.func.isRequired,
  influencerId: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  profileId: PropTypes.string,
};
export default InfluencerProfileDetailsDrawer;
