const executeUpdate = async (fromRow, updated, updateSocialProfile, profiles) => {
  const [propData] = Object.keys(updated);
  const forUpdate = profiles[fromRow];

  // updated value conditionally, not every field will need to be parsed to and int or float
  // For updating influencer data (country and gender), do we need to create a new mutation for updating influencer, or add a new influencer input in updateSocialProfile mutation?
  const updatedValue =
    propData === 'engagementRate'
      ? !isNaN(updated[propData]) && parseFloat(updated[propData])
      : !isNaN(updated[propData]) && parseInt(updated[propData], 10);

  try {
    await updateSocialProfile({
      variables: {
        id: forUpdate.id,
        [propData]: updatedValue,
      },
    });
  } catch (err) {
    if (!err.graphQLErrors) {
      throw err;
    }
  }
};

export default executeUpdate;
