import gql from 'graphql-tag';

export default gql`
  mutation($creativeIterationId: ID!, $videoUrl: URL!, $price: Float!) {
    downloadYouTubeVideo(
      input: { creativeIterationId: $creativeIterationId, videoUrl: $videoUrl, price: $price }
    ) {
      creativeIteration {
        id
        caption
        state
        media {
          id
          path
          type
          order
        }
        price
        type
        deliverable {
          id
          campaign {
            id
          }
        }
      }
    }
  }
`;
