import React from 'react';
import { Grid } from 'tabler-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MEDIA_TYPES } from '../../../../../constants';
import styles from './ContentThumbnail.module.css';

const ContentThumbnail = ({ alt, media: { type, path }, hasLabel }) => {
  const { t } = useTranslation();
  let altText = alt;
  if (!alt) {
    altText = t('Contents');
  }
  return (
    <Grid.Row>
      <Grid.Col>
        <div className={styles.Contents}>
          {hasLabel && <p className={`text-capitalize mb-0 ${styles.Label}`}>{t('contents')}</p>}
          {type === MEDIA_TYPES.IMAGE ? (
            <img alt={altText} className={`mw-100 ${styles.Thumbnail}`} src={path} />
          ) : (
            <video className={styles.Thumbnail} preload="metadata" width="100%">
              <source src={`${path}#t=0.1`} />
            </video>
          )}
        </div>
      </Grid.Col>
    </Grid.Row>
  );
};

ContentThumbnail.defaultProps = {
  alt: '',
  hasLabel: false,
};

ContentThumbnail.propTypes = {
  alt: PropTypes.string,
  hasLabel: PropTypes.bool,
  media: PropTypes.shape({
    path: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(MEDIA_TYPES)),
  }).isRequired,
};

export default ContentThumbnail;
