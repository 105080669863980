import gql from 'graphql-tag';

export default gql`
  query {
    campaignsSummary {
      totalCount
      totalShortlistedProfiles
      totalFollowerCount
      totalDeliverablesCount
      company {
        id
        name
        logoUrl
      }
    }
  }
`;
