import React from 'react';
import { Container, Grid, Button } from 'tabler-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Unauthorized from '../../../layouts/Unauthorized';
import styles from './NotFoundPage.module.css';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <Unauthorized>
      <Container className="h-100">
        <Grid.Row className="h-100 align-items-center justify-content-center">
          <Grid.Col className="mt-3 col-4 text-center">
            <h1 className={styles.ErrorCode}>{t('404')}</h1>
            <p className={`${styles.ErrorMessage} font-italic`}>
              {t("Oh no this Page doesn't Exist")}
            </p>
            <Link to="/campaigns">
              <Button className="text-center mx-auto" color="primary">
                Go Back to Homepage
              </Button>
            </Link>
          </Grid.Col>
        </Grid.Row>
      </Container>
    </Unauthorized>
  );
};

export default NotFoundPage;
