import gql from 'graphql-tag';

export default gql`
  mutation(
    $campaignId: ID!
    $socialProfileId: ID
    $handle: String
    $youtubeChannel: String
    $numberOfPosts: Int
    $numberOfStories: Int
    $numberOfVideos: Int
  ) {
    addContent(
      input: {
        campaignId: $campaignId
        socialProfileId: $socialProfileId
        influencerHandle: $handle
        youtubeChannel: $youtubeChannel
        numberOfPosts: $numberOfPosts
        numberOfStories: $numberOfStories
        numberOfVideos: $numberOfVideos
      }
    ) {
      created
    }
  }
`;
