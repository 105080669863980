import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'tabler-react';
import styles from './PageHeader.module.css';

const PageHeader = ({ children, className }) => {
  return (
    <div data-testid="PageHeader">
      <Grid.Row className={`mx-0 ${styles.PageHeader} ${className}`}>{children}</Grid.Row>
    </div>
  );
};

PageHeader.defaultProps = {
  className: '',
};

PageHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};

export default PageHeader;
