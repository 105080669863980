export const CREATIVE_ITERATION_STATES = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  CHANGES_REQUESTED: 'CHANGES_REQUESTED',
  POSTED: 'POSTED',
};

export const MEDIA_TYPES = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
};

export const SHORTLISTED_SOCIAL_PROFILE_STATES = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const CREATIVE_ITERATION_TYPES = {
  InstagramFeedImage: 'InstagramFeedImage',
  InstagramFeedVideo: 'InstagramFeedVideo',
  InstagramFeedCarousel: 'InstagramFeedCarousel',
  InstagramStoryImage: 'InstagramStoryImage',
  InstagramStoryVideo: 'InstagramStoryVideo',
  YouTubeVideo: 'YouTubeVideo',
};
