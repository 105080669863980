import gql from 'graphql-tag';

export default gql`
  fragment InfluencerData on Influencer {
    id
    name
    pictureUrl
    country
    gender
    profiles {
      ... on YouTubeChannel {
        id
        url
      }
      ... on InstagramProfile {
        id
        url
      }
      __typename
    }
    __typename
  }
`;
