import React from 'react';
import PropTypes from 'prop-types';
import { useQueryParams, StringParam } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { Grid } from 'tabler-react';
import InfluencerProfileDetailsDrawer from '../InfluencerProfileDetailsDrawer';
import GridView from './GridView';
import TableView from './TableView';
import styles from './InfluencersList.module.css';

const InfluencersList = ({ isSidebarOpen, profiles, platform, view, tableWidth }) => {
  const { t } = useTranslation();

  const [drawerDetails, setDrawerDetails] = useQueryParams({
    influencerId: StringParam,
    profileId: StringParam,
  });

  if (profiles.length === 0) {
    return (
      <Grid.Row>
        <Grid.Col>
          <div
            className={`text-center text-muted ${styles.NoContentLabel}`}
            style={{ minWidth: tableWidth }}
          >
            {t('No profiles found')}
          </div>
        </Grid.Col>
      </Grid.Row>
    );
  }

  return (
    <>
      <InfluencerProfileDetailsDrawer
        handleClose={() => setDrawerDetails({ influencerId: null, profileId: null })}
        influencerId={drawerDetails.influencerId}
        isOpen={Boolean(drawerDetails.influencerId)}
        profileId={drawerDetails.profileId}
      />
      <Grid.Row className="mx-0 my-4">
        {view === 'grid' && (
          <GridView
            onClick={(influencerId, profileId) => {
              setDrawerDetails({ influencerId, profileId });
            }}
            profiles={profiles}
          />
        )}
        {view === 'table' && isSidebarOpen && (
          <div style={{ minWidth: tableWidth }}>
            <TableView
              onClick={(influencerId, profileId) => {
                setDrawerDetails({ influencerId, profileId });
              }}
              platform={platform}
              profiles={profiles}
            />
          </div>
        )}
        {view === 'table' && !isSidebarOpen && (
          <div style={{ minWidth: tableWidth }}>
            <TableView
              onClick={(influencerId, profileId) => {
                setDrawerDetails({ influencerId, profileId });
              }}
              platform={platform}
              profiles={profiles}
            />
          </div>
        )}
      </Grid.Row>
    </>
  );
};

InfluencersList.defaultProps = {
  isSidebarOpen: true,
};

InfluencersList.propTypes = {
  isSidebarOpen: PropTypes.bool,
  platform: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tableWidth: PropTypes.number.isRequired,
  view: PropTypes.oneOf(['table', 'grid']).isRequired,
};

export default InfluencersList;
