import React from 'react';
import { Grid, Card } from 'tabler-react';
import PropTypes from 'prop-types';
import styles from './StatCard.module.css';

const StatCard = ({ stat, title }) => {
  const { current, total } = title;
  const { value, outOf } = stat;
  return (
    <div data-testid="StatCard">
      <Card className={styles.Card}>
        <Grid.Row className={styles.Center}>
          <Grid.Col className={styles.Stats}>
            <span className={styles.Value}>{value}</span> / {outOf}
          </Grid.Col>
        </Grid.Row>
        <Grid.Row className={styles.Center}>
          <Grid.Col className={styles.Title}>
            {current} / {total}
          </Grid.Col>
        </Grid.Row>
      </Card>
    </div>
  );
};

StatCard.propTypes = {
  stat: PropTypes.shape({
    outOf: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  }).isRequired,
  title: PropTypes.shape({
    current: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
  }).isRequired,
};

export default StatCard;
