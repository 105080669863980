import React from 'react';
import PropTypes from 'prop-types';
import { CREATIVE_ITERATION_TYPES } from '../../../constants';
import InstagramFeedTemplate from './InstagramFeedTemplate';
import InstagramStoryTemplate from './InstagramStoryTemplate';
import YoutubeVideoTemplate from './YoutubeVideoTemplate';
import ReviewerActions from './ReviewerActions';
import ManagerActions from './ManagerActions';
import StateLabel from './StateLabel';

const ContentDetailsCard = ({ children, deliverable, permissions }) => {
  const { viewerCanUploadContent, viewerCanShowToReviewer, viewerCanApprove } = permissions;
  const {
    creativeIterations: [creativeIteration],
  } = deliverable;
  const { type } = creativeIteration;
  const templateMap = {
    [CREATIVE_ITERATION_TYPES.InstagramFeedImage]: InstagramFeedTemplate,
    [CREATIVE_ITERATION_TYPES.InstagramFeedVideo]: InstagramFeedTemplate,
    [CREATIVE_ITERATION_TYPES.InstagramFeedCarousel]: InstagramFeedTemplate,
    [CREATIVE_ITERATION_TYPES.InstagramStoryImage]: InstagramStoryTemplate,
    [CREATIVE_ITERATION_TYPES.InstagramStoryVideo]: InstagramStoryTemplate,
    [CREATIVE_ITERATION_TYPES.YouTubeVideo]: YoutubeVideoTemplate,
  };

  // those we should extract into a `ContentActions` component
  const isManager = viewerCanUploadContent || viewerCanShowToReviewer;
  const isReviewer = !isManager && viewerCanApprove;
  const isViewer = !isManager && !isReviewer;

  const Template = templateMap[type];
  return (
    <Template deliverable={deliverable}>
      {/* always display only one set of actions because of tailify reviewers */}
      {isManager && <ManagerActions deliverable={deliverable} permissions={permissions} />}
      {isReviewer && <ReviewerActions creativeIteration={creativeIteration} />}
      {isViewer && <StateLabel creativeIteration={creativeIteration} />}
      {children}
    </Template>
  );
};

ContentDetailsCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  deliverable: PropTypes.shape({
    creativeIterations: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf(Object.values(CREATIVE_ITERATION_TYPES)),
      })
    ).isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    viewerCanApprove: PropTypes.bool.isRequired,
    viewerCanShowToReviewer: PropTypes.bool.isRequired,
    viewerCanUploadContent: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ContentDetailsCard;
