import React from 'react';
import PropTypes from 'prop-types';
import Humanize from 'humanize-plus';
import { Icon } from 'tabler-react';
import styles from './InfluencerSocialProfileStats.module.css';

const InfluencerSocialProfileStats = ({ profile }) => {
  const {
    __typename: typename,
    engagementRate,
    followerCount,
    url,
    subscriberCount,
    viewRate,
  } = profile;

  const networkIconMap = {
    InstagramProfile: <Icon className="colored" name="instagram" />,
    YouTubeChannel: <Icon className="colored" name="youtube-play" prefix="fa" />,
  };

  return (
    <div className={`col f-flex align-items-center text-center p-1 ${styles.Stats}`}>
      <a href={url} rel="noopener noreferrer" target="_blank">
        {networkIconMap[typename]}
      </a>

      {typename === 'InstagramProfile' && (
        <>
          <div>{followerCount === null ? '-' : Humanize.compactInteger(followerCount, 1)}</div>
          <div className={styles.Percentage}>
            {engagementRate === null ? '-' : engagementRate.toFixed(2)}
          </div>
        </>
      )}
      {typename === 'YouTubeChannel' && (
        <>
          <div>{subscriberCount === null ? '-' : Humanize.compactInteger(subscriberCount, 1)}</div>
          <div className={styles.Percentage}>{viewRate === null ? '-' : viewRate.toFixed(2)}</div>
        </>
      )}
    </div>
  );
};

InfluencerSocialProfileStats.propTypes = {
  profile: PropTypes.shape({
    __typename: PropTypes.oneOf(['InstagramProfile', 'YouTubeChannel']).isRequired,
    engagementRate: PropTypes.number,
    followerCount: PropTypes.number,
    subscriberCount: PropTypes.number,
    url: PropTypes.string,
    viewRate: PropTypes.number,
  }).isRequired,
};

export default InfluencerSocialProfileStats;
