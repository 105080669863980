import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import CampaignCard from '../CampaignCard';
import styles from './CampaignsList.module.css';

const CampaignsList = ({ campaigns }) => {
  const { t } = useTranslation();

  if (campaigns.length === 0) {
    return (
      <Grid.Row>
        <Grid.Col>
          <div className={`text-center text-muted ${styles.NoContentLabel}`}>
            {t('No campaigns found')}
          </div>
        </Grid.Col>
      </Grid.Row>
    );
  }

  return (
    <Grid.Row>
      {campaigns.map(campaign => (
        <Grid.Col key={campaign.id} className="cardItem col-12" lg={6}>
          <CampaignCard campaign={campaign} />
        </Grid.Col>
      ))}
    </Grid.Row>
  );
};

CampaignsList.propTypes = {
  campaigns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default CampaignsList;
