import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ConversationEntry from '../ConversationEntry';
import styles from './EntriesList.module.css';

const handleScroll = ({ currentTarget }, onFetchMore) => {
  if (currentTarget.scrollTop + currentTarget.clientHeight >= currentTarget.scrollHeight) {
    onFetchMore();
  }
};

const EntriesList = ({ entries, onFetchMore }) => {
  const { t } = useTranslation();
  if (entries.length === 0) {
    return <p className="text-center text-muted">{t('No comments added yet')}</p>;
  }

  return (
    <div
      className={styles.CommentsContainer}
      data-testid="NotesContainer"
      onScroll={event => handleScroll(event, onFetchMore)}
    >
      {entries.map(entry => (
        <ConversationEntry key={entry.id} conversationEntry={entry} />
      ))}
    </div>
  );
};

EntriesList.propTypes = {
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  onFetchMore: PropTypes.func.isRequired,
};

export default EntriesList;
