import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'tabler-react';
import Drawer from '../../../layouts/components/Drawer';
import Form from './Form';

const ShortlistSocialProfile = ({ campaignId, viewerCanRecommend }) => {
  const { t } = useTranslation();
  const [addSocialProfileFormIsOpen, setAddSocialProfileFormIsOpen] = useState(false);

  return (
    <>
      <Drawer
        handleClose={() => {
          setAddSocialProfileFormIsOpen(false);
        }}
        isOpen={addSocialProfileFormIsOpen}
      >
        {addSocialProfileFormIsOpen && (
          <Form
            campaignId={campaignId}
            onSubmitSuccess={() => {
              setAddSocialProfileFormIsOpen(false);
            }}
            viewerCanRecommend={viewerCanRecommend}
          />
        )}
      </Drawer>
      <Button
        block
        className="w-auto float-right px-4 py-2"
        color="success"
        onClick={() => {
          setAddSocialProfileFormIsOpen(true);
        }}
      >
        {t('Add Influencer')}
      </Button>
    </>
  );
};

ShortlistSocialProfile.propTypes = {
  campaignId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  viewerCanRecommend: PropTypes.bool.isRequired,
};

export default ShortlistSocialProfile;
