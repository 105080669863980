import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Card } from 'tabler-react';
import nl2br from 'react-newline-to-break';
import ContentMedia from '../ContentMedia';
import styles from './InstagramStoryTemplate.module.css';

const InstagramStoryTemplate = ({ children, deliverable }) => {
  const {
    creativeIterations: [{ media, caption }],
    socialProfile: { username },
  } = deliverable;

  return (
    <div className={`mt-3 ${styles.InstagramStoryContainer}`}>
      <div className={styles.MediaBox}>
        <Grid.Row>
          <Grid.Col className={`${styles.InstagramStoryContainerCol}`} lg="12">
            <div className={`m-2 w-100 ${styles.InstagramStoryProfile}`}>
              <div className={`mr-2 ${styles.InstagramStoryAvatar}`} />
              <div className={styles.InstagramStoryHandle}>{username}</div>
            </div>
          </Grid.Col>
        </Grid.Row>
        <ContentMedia className={styles.InstagramStoryVideo} media={media} />
      </div>

      {caption && (
        <Card className={styles.InstagramStoryCaptionContainer}>
          <div className={styles.InstagramStoryCaption}>{nl2br(caption)}</div>
        </Card>
      )}
      {children}
    </div>
  );
};

InstagramStoryTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  deliverable: PropTypes.shape({
    creativeIterations: PropTypes.arrayOf(
      PropTypes.shape({
        caption: PropTypes.string,
        media: PropTypes.array.isRequired,
      })
    ),
    socialProfile: PropTypes.shape({
      username: PropTypes.string,
    }),
  }).isRequired,
};

export default InstagramStoryTemplate;
